import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLHeadElement>;
  controls?: React.ReactElement;
}

export const H1: FC<IProps> = (props) => (
  <h1
    {...props.attributes}
    className={[styles.h1, props.attributes?.className].join(" ")}
  >
    {props.children}
    {props.controls}
  </h1>
);

const styles = {
  h1: style({
    fontWeight: 600,
    fontSize: "1.286em",
    padding: "10px 0 20px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    $nest: {
      "& > *": {
        flex: 0,
      },
    },
  }),
};
