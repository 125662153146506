import { ICompany, IUser, Role, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import UserForm from "../components/forms/userForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { companyService } from "../services/company.service";
import { routerService } from "../services/router.service";
import { userService } from "../services/user.service";
import { authUtils } from "../utils/authentication.utils";
import { json } from "../utils/statics.utils";

interface IState {
  contact: IUser | null;
  image?: string;
}

export default class ContactFormView extends React.Component<
  RouteComponentProps,
  IState
> {
  private id: string | null = null;
  public state: IState = {
    contact: null,
    image: undefined,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    const id = (this.props.match as any).params.id;
    if (id && id !== "new") {
      this.id = id;
    }
  }

  componentDidMount() {
    this.fetchContact();
  }

  render() {
    return (
      <>
        {this.state.contact && (
          <Card
            attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}
            images={this.state.image ? [this.state.image] : undefined}
            imageSize="cover"
          >
            <UserForm
              roles={[Role.CONTACT]}
              onImageChange={(image) => this.setState({ image })}
              onSubmit={(contact) => this.submit(contact)}
              user={this.state.contact || undefined}
            />
          </Card>
        )}
      </>
    );
  }

  /**
   * Submit the contact form
   * @param contact
   */
  private async submit(contact: FormData) {
    try {
      const created = await json<IUser>(
        userService.createOrPatch(contact as any, { loader: true })
      );
      notificationListener.next({
        message: "Het contact is succesvol opgeslagen",
        severity: Severity.SUCCESS,
      });

      if (created.role === Role.CONTACT) {
        routerService.navigate(routes.contacts.path());
      } else {
        routerService.navigate(routes.employees.path());
      }
    } catch (err) {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.ALERT,
      });
    }
  }

  /**
   * Fetch the concerning contact or create an empty one
   */
  private async fetchContact() {
    if (!this.id) {
      const employedById = authUtils.getEmployer();
      const employedBy = employedById
        ? await json<ICompany>(companyService.get(employedById))
        : null;

      this.setState({
        contact: {
          employedById,
          employedBy,
        } as IUser,
      });

      return;
    }

    const contact = await json<IUser>(userService.get(this.id));
    this.setState({ contact, image: contact.imagePath || undefined });
  }
}
