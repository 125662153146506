import { IMessage, MessageType } from "@hulanbv/afbouwkeur-packages";
import { IResponse } from "../interfaces/response.interface";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { json } from "../utils/statics.utils";
import { authenticationService } from "./authentication.service";
import { CrudService } from "./crudService.abstract.service";
import { httpService } from "./http.service";

export const messageService = new (class Service extends CrudService<IMessage> {
  private fetchedMessageIds: string[] = [];

  constructor() {
    super("message");
  }

  /**
   * Dismiss a message
   * @param id
   */
  dismiss(id: string): Promise<IResponse<IMessage>> {
    return httpService.post([this.controller, id, "dismiss"].join("/"), null);
  }

  /**
   * Poll for unread high risk submissions
   * and add them to the notificationcenter
   */
  async pollMessages() {
    if (!authenticationService.getSessionToken()) {
      return;
    }

    const messages = await json<IMessage[]>(
      this.getAll({
        filter: { isDismissed: false.toString() },
        populate: [],
      })
    );
    messages.forEach((message) => {
      if (this.fetchedMessageIds.includes(message._id)) {
        return;
      }

      if (message.type === MessageType.FINISHED_ASSIGNMENT) {
        notificationListener.next({
          message: `Taak ${message.assignment?.name} is afgerond door ${message.author?.firstName} ${message.author?.lastName}`,
          severity: message.severity,
          duration: -1,
          navigateTo: routes.projectAssignment.path(
            message.assignment?.projectId || "",
            message.assignmentId
          ),
          onClose: () => this.dismiss(message._id),
        });
      }
      this.fetchedMessageIds.push(message._id);
    });
  }
})();
