import React from "react";
import { Sheet } from "strcss";
import { globalStyles } from "../../styles";
import { Dropdown, IOption } from "../containers/dropdown";
import { OptionPill } from "../containers/optionPill";
import { inputReadOnlyStyles, inputStyles } from "./input";

export interface ISearchOption extends IOption {
  onClick?: (option: IOption) => void;
}

interface IProps {
  defaultValue?: ISearchOption[];
  name: string;
  multiple?: boolean;
  searchMethod: (query: string) => Promise<ISearchOption[]>;
  placeholder?: string;
  required?: boolean;
  onChange?: (option: ISearchOption[]) => any;
  readOnly?: boolean;
  minQueryLength?: number;
}

interface IState {
  options: ISearchOption[];
  selected: ISearchOption[];
  focus: boolean;
}

export default class Search extends React.Component<IProps, IState> {
  public state: IState = {
    options: [],
    selected: [],
    focus: false,
  };
  private queryInput = React.createRef<HTMLDivElement>();
  private container = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener("click", this.closeSearch);
    this.setState({
      options: [],
      selected: this.props.defaultValue || [],
    });
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.closeSearch);
  }

  componentWillReceiveProps(props: IProps) {
    this.setState({
      options: [],
    });
  }

  render() {
    return (
      <div
        ref={this.container}
        className={[
          inputStyles,
          map.inputWrapper,
          globalStyles.flexWrapped,
          this.props.readOnly ? inputReadOnlyStyles : "",
        ].join(" ")}
        onClick={this.focusInner}
      >
        {this.props.required && (
          <input
            className={map.requiredField}
            value={this.state.selected.map((option) => option.key).join("")}
            onFocus={() =>
              this.queryInput.current && this.queryInput.current.focus()
            }
            onChange={() => {
              /* prevent react from complaining */
            }}
            required
          />
        )}

        {!this.state.selected.length && (
          <input type="hidden" value={""} name={this.props.name} />
        )}
        {this.state.selected.map((option, i) => (
          <input
            key={option.key}
            type="hidden"
            value={option.key}
            name={`${this.props.name}${this.props.multiple ? `[${i}]` : ""}`}
          />
        ))}

        {this.state.selected.map((option, i) => (
          <OptionPill
            key={option.key}
            option={option}
            style={{ width: this.props.multiple ? undefined : "100%" }}
            onClick={option.onClick}
            onDelete={
              this.props.readOnly
                ? undefined
                : (option) => this.removeSelected(option)
            }
          />
        ))}

        {!this.props.readOnly &&
          (this.props.multiple || !this.state.selected.length) && (
            <div
              placeholder={this.props.placeholder}
              ref={this.queryInput}
              className={[map.searchInput, "input"].join(" ")}
              contentEditable
              onFocus={() => this.searchOptions("")}
              onKeyUp={(e) =>
                this.searchOptions(e.currentTarget.textContent || "")
              }
            />
          )}

        {!!this.state.options.length && (
          <Dropdown
            onSelect={(option) => this.selectOption(option)}
            options={this.state.options.filter(
              (option) =>
                !this.state.selected.find(
                  (sOption) => sOption.key === option.key
                )
            )}
          />
        )}
      </div>
    );
  }

  private resetQuery() {
    if (this.queryInput.current) {
      this.queryInput.current.textContent = "";
    }
    this.setState({ options: [] });
  }

  private removeSelected(option: ISearchOption) {
    const selected = this.state.selected.filter(
      (selected) => selected.key !== option.key
    );
    this.setState(
      { selected },
      () => this.queryInput.current && this.queryInput.current.focus()
    );

    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  }

  /**
   * Select an option from the dropdown
   * @param option
   */
  private selectOption(option: ISearchOption) {
    if (this.props.multiple) {
      this.state.selected.push(option);
    } else {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.selected = [option];
    }
    this.resetQuery();

    this.setState({ selected: this.state.selected });

    if (this.props.onChange) {
      this.props.onChange(this.state.selected);
    }
  }

  /**
   * Search for new dropdown options
   * @param query
   */
  private async searchOptions(query: string) {
    let options: ISearchOption[] = [];
    if (
      (!this.state.selected.length || this.props.multiple) &&
      (this.props.minQueryLength === undefined ||
        query.length >= this.props.minQueryLength)
    ) {
      options = await this.props.searchMethod(query);
    }

    this.setState({ options });
  }

  /**
   * Focus the inner element with the class input
   * @param e
   */
  private focusInner(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const input = Array.from(
      e.currentTarget.getElementsByClassName("input")
    ).pop() as HTMLInputElement;
    if (input) {
      input.focus();
    }
  }

  /**
   * Close the search dropdown
   */
  closeSearch = (e: MouseEvent) => {
    if (!this.container.current?.contains(e.target as HTMLDivElement)) {
      this.resetQuery();
    }
  };
}

const { map } = new Sheet(`
  map inputWrapper
    cursor text
    alignItems center
    padding 0

  map searchInput 
    outline none
    margin 10
    flex 1
    minWidth 100
    fontSize 1em
  
  map requiredField
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    opacity 0
    padding 10
    zIndex -1
`);
