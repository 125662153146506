import { IAttachment } from "@hulanbv/afbouwkeur-packages";
import { CrudService } from "./crudService.abstract.service";

export const attachmentService = new (class Service extends CrudService<
  IAttachment
> {
  constructor() {
    super("attachment");
  }
})();
