import { ICredentials, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { LoginForm } from "../components/forms/loginForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { authenticationService } from "../services/authentication.service";

export default class LoginView extends React.Component<RouteComponentProps> {
  componentDidMount() {}

  render() {
    return (
      <Card
        head="Inloggen"
        attr={{ style: { maxWidth: "400px", margin: "0 auto" } }}
      >
        <LoginForm onSubmit={(credentials) => this.authenticate(credentials)} />
        <Button
          ghost
          onClick={() =>
            this.props.history.push(routes.resetPasswordRequest.path())
          }
        >
          Wachtwoord vergeten
        </Button>
      </Card>
    );
  }

  /**
   * Log in to the application
   * @param credentials
   */
  private async authenticate(credentials: ICredentials) {
    try {
      await authenticationService.authenticate(credentials, { loader: true });
      notificationListener.next({
        message: "Succesvol ingelogd",
        severity: Severity.SUCCESS,
      });
    } catch (err) {
      notificationListener.next({
        message: "Gebruikersnaam en/of wachtwoord onjuist",
        severity: Severity.WARNING,
      });
    }
  }
}
