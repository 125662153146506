import React, { ButtonHTMLAttributes } from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  ghost?: boolean;
  compact?: boolean;
  small?: boolean;
  bland?: boolean;
}

export const Button: React.FC<IButtonProps> = (props) => (
  <button
    {...{
      type: "button",
      ...props,
      ghost: undefined,
      compact: undefined,
      small: undefined,
      bland: undefined,
      className: [
        props.className,
        map.button,
        props.ghost ? map.ghost : "",
        props.compact ? map.compact : "",
        props.small ? map.small : "",
        props.bland ? map.bland : "",
      ].join(" "),
    }}
  >
    {props.children}
  </button>
);

const { map } = new Sheet(`
  map button
    display flex
    alignItems center
    justifyContent space-around
    border 0
    outline 0
    cursor pointer
    padding 10px 25px
    position relative
    margin 0 5
    width -webkit-fill-available
  
    borderRadius 40px
    background ${colors.brand.gradient.diagonal}
    color ${colors.textLight.primary}
    fontWeight 500
    fontSize 1em

    transition .3s all

  map button !before
    content " "
    overlay 100
    position absolute
    borderRadius 40px
    transition 0.3s all
    backgroundColor #fff
    opacity 0

  map button !hover !before
    opacity 0.15

  map button !active !before
    opacity 0.02
    background #000

    
  map button !disabled
    opacity 0.5
    cursor default
    
  map ghost
    background transparent
    color ${colors.text.secondary}

  map compact
    padding 13

  map small
    fontSize 0.714em
    padding 8 12

  map bland
    background white
    color ${colors.text.secondary}
    border 1 solid #dedede
  `);
