import { ISessionToken, IUser } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import { Subscription } from "rxjs";
import { style } from "typestyle";
import { breadcrumbListener } from "../../observables/breadcrumb-listener";
import routes, { RouteOptions } from "../../routes";
import { authenticationService } from "../../services/authentication.service";
import { Breadcrumbs } from "../controls/breadcrumbs";
import { NavigationBar } from "./navigationBar";
import { Navigator } from "./navigator";

interface IState {
  user: IUser | null;
}

export default class Router extends React.Component<{}, IState> {
  public state: IState = {
    user: null,
  };

  private sessionChangedListener: Subscription | null = null;

  componentDidMount() {
    this.setUser(authenticationService.getSessionToken());
    this.sessionChangedListener = authenticationService.onSessionChange.subscribe(
      (sessionToken) => this.setUser(sessionToken)
    );
  }

  componentWillUnmount() {
    this.sessionChangedListener!.unsubscribe();
  }

  render() {
    return (
      <BrowserRouter>
        <div className={styles.hideOnPrint}>
          <Navigator />
          <NavigationBar user={this.state.user} />
          <Breadcrumbs />
        </div>
        <Switch>
          {Object.values(routes)
            .filter(
              (route) =>
                (this.state.user === null && route.roles === null) ||
                (route.roles &&
                  ((this.state.user && route.roles.length === 0) ||
                    (this.state.user &&
                      route.roles.includes(this.state.user.role))))
            )
            .map((route, i) => (
              <Route
                exact
                key={i}
                path={route.path()}
                component={(props: RouteComponentProps) => {
                  this.resolveBreadcrumbs(route, props.match.params);
                  return <route.component {...props} />;
                }}
              />
            ))}
        </Switch>
      </BrowserRouter>
    );
  }

  /**
   * Resolve the new breadcrumbs based on the route
   * @param route
   * @param params
   */
  private async resolveBreadcrumbs(route: RouteOptions, params: any) {
    let crumbs: (string | JSX.Element)[] = [];
    try {
      crumbs = (await route.breadcrumbs?.(params)) ?? [];
    } catch {}

    breadcrumbListener.next(crumbs);
  }

  /**
   * Sets the logged in user
   */
  private setUser(sessionToken: ISessionToken | null) {
    let user = null;
    if (sessionToken && sessionToken.user) {
      user = sessionToken.user;
    }
    this.setState({ user });
  }
}

const styles = {
  hideOnPrint: style({
    $nest: {
      "@media print": {
        display: "none",
      },
    },
  }),
};
