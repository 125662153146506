import React, { CSSProperties } from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

interface IProps {
  size?: number;
  style?: CSSProperties;
  background?: string;
}

export const Circle: React.FC<IProps> = props => {
  const style: CSSProperties = {
    ...props.style
  };
  if (props.size) {
    style.width = `${props.size}px`;
    style.height = `${props.size}px`;
  }
  if (props.background) {
    style.background = props.background;
  }

  return <div className={map.circle} style={style} />;
};

const { map } = new Sheet(`
map circle
    borderRadius 100%
    size 6
    background ${colors.brand.gradient.diagonal}
    backgroundSize cover
    backgroundPosition center
`);
