import React from "react";
import xIcon from "../../../assets/x.svg";
import { dictionary } from "../../../constants/i18n/dictionary";
import { globalStyles } from "../../../styles";
import { FormRow } from "../../containers/formRow";
import { Button } from "../../controls/button";
import { Image } from "../../controls/image";
import { Input } from "../../controls/input";

interface IProps {
  questions: string[];
  onChange: (questions: string[]) => any;
  inputName?: string;
  readOnly?: boolean;
}

export const SecurityQuestions: React.FC<IProps> = (props) => {
  // create event handlers
  const add = () => props.onChange([...props.questions, ""]);
  const remove = (index: number) =>
    props.onChange(props.questions.filter((x, i) => i !== index));
  const change = (value: string, index: number) =>
    props.onChange(props.questions.map((v, i) => (i === index ? value : v)));

  return (
    <>
      {(props.questions || []).map((question, i) => (
        <FormRow key={i + Date.now()}>
          <div className={globalStyles.flex}>
            <Input
              readOnly={props.readOnly}
              style={{ flex: 1 }}
              name={`${props.inputName || "securityQuestions"}[${i}]`}
              placeholder="Vraag"
              defaultValue={question}
              onBlur={(e) =>
                e.currentTarget.value !== question &&
                change(e.currentTarget.value, i)
              }
              required
            />
            {!props.readOnly && (
              <Button ghost style={{ flex: 0 }} onClick={() => remove(i)}>
                <Image src={xIcon} height={15} alt={"x"} />
              </Button>
            )}
          </div>
        </FormRow>
      ))}
      {!props.readOnly && (
        <Button ghost onClick={add}>
          {dictionary.add_question}
        </Button>
      )}
    </>
  );
};
