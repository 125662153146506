import { IUser } from "@hulanbv/afbouwkeur-packages";
import { IOption } from "../components/containers/dropdown";
import { ISearchOption } from "../components/controls/search";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import routes from "../routes";
import { routerService } from "../services/router.service";
import { userService } from "../services/user.service";
import { isNil, json } from "./statics.utils";

export const userUtils = new (class Utils {
  /**
   * Returns the users as IOption array
   * @param users
   */
  public toOption(
    users: (IUser | undefined | null)[],
    onClick?: (option: IOption) => void
  ): ISearchOption[] {
    return users
      .filter((user) => !isNil(user))
      .map((user) => ({
        key: user!._id,
        value: `${user!.fullname}`,
        image: user!.imagePath,
        onClick:
          onClick ||
          ((option) => {
            routerService.navigate(routes.contact.path(option.key));
          }),
      }));
  }

  /**
   * Search users and retrieve options
   * @param query
   */
  public async searchUserOptions(
    options: IHttpOptions
  ): Promise<ISearchOption[]> {
    return this.toOption(
      await json<IUser[]>(
        userService.getAll({
          searchScope: ["firstName", "lastName"],

          ...options,
        })
      )
    );
  }
})();
