import { INewsItem, Role } from "@hulanbv/afbouwkeur-packages";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { style } from "typestyle";
import { Button } from "../components/controls/button";
import { Pagination } from "../components/controls/pagination";
import { NewsItem } from "../components/data/newsItem";
import colors from "../constants/colors.constants";
import { dictionary } from "../constants/i18n/dictionary";
import routes from "../routes";
import { newsService } from "../services/news.service";
import { routerService } from "../services/router.service";
import { authUtils } from "../utils/authentication.utils";

export const NewsView: React.FC<RouteComponentProps> = (props) => {
  const [models, setModels] = useState<INewsItem[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const limit = 10;

  useEffect(() => {
    const fetchModels = async () => {
      const response = await newsService.getAll({
        sort: ["-isPinned", "-createdAt"],
        populate: [],
        limit,
        offset: limit * (page - 1),
      });
      setTotalCount(+(response.headers.get("X-total-count") ?? 0));
      setModels(await response.json());
    };

    fetchModels();
  }, [page]);

  if (models === null) {
    return <></>;
  }
  return (
    <>
      <div className={styles.flex}>
        {authUtils.satisfiesRoles(Role.SYSTEM_ADMIN, Role.ADMIN) && (
          <Button
            onClick={() => routerService.navigate(routes.newsCreate.path())}
          >
            {dictionary.new}
          </Button>
        )}
      </div>
      {models.length === 0 && (
        <div className={styles.empty}>Geen nieuwsberichten gevonden</div>
      )}
      {models.map((model) => {
        const isAdmin = authUtils.satisfiesRoles(Role.SYSTEM_ADMIN, Role.ADMIN);
        return (
          <div
            key={model._id}
            className={isAdmin ? styles.clickable : undefined}
            onClick={
              isAdmin
                ? () => routerService.navigate(routes.newsEdit.path(model._id))
                : undefined
            }
          >
            <NewsItem item={model} />
          </div>
        );
      })}
      <div className={styles.pagination}>
        <Pagination
          page={page}
          totalPages={Math.ceil(totalCount / limit) || 1}
          onPageSelect={setPage}
        />
      </div>
    </>
  );
};

const styles = {
  flex: style({
    display: "flex",
    $nest: {
      "& > button": {
        marginLeft: "auto",
        flex: 0,
      },
    },
  }),

  clickable: style({
    cursor: "pointer",
    $nest: {
      "&:hover": {
        background: "#fafafa",
      },
    },
  }),

  pagination: style({
    margin: "30px 0",
  }),

  empty: style({
    color: colors.text.secondary,
    textAlign: "center",
    padding: 20,
  }),
};
