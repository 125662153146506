import React from "react";
import { formUtils } from "../../utils/form.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Input } from "../controls/input";

interface IProps {
  onSubmit: (email: string) => any;
}

export const ResetPasswordRequestForm: React.FC<IProps> = props => (
  <form
    onSubmit={e => {
      e.preventDefault();
      props.onSubmit(
        formUtils.getFormDataAsJSON<{ email: string }>(e.currentTarget).email
      );
    }}
  >
    <FormRow label="E-mail">
      <Input name="email" type="email" placeholder="E-mail" />
    </FormRow>
    <FormRow>
      <Button type="submit"> Verstuur </Button>
    </FormRow>
  </form>
);
