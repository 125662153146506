import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Subscription } from "rxjs";
import { authenticationService } from "../../services/authentication.service";
import { routerService } from "../../services/router.service";

class NavigatorClass extends React.Component<RouteComponentProps> {
  private routeChangedListener: Subscription | null = null;

  componentDidMount() {
    // validate the stored token when the app is started
    if (authenticationService.getSessionToken()) {
      authenticationService.validate();
    }

    this.routeChangedListener = routerService.onRouteChange.subscribe(path =>
      this.props.history.push(path)
    );

    this.props.history.listen(() => window.scrollTo(0, 0));
  }

  componentWillUnmount() {
    this.routeChangedListener!.unsubscribe();
  }

  render() {
    return <></>;
  }
}

export const Navigator = withRouter(NavigatorClass);
