import { Loader } from "../components/statics/loader";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { IResponse } from "../interfaces/response.interface";
import { sleep } from "../utils/statics.utils";
import { authenticationService } from "./authentication.service";

export const httpService = new (class Service {
  /**
   * Executes an http request
   * @param method
   * @param query
   * @param body
   */
  private async send<Model>(
    method: string,
    query: string,
    body?: Object | null,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    const sessionToken = authenticationService.getSessionToken();
    const queryParams = this.getQueryParams(options || {});
    const init: { [key: string]: any } = {
      method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    };

    // add body and auth token if provided
    if (body) {
      if (body instanceof FormData) {
        delete init.headers["Content-Type"];
        init.body = body;
      } else {
        init.body = JSON.stringify(body);
      }
    }

    if (sessionToken) {
      init.headers.Authorization = sessionToken.token;
    }

    if (options && options.loader) {
      Loader.mount({});
    }

    const response: IResponse<Model> = await fetch(
      [process.env.REACT_APP_API_URL, query + queryParams].join("/"),
      init
    );

    // add a timeout to give it more of an "app feel"
    if (options && options.useTimeout) {
      await sleep(500);
    }

    if (options && options.loader) {
      Loader.unmount();
    }

    // logout if the user is unauthorized
    if (response.status === 401) {
      authenticationService.logout();
    }

    // fetch doesn't error on all required codes by default
    if (response.status >= 400) {
      throw response;
    }

    return response;
  }

  /**
   * Executes a GET request
   * @param query
   */
  public get<Model>(
    query: string,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    return this.send<Model>("GET", query, null, options);
  }

  /**
   * Executes a POST request
   * @param query
   * @param body
   */
  public post<Model>(
    query: string,
    body: any,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    return this.send<Model>("POST", query, body, options);
  }

  /**
   * Executes a PUT request
   * @param query
   * @param body
   */
  public put<Model>(
    query: string,
    body: any,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    return this.send<Model>("PUT", query, body, options);
  }

  /**
   * Executes a PATCH request
   * @param query
   * @param body
   */
  public patch<Model>(
    query: string,
    body: any,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    return this.send<Model>("PATCH", query, body, options);
  }

  /**
   * Executes a DELETE request
   * @param query
   */
  public delete<Model>(
    query: string,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    return this.send<Model>("DELETE", query, options);
  }

  private getQueryParams(options: IHttpOptions): string {
    return (
      "?" +
      Object.keys(options)
        .map(key => {
          const item = (options as any)[key];
          if (Array.isArray(item)) {
            return `${key}=${item.join(",")}`;
          }
          if (typeof item === "object") {
            return Object.keys(item)
              .map(subKey => `${key}[${subKey}]=${item[subKey]}`)
              .join("&");
          }
          return `${key}=${item}`;
        })
        .join("&")
    );
  }
})();
