import { INewsItem } from "@hulanbv/afbouwkeur-packages";
import marked from "marked";
import React, { FC } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { formatDate } from "../../utils/date.utils";

interface IProps {
  item: INewsItem;
}

export const NewsItem: FC<IProps> = (props) => (
  <div className={styles.item}>
    <div className={styles.title}>{props.item.title}</div>
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: marked(props.item.content) }}
    />
    <div className={styles.details}>
      {props.item.author?.fullname} -{" "}
      {formatDate(
        props.item.createdAt?.toString() ?? "",
        dictionary.date_format
      )}
    </div>
  </div>
);

const styles = {
  item: style({
    padding: "30px 15px 30px 15px",
    borderBottom: "1px solid #eee",
  }),
  title: style({
    fontWeight: 500,
  }),
  content: style({
    padding: "20px 0",
  }),
  details: style({
    opacity: 0.54,
  }),
};
