import {
  ICompany,
  ReportType,
  Role,
  Severity,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { notificationListener } from "../../observables/notification-listener";
import routes from "../../routes";
import { companyService } from "../../services/company.service";
import { routerService } from "../../services/router.service";
import { formUtils } from "../../utils/form.utils";
import { userUtils } from "../../utils/user.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { InputGroup } from "../controls/inputGroup";
import Search from "../controls/search";
import { SecurityQuestions } from "./components/securityQuestions";
import { Select } from "../controls/select";
import { isNil } from "lodash";
import { reportTypeValues } from "../../constants/enumValues.constants";
import { authUtils } from "../../utils/authentication.utils";

interface IState {
  company: ICompany;
}

interface IProps {
  company?: ICompany;
  readOnly?: boolean;
  onSubmit: (formData: FormData) => any;
  onImageChange?: (image: string) => any;
}

export default class CompanyForm extends React.Component<IProps, IState> {
  private formRef = React.createRef<HTMLFormElement>();
  private file: File | null = null;
  public state: IState = {
    company: {
      _id: null,
      imagePath: undefined,
      name: "",
      reportType: ReportType.NON_SPECIFIC,
      location: {
        address: "",
        city: "",
        zipCode: "",
        coordinates: [0, 0],
      },
      securityQuestions: [""],
    },
  };

  componentDidMount() {
    const company = {
      ...this.state.company,
      ...this.props.company,
    };

    this.setState({ company });
  }

  render() {
    return (
      <form
        key={this.state.company ? this.state.company._id : ""}
        ref={this.formRef}
        onSubmit={(e) => {
          e.preventDefault();
          this.submit();
        }}
      >
        <InputGroup
          readOnly={this.props.readOnly}
          inputs={[
            {
              name: "_id",
              type: "hidden",
              defaultValue: this.state.company._id || undefined,
              required: false,
            },
            {
              name: "_imagePath",
              label: dictionary.image,
              type: this.props.readOnly ? "hidden" : "file",
              required: false,
              onChange: async (e) => {
                this.file = await formUtils.processFileInput(
                  e,
                  this.props.onImageChange
                );
              },
            },
            {
              name: "name",
              label: dictionary.participant_name,
              placeholder: dictionary.participant_name,
              defaultValue: this.state.company.name,
            },
            {
              name: "location.address",
              label: dictionary.address,
              placeholder: dictionary.address,
              defaultValue: this.state.company.location.address,
            },
            [
              {
                name: "location.city",
                label: dictionary.city,
                placeholder: dictionary.city,
                defaultValue: this.state.company.location.city,
              },
              {
                name: "location.zipCode",
                label: dictionary.zip_code,
                placeholder: dictionary.zip_code,
                defaultValue: this.state.company.location.zipCode,
              },
            ],
          ]}
        />

        <FormRow label={dictionary.manager}>
          <Search
            readOnly={this.props.readOnly}
            name="managerId"
            searchMethod={(search) =>
              userUtils.searchUserOptions({ search, limit: 5 })
            }
            defaultValue={userUtils.toOption([this.state.company.manager])}
            placeholder={dictionary.find_a_manager}
          />
        </FormRow>

        <FormRow label="Rapportage type">
          <Select
            disabled={
              this.props.readOnly ||
              !authUtils.satisfiesRoles(Role.SYSTEM_ADMIN, Role.ADMIN)
            }
            defaultValue={
              isNil(this.state.company.reportType)
                ? ""
                : this.state.company.reportType!.toString()
            }
            options={Object.entries(reportTypeValues).map(([key, value]) => ({
              value,
              key,
            }))}
            name="reportType"
          />
        </FormRow>

        <FormRow label={dictionary.default_lmra_questions}>
          <SecurityQuestions
            readOnly={this.props.readOnly}
            questions={this.state.company.securityQuestions}
            onChange={(securityQuestions) =>
              this.setState({
                company: { ...this.state.company, securityQuestions },
              })
            }
          />
        </FormRow>

        {!this.props.readOnly && (
          <>
            <FormRow>
              <Button type="submit">
                {this.state.company._id ? dictionary.update : dictionary.create}
              </Button>
            </FormRow>
            {this.state.company._id && (
              <FormRow>
                <Button ghost onClick={() => this.delete()}>
                  {dictionary.delete}
                </Button>
              </FormRow>
            )}
          </>
        )}
      </form>
    );
  }

  private async delete() {
    const id = this.state.company._id;
    if (!id) {
      return;
    }

    if (
      window.confirm(
        dictionary.are_you_sure_you_want_to_delete_x(dictionary.company)
      )
    ) {
      try {
        await companyService.delete(id, { loader: true });
        routerService.navigate(routes.companies.path());

        notificationListener.next({
          message: dictionary.the_x_has_been_successfully_removed(
            dictionary.company
          ),
          severity: Severity.SUCCESS,
        });
      } catch (err) {
        notificationListener.next({
          message: dictionary.something_went_wrong,
          severity: Severity.ALERT,
        });
      }
    }
  }

  /**
   * Store the form data in the company attribute
   */
  private storeFormData(callback?: () => any) {
    let company = this.state.company;
    if (this.formRef.current) {
      company = formUtils.getFormDataAsJSON<ICompany>(this.formRef.current);
    }
    this.setState({ company }, callback || (() => {}));
  }

  /**
   * submit the form
   */
  private submit() {
    this.storeFormData(() => {
      const formData = formUtils.jsonToFormData(this.state.company);
      if (this.file) {
        formData.append("file", this.file);
      }

      this.props.onSubmit(formData);
    });
  }
}
