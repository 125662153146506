import { ISubmissionComment, Severity } from "@hulanbv/afbouwkeur-packages";
import React, { FC } from "react";
import { SubmissionCommentForm } from "../components/forms/submissionCommentForm";
import { notificationListener } from "../observables/notification-listener";
import { authenticationService } from "../services/authentication.service";
import { submissionCommentService } from "../services/submissionComment.service";
import { json } from "../utils/statics.utils";
import { dialog } from "./dialog";

interface IProps {
  model?: Partial<ISubmissionComment>;
  submissionId: string;
  onCreated?: (model: ISubmissionComment) => void;
}

export const CreateSubmissionCommentDialog: FC<IProps> = (props) => {
  const save = async (model: FormData) => {
    const created = await json<ISubmissionComment>(
      submissionCommentService.create(model)
    );
    props.onCreated?.(created);

    notificationListener.next({
      message: "De reactie is succesvol aangemaakt",
      severity: Severity.SUCCESS,
    });
    dialog.unmount();
  };

  return (
    <SubmissionCommentForm
      model={{
        ...props.model,
        submissionId: props.submissionId,
        authorId: authenticationService.getSessionToken()?.userId,
      }}
      onSubmit={save}
    />
  );
};
