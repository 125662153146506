import { IProject } from "@hulanbv/afbouwkeur-packages";
import { DateTime } from "luxon";
import React from "react";
import { dictionary } from "../../../constants/i18n/dictionary";
import routes from "../../../routes";
import { routerService } from "../../../services/router.service";
import { TD } from "../../containers/tables/tableCell";
import { TR } from "../../containers/tables/tableRow";
import { Circle } from "../../shapes/circle";

export const projectRowHeadings = () => ({
  imagePath: "",
  name: dictionary.name,
  "customer.name": dictionary.employer,
  "location.city": dictionary.place,
  createdAt: dictionary.creation_date,
});

interface IProps {
  project: IProject;
}

export const ProjectRow: React.FC<IProps> = (props) => (
  <TR
    onClick={() =>
      routerService.navigate(routes.project.path(props.project._id))
    }
  >
    <TD style={{ width: 50 }}>
      <Circle
        size={35}
        background={
          props.project.imagePath
            ? `url(${props.project.imagePath}) center center/cover no-repeat`
            : "rgba(0, 0, 0, 0.05)"
        }
      />
    </TD>
    <TD>{props.project.name}</TD>
    <TD>{props.project.customer ? props.project.customer.name : ""}</TD>
    <TD>{props.project.location.city}</TD>

    <TD>
      {DateTime.fromISO(props.project.createdAt!.toString()).toFormat(
        "dd-MM-yyyy"
      )}
    </TD>
  </TR>
);
