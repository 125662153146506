import { ISecurityQuestion } from "@hulanbv/afbouwkeur-packages";
import { CrudService } from "./crudService.abstract.service";

export const securityQuestionService = new (class Service extends CrudService<
  ISecurityQuestion
> {
  constructor() {
    super("security-question");
  }
})();
