import {
  CustomerType,
  ICustomer,
  Role,
  Severity,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { customerTypeValues } from "../../constants/enumValues.constants";
import { dictionary } from "../../constants/i18n/dictionary";
import { CreateUserDialog } from "../../dialogs/createUser.dialog";
import { dialog } from "../../dialogs/dialog";
import { notificationListener } from "../../observables/notification-listener";
import routes from "../../routes";
import { authenticationService } from "../../services/authentication.service";
import { customerService } from "../../services/customer.service";
import { routerService } from "../../services/router.service";
import { authUtils } from "../../utils/authentication.utils";
import { companyUtils } from "../../utils/company.utils";
import { formUtils } from "../../utils/form.utils";
import { userUtils } from "../../utils/user.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { InputGroup } from "../controls/inputGroup";
import Search from "../controls/search";
import { Select } from "../controls/select";

interface IState {
  customer: ICustomer;
}

interface IProps {
  customer?: ICustomer;
  onSubmit: (formData: FormData) => any;
  onImageChange?: (image: string) => any;
}

export default class CustomerForm extends React.Component<IProps, IState> {
  private formRef = React.createRef<HTMLFormElement>();
  private file: File | null = null;
  public state: IState = {
    customer: {
      _id: null,
      name: "",
      imagePath: undefined,
      location: { address: "", city: "", zipCode: "", coordinates: [0, 0] },
      contactIds: [],
      companyId: "",
      type: CustomerType.PRIVATE,
    },
  };

  componentDidMount() {
    const customer = {
      ...this.state.customer,
      ...this.props.customer,
    };

    this.setState({ customer });
  }

  render() {
    return (
      <form
        key={this.state.customer._id}
        ref={this.formRef}
        onSubmit={(e) => {
          e.preventDefault();
          this.submit();
        }}
      >
        <InputGroup
          inputs={[
            {
              name: "_id",
              type: "hidden",
              defaultValue: this.state.customer._id || undefined,
              required: false,
            },
            {
              name: "_imagePath",
              label: "Afbeelding",
              type: "file",
              required: false,
              onChange: async (e) => {
                this.file = await formUtils.processFileInput(
                  e,
                  this.props.onImageChange
                );
              },
            },
            {
              name: "customerNumber",
              label: "Klantnummer",
              placeholder: "Klantnummer",
              type: "text",
              defaultValue: this.state.customer.customerNumber,
            },
            {
              name: "name",
              label: "Opdrachtgever naam",
              placeholder: "Opdrachtgever naam",
              type: "text",
              defaultValue: this.state.customer.name,
            },
            {
              name: "location.address",
              label: "Adres",
              type: "text",
              placeholder: "Adres",
              defaultValue: this.state.customer.location.address,
            },
            [
              {
                name: "location.zipCode",
                label: "Postcode",
                type: "text",
                placeholder: "Postcode",
                defaultValue: this.state.customer.location.zipCode,
              },
              {
                name: "location.city",
                label: "Stad",
                type: "text",
                placeholder: "Stad",
                defaultValue: this.state.customer.location.city,
              },
            ],
          ]}
        />

        <FormRow label="Contact(en)" optional={true}>
          <div style={{ display: "flex" }}>
            <Search
              key={this.state.customer.contacts?.length}
              name="contactIds"
              multiple
              searchMethod={(query) =>
                userUtils.searchUserOptions({
                  search: query,
                  filter: { role: Role.CONTACT.toString() },
                  limit: 5,
                })
              }
              defaultValue={userUtils.toOption(
                this.state.customer.contacts || []
              )}
              placeholder="Vind een contact"
            />
            <Button
              ghost
              compact
              style={{ flex: 0 }}
              onClick={() =>
                dialog.mount({
                  title: "Contact aanmaken",
                  children: (
                    <CreateUserDialog
                      roles={[Role.CONTACT]}
                      onCreated={(user) => {
                        this.setState((_state) => {
                          const state = { ..._state };
                          state.customer.contactIds.push(user._id);
                          state.customer.contacts =
                            state.customer.contacts || [];
                          state.customer.contacts.push(user);
                          return state;
                        });
                      }}
                    />
                  ),
                })
              }
            >
              {dictionary.new}
            </Button>
          </div>
        </FormRow>

        <FormRow label="type">
          <Select
            name="type"
            defaultValue={(this.state.customer.type || "").toString()}
            options={Object.keys(customerTypeValues).map((key) => ({
              key,
              value: customerTypeValues[key],
            }))}
          />
        </FormRow>

        {authUtils.satisfiesRoles(Role.ADMIN, Role.SYSTEM_ADMIN) ? (
          <FormRow label="Deelnemer">
            <Search
              required
              name="companyId"
              searchMethod={(query) => companyUtils.searchCompanyOptions(query)}
              defaultValue={companyUtils.toOption([
                this.state.customer.company,
              ])}
              placeholder="Vind een deelnemer"
            />
          </FormRow>
        ) : (
          <input
            name="companyId"
            value={authenticationService.getSessionToken()?.user?.employedById}
            type="hidden"
          />
        )}

        <FormRow>
          <Button type="submit">
            {this.state.customer._id ? "Aanpassen" : "Aanmaken"}
          </Button>
        </FormRow>

        {this.state.customer._id && (
          <FormRow>
            <Button ghost onClick={() => this.delete()}>
              Verwijderen
            </Button>
          </FormRow>
        )}
      </form>
    );
  }

  /**
   * Delete a customer
   */
  private async delete() {
    const id = this.state.customer._id;
    if (!id) {
      return;
    }

    if (window.confirm("Weet u zeker dat u dit contact wilt verwijderen?")) {
      try {
        await customerService.delete(id, { loader: true });
        routerService.navigate(routes.contacts.path());

        notificationListener.next({
          message: "Het contact is succesvol verwijderd",
          severity: Severity.SUCCESS,
        });
      } catch (err) {
        notificationListener.next({
          message: "Er ging iets mis",
          severity: Severity.ALERT,
        });
      }
    }
  }

  /**
   * Store the form data in the customer attribute
   */
  private storeFormData(callback?: () => any) {
    let customer = this.state.customer;
    if (this.formRef.current) {
      customer = formUtils.getFormDataAsJSON<ICustomer>(this.formRef.current);
    }
    this.setState({ customer }, callback || (() => {}));
  }

  /**
   * submit the form
   */
  private submit() {
    this.storeFormData(() => {
      const formData = formUtils.jsonToFormData(this.state.customer);
      if (this.file) {
        formData.append("file", this.file);
      }

      this.props.onSubmit(formData);
    });
  }
}
