/* eslint-disable react-hooks/exhaustive-deps */
import { IQuestionnaire, Severity } from "@hulanbv/afbouwkeur-packages";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { QuestionnaireForm } from "../components/forms/questionnaireForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { questionnaireService } from "../services/questionnaire.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";

export const QuestionnaireFormView: React.FC<RouteComponentProps> = (props) => {
  const { questionnaireId, companyId } = (props.match as any).params;
  const newModel = props.location.pathname.includes("/new");
  const [model, setModel] = useState<IQuestionnaire>();

  const submit = async (questionnaire: FormData) => {
    const id = questionnaire.get("_id");
    if (companyId) {
      questionnaire.set("companyId", companyId);
    }

    if (id) {
      await questionnaireService.patch(questionnaire);
    } else {
      await questionnaireService.create(questionnaire);
    }
    notificationListener.next({
      message: "De vragenlijst is opgeslagen",
      severity: Severity.SUCCESS,
    });
    if (companyId) {
      routerService.navigate(routes.company.path(companyId));
    } else {
      routerService.navigate(routes.questionnaires.path());
    }
  };

  const deleteModel = async () => {
    if (!window.confirm("Weet je zeker dat je deze lijst wilt verwijderen?")) {
      return;
    }

    await questionnaireService.delete(model?._id);
    notificationListener.next({
      message: "De lijst is succesvol verwijderd",
      severity: Severity.SUCCESS,
    });
    if (companyId) {
      routerService.navigate(routes.company.path(companyId));
    } else {
      routerService.navigate(routes.questionnaires.path());
    }
  };

  useEffect(() => {
    const fetchModel = async () => {
      const model = await json<IQuestionnaire>(
        questionnaireService.get(questionnaireId)
      );
      if (newModel) {
        model._id = undefined;
      }
      setModel(model);
    };
    if (questionnaireId) {
      fetchModel();
    }
  }, [props.match]);

  if (questionnaireId && !model) {
    return <></>;
  }
  return (
    <Card attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}>
      <QuestionnaireForm questionnaire={model} onSubmit={submit} />
      {model?._id && (
        <Button ghost onClick={deleteModel}>
          Verwijderen
        </Button>
      )}
    </Card>
  );
};
