import React, { InputHTMLAttributes } from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Input: React.FC<IProps> = (props) => (
  <input
    {...{
      type: "text",
      ...props,
      placeholder: props.placeholder ?? (props.readOnly ? "-" : undefined),
      className: [
        map.input,
        props.readOnly ? map.readOnly : "",
        props.className,
      ].join(" "),
    }}
  />
);

const { map } = new Sheet(`
  map input
    border 1 solid #CDCDCD
    outline 0
    padding 10
    position relative
    margin 0 5
    fontSize 1em
    width -webkit-fill-available
  
    borderRadius 8
    color ${colors.text.secondary}
    fontWeight 500
    boxShadow 0px 0px 0px 0px #00000019

    transition .1s all

  map input !focus
    boxShadow 0px 0px 3px 0px #00000019
    
  map input !hover
    boxShadow 0px 0px 3px 0px #00000019
    
  map input !placeholder
    color ${colors.text.tertiary}

  map readOnly
    border 0 
    padding 5 10
    cursor default 
    background transparent

  map readOnly !focus
    boxShadow none  
  
  map readOnly !hover
    boxShadow none 

`);

export const inputStyles = map.input;

export const inputReadOnlyStyles = map.readOnly;
