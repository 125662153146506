import React from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

export const Footer: React.FC = () => (
  <footer className={map.footer}>
    {/* Hulan b.v. - {new Date().getFullYear()} */}
  </footer>
);

const { map } = new Sheet(`
  map footer
    color ${colors.text.secondary}
    fontSize 1em
    textAlign center
    margin 125 0
`);
