import { IAttachment, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { AttachmentForm } from "../components/forms/attachmentForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { attachmentService } from "../services/attachment.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";

interface IState {
  attachment: IAttachment | null;
}

export default class AttachmentFormView extends React.Component<
  RouteComponentProps,
  IState
> {
  private attachmentId: string | null = null;
  private projectId: string;
  private assignmentId: string;
  public state: IState = {
    attachment: null,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    const { attachmentId, projectId, assignmentId } = (this.props
      .match as any).params;
    if (attachmentId && attachmentId !== "new") {
      this.attachmentId = attachmentId;
    }
    this.projectId = projectId;
    this.assignmentId = assignmentId;
  }

  componentDidMount() {
    this.fetchAssignment();
  }

  render() {
    return (
      <>
        {(!this.attachmentId || this.state.attachment) && (
          <Card
            attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}
            imageSize="cover"
          >
            <AttachmentForm
              onSubmit={(attachmentData) => this.submit(attachmentData)}
              attachment={
                this.state.attachment || {
                  assignmentId: this.assignmentId || "",
                  projectId: !this.assignmentId ? this.projectId || "" : "",
                }
              }
            />
          </Card>
        )}
      </>
    );
  }

  private async submit(attachment: FormData) {
    try {
      const created = await json<IAttachment>(
        attachmentService.createOrPatch(attachment as any, {
          loader: true,
        })
      );
      notificationListener.next({
        message: "De bijlage is succesvol opgeslagen",
        severity: Severity.SUCCESS,
      });

      if (created.projectId) {
        routerService.navigate(routes.project.path(created.projectId));
      } else if (created.assignmentId) {
        routerService.navigate(
          routes.projectAssignment.path(this.projectId, this.assignmentId)
        );
      }
    } catch (err) {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.ALERT,
      });
    }
  }

  private async fetchAssignment() {
    if (!this.attachmentId) {
      return;
    }

    const attachment = await json<IAttachment>(
      attachmentService.get(this.attachmentId)
    );
    this.setState({ attachment });
  }
}
