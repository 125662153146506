import { ICompany, IUser, Role, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import UserForm from "../components/forms/userForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { companyService } from "../services/company.service";
import { routerService } from "../services/router.service";
import { userService } from "../services/user.service";
import { authUtils } from "../utils/authentication.utils";
import { json } from "../utils/statics.utils";

interface IState {
  employee: IUser | null;
  image?: string;
}

export default class EmployeeFormView extends React.Component<
  RouteComponentProps,
  IState
> {
  private employeeId: string | null = null;
  private companyId: string | null = null;
  private roles: Role[] = [];
  public state: IState = {
    employee: null,
    image: undefined,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    const employeeId = (this.props.match as any).params.employeeId;
    if (employeeId && employeeId !== "new") {
      this.employeeId = employeeId;
    }

    const companyId = (this.props.match as any).params.companyId;
    if (companyId && companyId !== "new") {
      this.companyId = companyId;
    }
  }

  componentDidMount() {
    this.roles = [Role.EXECUTOR, Role.SELF_EMPLOYED, Role.MANAGER];
    if (authUtils.satisfiesRoles(Role.ADMIN, Role.SYSTEM_ADMIN)) {
      this.roles.push(Role.ADMIN);
    }
    if (authUtils.satisfiesRoles(Role.SYSTEM_ADMIN)) {
      this.roles.push(Role.SYSTEM_ADMIN);
    }

    this.fetchEmployee();
  }

  render() {
    return (
      <>
        {this.state.employee && (
          <Card
            attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}
            images={this.state.image ? [this.state.image] : undefined}
            imageSize="cover"
          >
            <UserForm
              onImageChange={(image) => this.setState({ image })}
              onSubmit={(employee) => this.submit(employee)}
              user={this.state.employee || undefined}
              roles={this.roles}
            />
          </Card>
        )}
      </>
    );
  }

  /**
   * Submit the employee form
   * @param employee
   */
  private async submit(employee: FormData) {
    try {
      const created = await json<IUser>(
        userService.createOrPatch(employee as any, { loader: true })
      );
      notificationListener.next({
        message: "Het employee is succesvol opgeslagen",
        severity: Severity.SUCCESS,
      });

      if (this.companyId) {
        routerService.navigate(routes.company.path(this.companyId));
      } else if (created.role === Role.CONTACT) {
        routerService.navigate(routes.contacts.path());
      } else {
        routerService.navigate(routes.employees.path());
      }
    } catch (err) {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.ALERT,
      });
    }
  }

  /**
   * Fetch the concerning employee or create an empty one
   */
  private async fetchEmployee() {
    if (!this.employeeId) {
      const employedById = this.companyId || authUtils.getEmployer();
      const employedBy = employedById
        ? await json<ICompany>(companyService.get(employedById))
        : null;

      this.setState({
        employee: {
          employedById,
          employedBy,
        } as IUser,
      });

      return;
    }

    const employee = await json<IUser>(userService.get(this.employeeId));
    this.setState({ employee, image: employee.imagePath || undefined });
  }
}
