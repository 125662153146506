import React from "react";
import { RouteComponentProps } from "react-router";
import { authenticationService } from "../services/authentication.service";

export default class LogoutView extends React.Component<RouteComponentProps> {
  componentDidMount() {
    authenticationService.logout();
  }

  render() {
    return <></>;
  }
}
