import React, { SelectHTMLAttributes } from "react";
import { Sheet } from "strcss";
import { classes } from "typestyle";
import { IOption } from "../containers/dropdown";
import { inputStyles } from "./input";

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: IOption[];
}

export const Select: React.FC<IProps> = (props) => (
  <select
    {...{
      ...props,
      className: classes(
        inputStyles,
        !props.disabled && map.activeSelect,
        map.select,
        props.className
      ),
      options: undefined,
    }}
  >
    {props.options.map((option) => (
      <option key={option.value} value={option.key}>
        {option.value}
      </option>
    ))}
    {props.children}
  </select>
);

const { map } = new Sheet(`
  map select
    -webkit-appearance none
    border none

  map activeSelect 
    border 1 solid #dedede
    backgroundImage linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
    backgroundPosition calc(100% - 20px) calc(1em + 5px), calc(100% - 15px) calc(1em + 5px)
    backgroundSize 5px 5px, 5px 5px
    backgroundRepeat no-repeat;
    backgroundColor white
    paddingRight 30
`);
