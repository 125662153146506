import React, {
  FC,
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { classes, style } from "typestyle";
import colors from "../../constants/colors.constants";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  defaultValue?: boolean;
  value?: boolean;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: boolean) => Promise<void> | void;
}

export const Checkbox: FC<IProps> = (props) => {
  const [value, setValue] = useState<boolean>(
    props.value ?? props.defaultValue ?? false
  );

  /**
   * Handles a click on the checkbox container
   * @param ev
   */
  const handleOnClick = (ev: MouseEvent<HTMLDivElement>) => {
    if (props.disabled || props.readOnly) {
      return;
    }

    setValue(!value);
    props.attributes?.onClick?.(ev);
  };

  /**
   * Handles keypresses while focusin the checkbox
   * @param ev
   */
  const handelKeyPress = (ev: KeyboardEvent) => {
    if (props.disabled || props.readOnly) {
      return;
    }

    // toggle the checkbox when spacebar was pressed
    if (ev.key === " ") {
      setValue(!value);
    }
  };

  useEffect(() => {
    setValue(props.value ?? props.defaultValue ?? false);
  }, [props.defaultValue, props.value]);

  return (
    <div
      {...{
        ...props.attributes,
        className: classes(
          styles.container,
          props.attributes?.className,
          !props.disabled && !props.readOnly && styles.enabled
        ),
      }}
      onClick={handleOnClick}
    >
      {props.name && !props.disabled && (
        <input type="hidden" name={props.name} value={value.toString()} />
      )}

      <div
        className={classes(styles.checkbox, value && styles.checked)}
        tabIndex={0}
        onKeyPress={handelKeyPress}
      />

      {props.children}
    </div>
  );
};

export const styles = {
  container: style({
    display: "flex",
    alignItems: "center",
  }),
  enabled: style({
    cursor: "pointer",
  }),

  checkbox: style({
    borderRadius: 2,
    outline: 1,
    border: "1px solid #D1D5DB",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    width: 15,
    height: 15,
    marginRight: 10,
    marginLeft: 10,
  }),
  checked: style({
    background: colors.brand.gradient.diagonal,
  }),
};
