export const en = {
  active_assignments: "Active assignments",
  add_question: "Add question",
  all_attachments_have_been_successfully_downloaded:
    "All attachments have been successfully downloaded",
  attachment: "Attachment",
  attachments: "Attachments",
  apply: "Apply",
  cancel: "Cancel",
  confirm: "Confirm",
  completed_on: "Completed on",
  creation_date: "Creation date",
  customerNumber: "Customer number",
  date_format: "y-LL-dd",
  date_format_string: "yyyy-mm-dd",
  defaults_to_manager: "Defaults to manager",
  description: "Description",
  distributed_by: "Distributed by",
  download_attachments: "Download attachments",
  email: "Email",
  employer: "Employer",
  executors: "Executors",
  filters_active: "Filter(s) active",
  finished_assignments: "Finished assignments",
  job_title: "Job title",
  location: "Location",
  manager: "Manager",
  name: "Name",
  new: "New",
  news: "News",
  no_submissions_found_for_this_assignment:
    "No submissions found for this assignment",
  on__off: "On/off",
  ongoing: "Ongoing",
  participant: "Participant",
  phone_number: "Phone number",
  place: "Place",
  print: "Print",
  print_project: "Print project",
  print_assignment: "Print assignment",
  project: "Project",
  project_number: "Project number",
  risk: "Risk",
  show_active: "Show active",
  show_finished: "Show finished",
  show__hide_on_print: "Show/hide on print",
  status: "Status",
  totalAttachments: "Total attachments",
  type: "Type",
  assignment_name: "Assignment name",
  assignment: "Assignment",
  download_x_attachments_to_device: (total: number) =>
    `Are you sure you want to download ${total} ${
      total === 1 ? "attachment" : "attachments"
    } to your device?`,
  start_date: "Start date",
  executing_employees: "Executing employees",
  find_employees: "Find employees",
  assignment_description: "Assignment description",
  lmra_questions: "LMRA questions",
  update: "Update",
  create: "Create",
  delete: "Delete",
  are_you_sure_you_want_to_delete_x: (x: string) =>
    `Are you sure you want to delete ${x}?`,
  the_x_has_been_successfully_removed: (x: string) =>
    `The ${x} has been successfulle removed`,
  contact: "Contact",
  something_went_wrong: "Something went wrong",
  something_went_wrong_with_downloading_the_attachments:
    "Something went wrong with downloading the attachments",
  file: "File",
  file_description: "File description",
  specifically_authorized_employees: "Specifically authorized employees",
  save: "Save",
  image: "Image",
  participant_name: "Participant name",
  address: "Address",
  city: "City",
  zip_code: "Zip code",
  find_a_manager: "Find a manager",
  default_lmra_questions: "Default LMRA questions",
  company: "Company",
  password: "Password",
  login: "Login",
  title: "Title",
  content: "Content",
  questions: "Questions",
  questionnaire: "Questionnaire",
  questionnaires: "Questionnaires",
  public_questionnaires: "Public questionnaires",
  find_questionnaire: "Find questionnaire",
  questionnaire_templates: "Questionnaire templates",
  show_questionnaires: "Show questionnaires",
  show_templates: "Show templates",
};
