import React from "react";
import { Sheet } from "strcss";
import { globalStyles } from "../../styles";
import { Circle } from "../shapes/circle";

export interface IOption {
  key: string;
  value: string;
  image?: string;
}

interface IProps {
  onSelect: (option: IOption) => any;
  options: IOption[];
}

export const Dropdown: React.FC<IProps> = props => (
  <div className={map.dropdown}>
    {props.options.map(option => (
      <div
        key={option.key}
        className={map.option}
        onClick={() => props.onSelect(option)}
      >
        <Circle
          size={35}
          background={
            option.image
              ? `url(${option.image}) center center/cover no-repeat`
              : "rgba(0, 0, 0, 0.05)"
          }
        />
        <div className={[map.value, globalStyles.ellipsis].join(" ")}>
          {option.value}
        </div>
      </div>
    ))}
  </div>
);

const { map } = new Sheet(`
  map dropdown
    padding 5 0
    background #fff
    border 1 solid #eee
    borderRadius 4
    position absolute
    left 0
    width 100%
    zIndex 999
    margin-top 5
    top 100%

  map option
    padding 10 20
    width 100%
    cursor pointer
    background #fafafa !hover
    display flex
    alignItems center

  map value 
    margin 0 20px
    
`);
