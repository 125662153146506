import React from "react";
import { Sheet } from "strcss";
import { isNil } from "../../utils/statics.utils";
import { ImageCarousel } from "../controls/imageCarousel";
import { Image } from "../controls/image";

interface IProps {
  darkened?: boolean;
  padding?: number;
  images?: string[];
  hasImageNumbers?: boolean;
  head?: string;
  controls?: React.ReactElement;
  imageSize?: "cover" | "contain";
  attr?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

export const Card: React.FC<IProps> = (props) => (
  <div {...props.attr} className={[map.card, props.attr?.className].join(" ")}>
    {(props.head || props.controls) && (
      <h4 className={map.headerText}>
        {props.head} <div>{props.controls}</div>
      </h4>
    )}
    {props.images?.length === 1 && (
      <div className={map.headerImage}>
        <Image
          carousel
          src={props.images[0]}
          alt=""
          style={{ objectFit: props.imageSize }}
          className={map.image}
        />
        {props.hasImageNumbers && <div className={map.imageNumber}>1/1</div>}
      </div>
    )}
    {props.images?.length && props.images.length > 1 && (
      <ImageCarousel
        images={props.images}
        imageSize={props.imageSize}
        showNumbers={props.hasImageNumbers}
      />
    )}

    <div
      style={{
        padding: `${isNil(props.padding) ? 30 : props.padding}px`,
        background: props.darkened ? "#fdfdfd" : undefined,
      }}
    >
      {props.children}
    </div>
  </div>
);

const { map } = new Sheet(`
  map card
    borderRadius 5
    boxShadow 0 2 1 #00000008
    border 1 solid #eaeaea
    position relative
    background #fff
    pageBreakInside avoid

  map headerText
    borderBottom 1 solid #eee
    padding 20 20 20 30
    margin: 0r
    font-weight 600
    display flex
    justifyContent space-between

    margin 0

  map headerImage
    width 100%
    height 280
    boxSizing content-box

  map image
    borderRadius 5 5 0 0
    position relative
    width 100%
    height 100%

  map imageNumber
    position absolute
    top 0
    left 0
    padding 5
    background rgba(0, 0, 0, 0.5)
    color white
    borderRadius 0 5 5 0
`);
