import React, { CSSProperties } from "react";
import { Sheet } from "strcss";
import { SortIndicator } from "./sortIndicator";
import { TH } from "./tableCell";
import { TR } from "./tableRow";

interface IProps {
  style?: CSSProperties;
  sortBy?: string;
  onSelectSortBy?: (key: string) => void;

  headings: { [modelKey: string]: string };
}

export const Table: React.FC<IProps> = props => (
  <table className={map.table} {...(props.style || {})}>
    <thead>
      <TR>
        {Object.keys(props.headings).map((key, i) => (
          <TH
            key={i}
            className={
              props.onSelectSortBy && props.headings[key] ? map.selectable : ""
            }
            onClick={() => {
              if (props.headings[key]) {
                // call the sort method with asc/desc key
                props.onSelectSortBy &&
                  props.onSelectSortBy((key === props.sortBy ? "-" : "") + key);
              }
            }}
          >
            {props.headings[key]}
            {props.sortBy && (
              <SortIndicator columnKey={key} sortBy={props.sortBy} />
            )}
          </TH>
        ))}
      </TR>
    </thead>
    <tbody>{props.children}</tbody>
  </table>
);

const { map } = new Sheet(`
  map table
    width 100%
    borderSpacing 0
    margin 0 0 20px 0

  map selectable
    cursor pointer
    opacity .7 !hover
`);
