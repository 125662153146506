import { IUser, Role, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { roleValues } from "../../constants/enumValues.constants";
import { notificationListener } from "../../observables/notification-listener";
import routes from "../../routes";
import { authenticationService } from "../../services/authentication.service";
import { routerService } from "../../services/router.service";
import { userService } from "../../services/user.service";
import { authUtils } from "../../utils/authentication.utils";
import { companyUtils } from "../../utils/company.utils";
import { formUtils } from "../../utils/form.utils";
import { isNil } from "../../utils/statics.utils";
import { Flex } from "../containers/flex";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox.element";
import { Input } from "../controls/input";
import { InputGroup } from "../controls/inputGroup";
import { PhoneInput } from "../controls/phoneInput";
import Search from "../controls/search";
import { Select } from "../controls/select";

interface IState {
  user: Partial<IUser>;
}

interface IProps {
  user?: Partial<IUser>;
  roles?: Role[];
  onSubmit: (formData: FormData) => any;
  onImageChange?: (image: string) => any;
}

export default class UserForm extends React.Component<IProps, IState> {
  private formRef = React.createRef<HTMLFormElement>();
  private file: File | null = null;

  public state: IState = {
    user: {
      _id: null,
      imagePath: undefined,
      email: "",
      firstName: "",
      insertion: "",
      lastName: "",
      jobTitle: "",
      role: undefined,
      employedById: undefined,
    },
  };

  componentDidMount() {
    const user = {
      ...this.state.user,
      ...this.props.user,
    };

    this.setState({ user });
  }

  render() {
    return (
      <form
        key={this.state.user._id}
        ref={this.formRef}
        onSubmit={(e) => {
          e.preventDefault();
          this.submit();
        }}
      >
        <InputGroup
          inputs={[
            {
              name: "_id",
              type: "hidden",
              defaultValue: this.state.user._id || undefined,
              required: false,
            },
            {
              name: "_imagePath",
              label: "Afbeelding",
              type: "file",
              required: false,
              onChange: async (e) => {
                this.file = await formUtils.processFileInput(
                  e,
                  this.props.onImageChange
                );
              },
            },
            [
              {
                name: "firstName",
                label: "Voornaam",
                placeholder: "Voornaam",
                defaultValue: this.state.user.firstName,
              },
              {
                name: "insertion",
                label: "Tussenvoegsel",
                placeholder: "Tussenvoegsel",
                defaultValue: this.state.user.insertion,
                required: false,
              },
              {
                name: "lastName",
                label: "Achternaam",
                placeholder: "Achternaam",
                defaultValue: this.state.user.lastName,
              },
            ],
          ]}
        />
        <Flex>
          <FormRow label="E-mail">
            <Input
              defaultValue={this.state.user.email}
              type="email"
              name="email"
              placeholder="E-mail"
            />
          </FormRow>
          <FormRow label="Telefoonnummer">
            <PhoneInput
              value={this.state.user.phoneNumber}
              placeholder="Telefoonnummer"
              name="phoneNumber"
            />
          </FormRow>
        </Flex>
        <InputGroup
          inputs={[
            {
              name: "jobTitle",
              label:
                "Functieomschrijving medewerker of bedrijfsnaam onderaannemer",
              placeholder:
                "Voer functieomschrijving of onderaannemers bedrijfsnaam in",
              defaultValue: this.state.user.jobTitle,
              required: false,
            },
          ]}
        />

        {authUtils.satisfiesRoles(Role.ADMIN, Role.SYSTEM_ADMIN) ? (
          <FormRow label="Deelnemer" key={this.state.user.employedById}>
            <Search
              required
              name="employedById"
              searchMethod={(query) => companyUtils.searchCompanyOptions(query)}
              defaultValue={companyUtils.toOption([this.state.user.employedBy])}
              placeholder="Vind een deelnemer"
            />
          </FormRow>
        ) : (
          <input
            name="employedById"
            value={authenticationService.getSessionToken()?.user?.employedById}
            type="hidden"
          />
        )}

        <FormRow label="Rol" optional>
          <Select
            defaultValue={
              isNil(this.state.user.role)
                ? ""
                : this.state.user.role!.toString()
            }
            options={(
              this.props.roles || Object.keys(roleValues).map((v) => +v)
            ).map((key: Role) => ({
              key: key.toString(),
              value: (roleValues as any)[key],
            }))}
            name="role"
          ></Select>
        </FormRow>
        <FormRow>
          <Checkbox
            name="hasLawyerFeature"
            defaultValue={this.state.user?.hasLawyerFeature ?? true}
          >
            Toegang tot de juristen feature
          </Checkbox>
        </FormRow>

        <FormRow>
          <Button type="submit">
            {this.state.user._id ? "Aanpassen" : "Aanmaken"}
          </Button>
        </FormRow>

        {this.state.user._id && (
          <>
            <FormRow>
              <Button ghost onClick={() => this.resetPassword()}>
                Reset wachtwoord
              </Button>
            </FormRow>
            <FormRow>
              <Button ghost onClick={() => this.delete()}>
                Verwijderen
              </Button>
            </FormRow>
          </>
        )}
      </form>
    );
  }

  /**
   * Delete a user
   */
  private async delete() {
    const id = this.state.user._id;
    if (!id) {
      return;
    }

    if (window.confirm("Weet u zeker dat u dit contact wilt verwijderen?")) {
      try {
        await userService.delete(id, { loader: true });
        routerService.navigate(routes.contacts.path());

        notificationListener.next({
          message: "Het contact is succesvol verwijderd",
          severity: Severity.SUCCESS,
        });
      } catch (err) {
        notificationListener.next({
          message: "Er ging iets mis",
          severity: Severity.ALERT,
        });
      }
    }
  }
  private async resetPassword() {
    const id = this.state.user._id;
    if (!id) {
      return;
    }

    if (
      window.confirm(
        "Weet u zeker dat u het wachtwoord van dit contact wilt resetten?"
      )
    ) {
      try {
        await userService.resetPasswordForUser(id, { loader: true });

        notificationListener.next({
          message: "Het wachtwoord is succesvol gereset",
          severity: Severity.SUCCESS,
        });
      } catch (err) {
        notificationListener.next({
          message: "Er ging iets mis",
          severity: Severity.ALERT,
        });
      }
    }
  }

  /**
   * Store the form data in the user attribute
   */
  private storeFormData(callback?: () => any) {
    let user = this.state.user;
    if (this.formRef.current) {
      user = formUtils.getFormDataAsJSON<IUser>(this.formRef.current);
    }
    this.setState({ user }, callback || (() => {}));
  }

  /**
   * submit the form
   */
  private submit() {
    this.storeFormData(() => {
      const formData = formUtils.jsonToFormData(this.state.user);
      if (this.file) {
        formData.append("file", this.file);
      }

      this.props.onSubmit(formData);
    });
  }
}
