import React, { TdHTMLAttributes } from "react";
import { Sheet } from "strcss";

interface IProps extends TdHTMLAttributes<HTMLTableDataCellElement> {}

export const TH: React.FC<IProps> = props => (
  <td
    {...{
      ...props,
      className: [map.tableHead, props.className || ""].join(" ")
    }}
  >
    {props.children}
  </td>
);

export const TD: React.FC<IProps> = props => (
  <td
    {...{
      ...props,
      className: [map.tableData, props.className || ""].join(" ")
    }}
  >
    {props.children}
  </td>
);

const { map } = new Sheet(`
  map tableData
    borderBottom 1 solid #eaeaea 
    padding 15px

  map tableHead
    borderBottom 1 solid #eaeaea 
    fontWeight 500
    padding 15
`);
