import { Role } from "@hulanbv/afbouwkeur-packages";
import { authenticationService } from "../services/authentication.service";

export const authUtils = new (class Utils {
  /**
   * get the employer id of the logged in user
   */
  public getEmployer(): string | null {
    const token = authenticationService.getSessionToken();
    return token ? token.user!.employedById || null : null;
  }

  /**
   * get the employer of the logged in user
   */
  public satisfiesRoles(...roles: Role[]): boolean {
    const token = authenticationService.getSessionToken();
    return roles.includes(token?.user?.role!);
  }
})();
