import { IQuestionnaire } from "@hulanbv/afbouwkeur-packages";
import { ISearchOption } from "../components/controls/search";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { questionnaireService } from "../services/questionnaire.service";
import { isNil, json } from "./statics.utils";

export const questionnaireUtils = new (class Utils {
  /**
   * Returns the questionnaires as IOption array
   * @param questionnaires
   */
  public toOption(
    questionnaires: (IQuestionnaire | undefined | null)[]
  ): ISearchOption[] {
    return questionnaires
      .filter((questionnaire) => !isNil(questionnaire))
      .map((questionnaire) => ({
        key: questionnaire!._id,
        value: questionnaire!.title,
      }));
  }

  /**
   * Search for questionnaires and retrieve options
   * @param query
   */
  public async searchQuestionnaireOptions(
    query: string,
    options?: IHttpOptions
  ): Promise<ISearchOption[]> {
    return this.toOption(
      await json<IQuestionnaire[]>(
        questionnaireService.getAll({
          ...options,
          search: query,
          searchScope: ["title"],
        })
      )
    );
  }
})();
