import React from "react";
import { Sheet } from "strcss";

interface IProps {
  height?: number;
}

export const Spacer: React.FC<IProps> = props => {
  const { map } = new Sheet(`
    map spacer
      padding ${props.height ? props.height / 2 : 20}
      width 100%

  `);

  return <div className={map.spacer}>{props.children}</div>;
};
