import { IAssignment } from "@hulanbv/afbouwkeur-packages";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { IResponse } from "../interfaces/response.interface";
import { CrudService } from "./crudService.abstract.service";
import { httpService } from "./http.service";
import { authenticationService } from "./authentication.service";
import { dictionary } from "../constants/i18n/dictionary";

export const assignmentService =
  new (class Service extends CrudService<IAssignment> {
    constructor() {
      super("assignment");
    }

    /**
     * download all attachments
     */
    async downloadAttachments(assignmentId: string) {
      const response = await fetch(
        [
          process.env.REACT_APP_API_URL,
          this.controller,
          assignmentId,
          "attachments",
        ].join("/"),
        {
          method: "GET",
          headers: new Headers({
            Authorization: authenticationService.getSessionToken()?.token || "",
          }),
        }
      );

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        response.headers.get("x-filename") || `${dictionary.attachments}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }

    /**
     * Get only active assignments
     * @param options
     */
    getActive(options?: IHttpOptions): Promise<IResponse<IAssignment[]>> {
      return httpService.get([this.controller, "active"].join("/"), options);
    }

    /**
     * Get only finished assignments
     * @param options
     */
    getFinished(options?: IHttpOptions): Promise<IResponse<IAssignment[]>> {
      return httpService.get([this.controller, "finished"].join("/"), options);
    }

    /**
     * Finish an assignment
     * @param id
     */
    finishAssignment(id: string): Promise<IResponse<IAssignment>> {
      return httpService.post([this.controller, id, "finish"].join("/"), null);
    }
  })();
