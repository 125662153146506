import React, { CSSProperties } from "react";
import { Sheet } from "strcss";

interface IProps {
  style?: CSSProperties;
  sortBy: string;
  columnKey: string;
}

export const SortIndicator: React.FC<IProps> = props => {
  const isDesc = props.sortBy[0] === "-";
  const columnSorted = props.sortBy.replace("-", "") === props.columnKey;
  const className = columnSorted
    ? isDesc
      ? map.descending
      : map.ascending
    : "";

  return (
    <div
      className={[map.indicator, className].join(" ")}
      {...props.style || {}}
    />
  );
};

const { map } = new Sheet(`
    map indicator
      margin 5
      display inlineBlock       
      width 0
      height 0 
      border-left 3 solid transparent
      border-right 3 solid transparent


    map ascending
      border-bottom 3 solid black

    map descending
      border-top 3 solid black

`);
