import { IQuestionnaire } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../../constants/i18n/dictionary";
import { TD } from "../../containers/tables/tableCell";
import { TR } from "../../containers/tables/tableRow";

export const questionnaireRowHeadings = () => ({
  title: dictionary.title,
  questions: dictionary.questions,
});

interface IProps {
  questionnaire: IQuestionnaire;
  onClick?: (questionnaire: IQuestionnaire) => void;
}

export const QuestionnaireRow: React.FC<IProps> = (props) => (
  <TR
    onClick={() =>
      props.onClick ? props.onClick(props.questionnaire) : undefined
    }
  >
    <TD>{props.questionnaire.title}</TD>
    <TD>{props.questionnaire.questions.length}</TD>
  </TR>
);
