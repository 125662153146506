import { IAttachment } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../../constants/i18n/dictionary";
import routes from "../../../routes";
import { routerService } from "../../../services/router.service";
import { TD } from "../../containers/tables/tableCell";
import { TR } from "../../containers/tables/tableRow";

export const attachmentRowHeadings = () => ({
  name: dictionary.name,
  description: dictionary.description,
  filePath: dictionary.type,
});

interface IProps {
  attachment: IAttachment;
}

export const AttachmentRow: React.FC<IProps> = (props) => (
  <TR
    onClick={() => {
      if (props.attachment.assignmentId) {
        routerService.navigate(
          routes.projectAssignmentAttachmentEdit.path(
            props.attachment.assignment?.projectId || "",
            props.attachment.assignmentId,
            props.attachment._id
          )
        );
      } else if (props.attachment.projectId) {
        routerService.navigate(
          routes.projectAttachmentEdit.path(
            props.attachment.projectId,
            props.attachment._id
          )
        );
      }
    }}
  >
    <TD>{props.attachment.name}</TD>
    <TD>{props.attachment.description}</TD>
    <TD>{props.attachment.filePath.split(".").reverse()[0]}</TD>
  </TR>
);
