import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import boxIcon from "../../assets/box.svg";
import checkboxIcon from "../../assets/checkbox.svg";
import { dictionary } from "../../constants/i18n/dictionary";
import { Image } from "./image";

interface IProps {
  id?: number;
  attributes?: HTMLAttributes<HTMLDivElement>;
  inactive?: boolean;
  onToggle?: (currentlyActive: boolean, id?: string) => void;
}

export const Toggler: FC<IProps> = (props) => (
  <table
    className={[
      props.inactive ? styles.inactiveToggler : "",
      styles.table,
    ].join(" ")}
  >
    <tbody
      {...{
        ...props.attributes,
        className: [props.attributes?.className].join(" "),
      }}
    >
      <tr>
        <td>
          <div className={styles.toggler}>
            <div className={styles.toggleColumn}>
              <Image
                className={styles.toggleButton}
                alt={dictionary.on__off}
                title={dictionary.show__hide_on_print}
                src={props.inactive ? boxIcon : checkboxIcon}
                onClick={() =>
                  props.onToggle?.(!!props.inactive, props.id?.toString())
                }
              />
            </div>
            <div
              className={[
                props.inactive ? styles.inactiveContent : "",
                styles.contentColumn,
              ].join(" ")}
            >
              {props.children}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

const styles = {
  table: style({
    overflow: "visible !important",
    width: "100%",

    $nest: {
      "& tbody thead": {
        display: "none !important",
      },
    },
  }),
  toggler: style({
    display: "flex",
    width: "100%",
  }),
  inactiveToggler: style({
    $nest: {
      "@media print": {
        display: "none",
      },
    },
  }),
  inactiveContent: style({
    opacity: 0.2,
  }),
  toggleColumn: style({
    flex: 0,
    minWidth: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRight: "1px solid #eee",
    margin: "10px 0",

    $nest: {
      "@media print": {
        display: "none",
      },
    },
  }),
  contentColumn: style({
    flex: 1,
    padding: 20,

    $nest: {
      "@media print": {
        padding: 0,
      },
    },
  }),
  toggleButton: style({
    height: 20,
    cursor: "pointer",
  }),
};
