import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { H1 } from "./h1";

interface IProps {
  attributes?: HTMLAttributes<HTMLHeadElement>;
  controls?: React.ReactElement;
}

export const H2: FC<IProps> = (props) => (
  <H1
    {...props}
    attributes={{
      ...props.attributes,
      className: [props.attributes?.className, styles.h2].join(" "),
    }}
  />
);

const styles = {
  h2: style({
    fontSize: "1.143em",
  }),
};
