import React, { InputHTMLAttributes } from "react";
import { Sheet } from "strcss";
import { inputReadOnlyStyles, inputStyles } from "./input";

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {}

export const Textarea: React.FC<IProps> = props => (
  <textarea
    {...{
      type: "text",
      ...props,
      className: [
        inputStyles,
        map.textarea,
        props.readOnly ? inputReadOnlyStyles : ""
      ].join(" ")
    }}
  />
);

const { map } = new Sheet(`
  map textarea
    height 90
    resize vertical

`);
