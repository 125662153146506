import React from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { ICrudTableFilterOptions } from "../../interfaces/crudTableFilterOptions.interface";
import { formUtils } from "../../utils/form.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Select } from "../controls/select";

interface IProps {
  filterOptions: ICrudTableFilterOptions[];
  onSubmit: (data: ICrudTableFilterOptions[]) => any;
}

export const CrudTableFilterForm: React.FC<IProps> = (props) => {
  const formToFilters = (json: { [key: string]: string }) =>
    props.filterOptions.map((filter) => ({
      ...filter,
      currentValue: json[filter.key] || undefined,
    }));

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit(
          formToFilters(formUtils.getFormDataAsJSON(e.currentTarget))
        );
      }}
    >
      {props.filterOptions.map((filter, i) => (
        <FormRow key={i} label={filter.label} optional>
          {filter.options ? (
            <Select
              defaultValue={filter.currentValue}
              name={filter.key}
              options={filter.options}
            />
          ) : (
            <Input
              defaultValue={filter.currentValue}
              name={filter.key}
              type="text"
              placeholder={filter.label}
            />
          )}
        </FormRow>
      ))}

      <FormRow>
        <Button type="submit">{dictionary.apply}</Button>
      </FormRow>
    </form>
  );
};
