import { DateTime } from "luxon";
import React, { FC, InputHTMLAttributes, useEffect, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { formatDate } from "../../utils/date.utils";
import { Input } from "./input";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const DateInput: FC<IProps> = (props) => {
  const [date, setDate] = useState<string>("");
  const [formattedDate, setformattedDate] = useState<string>("");

  /**
   * Formats a string to a date string and sets it to the date state
   * @param value
   */
  const stringToDate = (value: string) => {
    const dateTime = DateTime.fromFormat(value, dictionary.date_format);
    setDate(dateTime.isValid ? dateTime.toISODate() : "");
  };

  useEffect(() => {
    // format the date if applicable
    const value = props.value || props.defaultValue;
    if (value) {
      setDate(new Date(value.toString()).toISOString());
      setformattedDate(formatDate(value.toString(), dictionary.date_format));
    }
  }, [props]);

  return (
    <>
      <input type="hidden" value={date} name={props.name} />
      <Input
        {...props}
        key={formattedDate}
        {...{
          ...props,
          placeholder: !props.readOnly
            ? dictionary.date_format_string
            : undefined,
          name: undefined,
          defaultValue: formattedDate,
          value: undefined,
          onChange: (e) => stringToDate(e.currentTarget.value),
          onBlur: (e) => {
            if (!date) {
              setformattedDate("");
              e.currentTarget.value = "";
            }
          },
        }}
      />
    </>
  );
};
