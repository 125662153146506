import { IResetPasswordRequest } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { formUtils } from "../../utils/form.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Input } from "../controls/input";

interface IProps {
  email?: string;
  onSubmit: (data: IResetPasswordRequest) => any;
}

export const ResetPasswordForm: React.FC<IProps> = (props) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      props.onSubmit(formUtils.getFormDataAsJSON(e.currentTarget));
    }}
  >
    <FormRow label="E-mail">
      <Input
        name="email"
        type="email"
        placeholder="E-mail"
        value={props.email}
        disabled
      />
    </FormRow>
    <FormRow label="Nieuw wachtwoord">
      <Input
        name="password"
        type="password"
        placeholder="Wachtwoord"
        required
      />
    </FormRow>
    <FormRow>
      <Button type="submit"> Wachtwoord aanpassen </Button>
    </FormRow>
  </form>
);
