import React, { CSSProperties } from "react";
import { style } from "typestyle";
import colors from "../../constants/colors.constants";

interface IProps {
  style?: CSSProperties;
}

export const Line: React.FC<IProps> = (props) => (
  <div className={styles.line} style={props.style || {}} />
);

const styles = {
  line: style({
    height: 4,
    background: colors.brand.gradient.horizontal,
    width: "100%",

    $nest: {
      "@media print": {
        display: "none",
      },
    },
  }),
};
