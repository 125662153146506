import { IUser } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { NavLink } from "react-router-dom";
import { Sheet } from "strcss";
import logoImg from "../../assets/afbouwkeur-logo.png";
import colors from "../../constants/colors.constants";
import routes from "../../routes";

interface IProps {
  user: IUser | null;
}

export const NavigationBar: React.FC<IProps> = (props) => {
  return (
    <nav className={map.navigation}>
      <div className={map.brand} />

      {props.user && (
        <div>
          {Object.values(routes)
            .filter(
              (route) =>
                route.label &&
                ((props.user === null && route.roles === null) ||
                  (route.roles &&
                    ((props.user && route.roles.length === 0) ||
                      (props.user && route.roles.includes(props.user.role)))))
            )
            .map((route, i) => (
              <NavLink
                key={i}
                className={map.link}
                activeClassName={map.active}
                exact={route.exact !== false ? true : false}
                to={route.path()}
              >
                {route.label}
              </NavLink>
            ))}
        </div>
      )}
    </nav>
  );
};

const { map } = new Sheet(`
  map navigation
    display flex
    alignItems center
    justifyContent space-between
    margin 0 0 60 0

  map brand
    background url('${logoImg}')
    backgroundSize contain
    backgroundPosition center center
    backgroundRepeat no-repeat
    width 125
    height 75

  map link
    margin 0 20
    color ${colors.text.tertiary}
    color ${colors.text.secondary} !hover
    transition .3s color

  map active
    color ${colors.text.primary}
    fontWeight 500
`);
