import { ICustomer } from "@hulanbv/afbouwkeur-packages";
import { ISearchOption } from "../components/controls/search";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import routes from "../routes";
import { customerService } from "../services/customer.service";
import { routerService } from "../services/router.service";
import { isNil, json } from "./statics.utils";

export const customerUtils = new (class Utils {
  /**
   * Returns the customers as IOption array
   * @param customers
   */
  public toOption(
    customers: (ICustomer | undefined | null)[]
  ): ISearchOption[] {
    return customers
      .filter((customer) => !isNil(customer))
      .map((customer) => ({
        key: customer!._id,
        value: customer!.name,
        image: customer!.imagePath,
        onClick: (option) => {
          routerService.navigate(routes.customer.path(option.key));
        },
      }));
  }

  /**
   * Search for customers and retrieve options
   * @param query
   */
  public async searchCustomerOptions(
    query: string,
    options?: IHttpOptions
  ): Promise<ISearchOption[]> {
    return this.toOption(
      await json<ICustomer[]>(
        customerService.getAll({
          ...options,
          search: query,
          searchScope: ["name"],
        })
      )
    );
  }
})();
