import { ICompany, IUser, Role } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import CrudTable from "../components/containers/tables/crudTable";
import {
  EmployeeRow,
  employeeRowHeadings,
} from "../components/data/table-rows/employeeRow";
import { ICrudTableFilterOptions } from "../interfaces/crudTableFilterOptions.interface";
import routes from "../routes";
import { companyService } from "../services/company.service";
import { routerService } from "../services/router.service";
import { userService } from "../services/user.service";
import { authUtils } from "../utils/authentication.utils";
import { companyUtils } from "../utils/company.utils";
import { json } from "../utils/statics.utils";

interface IState {
  companies: ICompany[];
}

export default class EmployeesView extends React.Component<
  RouteComponentProps,
  IState
> {
  public state: IState = {
    companies: [],
  };

  async componentDidMount() {
    this.setState({
      companies: await json<ICompany[]>(companyService.getAll()),
    });
  }

  render() {
    const filters: ICrudTableFilterOptions[] = [
      { key: "lastName", label: "Achternaam" },
    ];
    if (authUtils.satisfiesRoles(Role.ADMIN, Role.SYSTEM_ADMIN)) {
      filters.push({
        key: "employedById",
        label: "Deelnemer",
        options: [
          { key: "", value: "" },
          ...companyUtils.toOption(this.state.companies),
        ],
      });
    }

    return (
      <CrudTable<IUser>
        limit={10}
        headings={employeeRowHeadings()}
        defaultSortBy="firstName,lastName"
        rowTemplate={(user) => <EmployeeRow key={user._id} user={user} />}
        fetchMethod={(options) =>
          userService.getAll({
            ...options,
            populate: ["employedBy"],
            filter: {
              ...(options.filter || {}),
              role: "!" + Role.CONTACT,
            },
          })
        }
        onCreateClick={() =>
          routerService.navigate(routes.employee.path("new"))
        }
        filterOptions={filters}
      />
    );
  }
}
