import { IAssignment } from "@hulanbv/afbouwkeur-packages";
import { DateTime } from "luxon";
import React from "react";
import { dictionary } from "../../../constants/i18n/dictionary";
import routes from "../../../routes";
import { routerService } from "../../../services/router.service";
import { TD } from "../../containers/tables/tableCell";
import { TR } from "../../containers/tables/tableRow";

export const assignmentRowHeadings = () => ({
  name: dictionary.name,
  executorIds: dictionary.executors,
  finishedAt: dictionary.status,
});

interface IProps {
  assignment: IAssignment;
}

export const AssignmentRow: React.FC<IProps> = (props) => (
  <TR
    onClick={() =>
      routerService.navigate(
        routes.projectAssignment.path(
          props.assignment.projectId,
          props.assignment._id
        )
      )
    }
  >
    <TD>{props.assignment.name}</TD>
    <TD>
      {props.assignment.executors
        ?.map(
          (executor) =>
            `${executor.firstName[0]?.toUpperCase()}. ${executor.lastName}`
        )
        .join(", ")}
    </TD>
    <TD>
      {props.assignment?.finishedAt ? (
        <div>
          {dictionary.completed_on}{" "}
          {DateTime.fromISO(props.assignment?.finishedAt.toString()).toFormat(
            "dd-MM-yyyy"
          )}
        </div>
      ) : (
        dictionary.ongoing
      )}
    </TD>
  </TR>
);
