import {
  IAssignment,
  IAttachment,
  Severity,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { classes, style } from "typestyle";
import downloadIcon from "../assets/download.svg";
import printIcon from "../assets/print.svg";
import { Card } from "../components/containers/card";
import { FormRow } from "../components/containers/formRow";
import { Grid, GridItem } from "../components/containers/grid";
import CrudTable from "../components/containers/tables/crudTable";
import { Button } from "../components/controls/button";
import { Image } from "../components/controls/image";
import { Spacer } from "../components/data/notifications/spacer";
import { SubmissionOverview } from "../components/data/submissions/submissionOverview";
import {
  AttachmentRow,
  attachmentRowHeadings,
} from "../components/data/table-rows/attachmentRow";
import AssignmentForm from "../components/forms/assignmentForm";
import { Hr } from "../components/shapes/hr";
import routes from "../routes";
import { assignmentService } from "../services/assignment.service";
import { attachmentService } from "../services/attachment.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";
import { dictionary } from "../constants/i18n/dictionary";
import { ConfirmDialog } from "../dialogs/confirm.dialog";
import { dialog } from "../dialogs/dialog";
import { notificationListener } from "../observables/notification-listener";

interface IState {
  assignment: IAssignment | null;
}

export default class AssignmentView extends React.Component<
  RouteComponentProps,
  IState
> {
  private id: string | null = null;
  public state: IState = {
    assignment: null,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.id = (props.match.params as any).assignmentId;
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(props: RouteComponentProps) {
    this.fetchData(props);
  }

  render() {
    return (
      <Grid>
        <GridItem flex={2}>
          <Card
            head={dictionary.assignment}
            controls={
              <Image
                className={styles.iconButton}
                alt={"print"}
                title={dictionary.print_assignment}
                src={printIcon}
                onClick={() =>
                  routerService.navigate(
                    routes.projectPrint.path(
                      this.state.assignment?.projectId || "",
                      this.id || ""
                    )
                  )
                }
              />
            }
          >
            {this.state.assignment && (
              <>
                <AssignmentForm
                  onSubmit={() => {}}
                  readOnly={true}
                  assignment={this.state.assignment || undefined}
                />

                <Hr />

                <FormRow>
                  {!this.state.assignment.finishedAt ? (
                    <Button onClick={() => this.finishAssignment()}>
                      Taak afronden
                    </Button>
                  ) : (
                    <Button ghost onClick={() => this.openAssignment()}>
                      Taak heropenen
                    </Button>
                  )}
                </FormRow>
                <FormRow>
                  <Button
                    ghost
                    onClick={() =>
                      routerService.navigate(
                        routes.projectAssignmentEdit.path(
                          this.state.assignment!.projectId,
                          this.state.assignment!._id
                        )
                      )
                    }
                  >
                    Aanpassen
                  </Button>
                </FormRow>
              </>
            )}
          </Card>
        </GridItem>
        <GridItem flex={5}>
          <Card
            head={dictionary.attachments}
            controls={
              <Image
                className={classes(
                  styles.iconButton,
                  this.state.assignment?.attachments?.length === 0 &&
                    styles.disabled
                )}
                alt={"download"}
                title={dictionary.download_attachments}
                src={downloadIcon}
                onClick={() => this.openConfirmDownloadDialog()}
              />
            }
          >
            <CrudTable<IAttachment>
              limit={10}
              defaultSortBy="createdAt"
              headings={attachmentRowHeadings()}
              rowTemplate={(attachment) => (
                <AttachmentRow key={attachment._id} attachment={attachment} />
              )}
              fetchMethod={(options) => {
                options.filter = options.filter || {};
                options.filter.assignmentId = this.id || "";
                options.populate = ["assignment"];

                return attachmentService.getAll(options);
              }}
              onCreateClick={() =>
                routerService.navigate(
                  routes.projectAssignmentAttachmentEdit.path(
                    this.state.assignment?.projectId || "",
                    this.id || "",
                    "new"
                  )
                )
              }
            />
          </Card>

          <Spacer height={30}></Spacer>

          <Card head="Dossier" darkened>
            <SubmissionOverview
              onRefreshRequested={() => this.fetchData(this.props)}
              submissions={this.state.assignment?.submissions ?? []}
              questionnaireSubmissions={
                this.state.assignment?.questionnaireSubmissions ?? []
              }
            />
          </Card>
        </GridItem>
      </Grid>
    );
  }

  private fetchData(props: RouteComponentProps) {
    const { assignmentId } = (props.match as any).params;
    this.id = assignmentId;

    this.fetchAssignment();
  }

  private async fetchAssignment() {
    try {
      if (!this.id) {
        throw new Error("No id defined");
      }

      const assignment = await json<IAssignment>(
        assignmentService.get(this.id, {
          populate: [
            "submissions.comments.author",
            "submissions.signature.author",
            "submissions.author",
            "attachments",
            "questionnaireSubmissions.author",
            "executors",
          ],
        })
      );
      this.setState({ assignment });
    } catch (err) {
      routerService.navigate(routes.projects.path());
    }
  }

  private async downloadAttachments() {
    try {
      await assignmentService.downloadAttachments(this.id || "");
      notificationListener.next({
        message: dictionary.all_attachments_have_been_successfully_downloaded,
        severity: Severity.SUCCESS,
      });
      dialog.unmount();
    } catch (err) {
      notificationListener.next({
        message:
          dictionary.something_went_wrong_with_downloading_the_attachments,
        severity: Severity.ALERT,
      });
    }
  }

  private async openConfirmDownloadDialog() {
    const totalAttachments = this.state.assignment?.attachments?.length ?? 0;

    dialog.mount({
      title: dictionary.download_attachments,
      children: (
        <ConfirmDialog
          children={
            <p>
              {dictionary.download_x_attachments_to_device(totalAttachments)}
            </p>
          }
          onConfirm={() => this.downloadAttachments()}
        />
      ),
    });
  }

  private async finishAssignment() {
    if (!this.state.assignment) {
      return;
    }
    const assignment = await json<IAssignment>(
      assignmentService.finishAssignment(this.state.assignment._id)
    );
    this.setState({ assignment });
  }

  private async openAssignment() {
    if (
      !this.state.assignment ||
      !window.confirm("Weet je zeker dat je deze taak wilt heropenen?")
    ) {
      return;
    }
    const assignment = await json<IAssignment>(
      assignmentService.put({
        ...this.state.assignment,
        finishedAt: undefined,
      })
    );
    this.setState({ assignment });
  }
}

const styles = {
  iconButton: style({
    cursor: "pointer",
    transition: ".3s opacity",
    opacity: 0.54,

    $nest: {
      "&:hover": {
        opacity: 0.87,
      },
    },
    height: 20,
  }),
  disabled: style({
    pointerEvents: "none",
    opacity: 0.5,
  }),
};
