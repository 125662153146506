import React from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

interface IProps {
  attr?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

export const Hr: React.FC<IProps> = props => (
  <div className={map.hr} {...(props.attr || {})} />
);

const { map } = new Sheet(`
  map hr
    height 1
    background ${colors.shades.lightGray}
    width 100%
    margin 10 0
`);
