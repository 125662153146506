import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export const Flex: FC<IProps> = props => (
  <div {...{ ...props, className: [styles.flex, props.className].join(" ") }}>
    {props.children}
  </div>
);

const styles = {
  flex: style({
    display: "flex",
    flexWrap: "wrap",

    $nest: {
      "&>*": {
        flex: 1
      }
    }
  })
};
