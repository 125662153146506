import React from "react";
import { Singleton } from "react-singletons";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

export const Loader = new Singleton<{}>(() => (
  <>
    <div className={map.loader} />
    <div className={map.indicator} />
  </>
));

const { map } = new Sheet(`
  map loader
    position fixed
    size 100%
    left 0 
    top 0 
    background #FFF
    opacity 0.6
    animation fade-in .2s
    zIndex 999

  map indicator
    size 50
    position fixed
    left 50%
    marginLeft -25
    top 50%
    marginTop -25
    zIndex 1000
    background ${colors.brand.gradient.diagonal}
    clipPath polygon(41% 8%, 100% 18%, 59% 76%, 59% 92%, 0 60%, 0 43%)
    -webkit-clipPath polygon(41% 8%, 100% 18%, 59% 76%, 59% 92%, 0 60%, 0 43%)
    animation flicker 1.5s infinite linear
`);
