import { Subject } from "rxjs";

export const routerService = new (class Service {
  /**
   * Listener for new route changes
   */
  public onRouteChange = new Subject<string>();

  /**
   * Navigate to a new route
   * @param route
   */
  public navigate(route: string): void {
    this.onRouteChange.next(route);
  }
})();
