import { IAssignment, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import AssignmentForm from "../components/forms/assignmentForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { assignmentService } from "../services/assignment.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";

interface IState {
  assignment: IAssignment | null;
}

export default class AssignmenFormView extends React.Component<
  RouteComponentProps,
  IState
> {
  private assignmentId: string | null = null;
  private projectId: string | null = null;
  public state: IState = {
    assignment: null,
  };
  constructor(props: RouteComponentProps) {
    super(props);

    const { assignmentId, projectId } = (this.props.match as any).params;
    if (assignmentId) {
      this.assignmentId = assignmentId;
    }

    this.projectId = projectId;
  }

  componentDidMount() {
    this.fetchAssignment();
  }

  render() {
    return (
      <>
        {(!this.assignmentId || this.state.assignment) && (
          <Card
            attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}
            imageSize="cover"
          >
            <AssignmentForm
              onSubmit={(assignment) => this.submit(assignment)}
              assignment={
                this.state.assignment || { projectId: this.projectId || "" }
              }
            />
          </Card>
        )}
      </>
    );
  }

  private async submit(assignment: IAssignment) {
    try {
      const created = await json<IAssignment>(
        assignmentService.createOrPut(assignment, {
          loader: true,
        })
      );
      notificationListener.next({
        message: "De taak is succesvol opgeslagen",
        severity: Severity.SUCCESS,
      });

      if (!assignment._id) {
        routerService.navigate(routes.project.path(created.projectId));
      } else {
        routerService.navigate(
          routes.projectAssignment.path(created.projectId, created._id)
        );
      }
    } catch (err) {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.ALERT,
      });
    }
  }

  private async fetchAssignment() {
    if (!this.assignmentId) {
      return;
    }

    const assignment = await json<IAssignment>(
      assignmentService.get(this.assignmentId)
    );
    this.setState({ assignment });
  }
}
