import { ISubmissionComment } from "@hulanbv/afbouwkeur-packages";
import { CrudService } from "./crudService.abstract.service";

export const submissionCommentService = new (class Service extends CrudService<
  ISubmissionComment
> {
  constructor() {
    super("submission-comment");
  }
})();
