import React, { CSSProperties } from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

interface IProps {
  totalPages: number;
  page: number;
  onPageSelect: (page: number) => any;

  style?: CSSProperties;
}

export const Pagination: React.FC<IProps> = (props) => {
  let pages: number[] = Array(props.totalPages)
    .fill(null)
    .map((x, i) => i + 1);

  // remove indexes when the number of pages exceeds 5
  if (pages.length > 9) {
    pages = Array.from(
      new Set([
        pages[0],
        ...pages.slice(Math.max(0, props.page - 3), props.page + 2),
        pages[props.totalPages - 1],
      ])
    );
  }

  let lastPage = 1;
  return (
    <div style={props.style || {}} className={map.pagination}>
      {pages.map((i) => {
        // add a filler if the gap is more than 1
        const filler = i - lastPage > 1;
        lastPage = i;

        return (
          <div key={i} className={map.itemWrapper}>
            {filler && (
              <div className={[map.item, map.filler].join(" ")}>...</div>
            )}
            <div
              onClick={() => props.onPageSelect(i)}
              className={[map.item, props.page === i ? map.active : ""].join(
                " "
              )}
            >
              {i}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const { map } = new Sheet(`
  map pagination 
    border 1 solid #eaeaea
    borderLeft 0
    borderRadius 5
    display inlineFlex
    overflow hidden
    margin 0 5px 

  map itemWrapper
    display inlineFlex

  map item
    size 34
    display flex
    cursor pointer
    alignItems center
    justifyContent spaceAround
    borderLeft 1 solid #eaeaea
    background #fafafa !hover

  map active
    background ${colors.brand.gradient.diagonal}
    background ${colors.brand.gradient.diagonal} !hover
    color ${colors.textLight.primary}
    cursor default

  map filler
    background transparent !hover
    cursor default

`);
