import { IUser, Role } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { Sheet } from "strcss";
import { dictionary } from "../../../constants/i18n/dictionary";
import routes from "../../../routes";
import { routerService } from "../../../services/router.service";
import { authUtils } from "../../../utils/authentication.utils";
import { TD } from "../../containers/tables/tableCell";
import { TR } from "../../containers/tables/tableRow";
import { Circle } from "../../shapes/circle";

export const contactRowHeadings = () => {
  const headings: Record<string, string> = {
    imagePath: "",
    "firstName,lastName": dictionary.name,
    jobTitle: dictionary.job_title,
    phoneNumber: dictionary.phone_number,
    email: dictionary.email,
  };
  if (authUtils.satisfiesRoles(Role.SYSTEM_ADMIN, Role.ADMIN)) {
    headings["employedBy.name"] = dictionary.participant;
  }

  return headings;
};

interface IProps {
  user: IUser;
}

export const ContactRow: React.FC<IProps> = (props) => (
  <TR
    className={map.row}
    onClick={() => routerService.navigate(routes.contact.path(props.user._id))}
  >
    <TD style={{ width: 50 }}>
      <Circle
        size={35}
        background={
          props.user.imagePath
            ? `url(${props.user.imagePath}) center center/cover no-repeat`
            : "rgba(0, 0, 0, 0.05)"
        }
      />
    </TD>
    <TD>{props.user.fullname}</TD>
    <TD>{props.user.jobTitle || ""}</TD>
    <TD>{props.user.phoneNumber || ""}</TD>
    <TD>{props.user.email}</TD>

    {authUtils.satisfiesRoles(Role.SYSTEM_ADMIN, Role.ADMIN) && (
      <TD>{props.user.employedBy?.name}</TD>
    )}
  </TR>
);

const { map } = new Sheet(`
    map row
        height auto
`);
