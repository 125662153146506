import { ICredentials, ISessionToken } from "@hulanbv/afbouwkeur-packages";
import { Subject } from "rxjs";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { IResponse } from "../interfaces/response.interface";
import routes from "../routes";
import { httpService } from "./http.service";
import { routerService } from "./router.service";

export const authenticationService = new (class Service {
  protected readonly controller = "authentication";
  protected readonly session_key = "session_token";

  /**
   * Listener for new session changes
   */
  public onSessionChange = new Subject<ISessionToken | null>();

  /**
   * Log in to the application
   * @param credentials
   */
  public async authenticate(
    credentials: ICredentials,
    options?: IHttpOptions
  ): Promise<IResponse<ISessionToken>> {
    const response = (
      await httpService.post(this.controller, credentials, options)
    ).clone();
    const sessionToken = await response.json();
    this.setSessionToken(sessionToken);

    return response;
  }

  /**
   * Validate your active session token
   */
  public async validate(
    options?: IHttpOptions
  ): Promise<IResponse<ISessionToken>> {
    return httpService.post(
      [this.controller, "validate"].join("/"),
      null,
      options
    );
  }

  /**
   * Log out of the application
   */
  public async logout(options?: IHttpOptions): Promise<void> {
    httpService.post([this.controller, "logout"].join("/"), null, options);

    this.clearSessionToken();
    routerService.navigate(routes.home.path());
  }

  /**
   * Save the session token
   * @param sessionToken
   */
  public setSessionToken(sessionToken: ISessionToken) {
    localStorage.setItem(this.session_key, JSON.stringify(sessionToken));
    this.onSessionChange.next(sessionToken);
  }

  /**
   * Retrieve the stores session token
   */
  public getSessionToken(): ISessionToken | null {
    const sessionToken = localStorage.getItem(this.session_key);
    return sessionToken ? JSON.parse(sessionToken) : null;
  }

  /**
   * Clear the stored session token
   */
  public clearSessionToken() {
    localStorage.removeItem(this.session_key);
    this.onSessionChange.next(null);
  }
})();
