import {
  ICompany,
  ICustomer,
  IProject,
  Role,
  Severity,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { notificationListener } from "../../observables/notification-listener";
import routes from "../../routes";
import { companyService } from "../../services/company.service";
import { customerService } from "../../services/customer.service";
import { projectService } from "../../services/project.service";
import { routerService } from "../../services/router.service";
import { authUtils } from "../../utils/authentication.utils";
import { companyUtils } from "../../utils/company.utils";
import { customerUtils } from "../../utils/customer.utils";
import { formUtils } from "../../utils/form.utils";
import { json } from "../../utils/statics.utils";
import { userUtils } from "../../utils/user.utils";
import { IOption } from "../containers/dropdown";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { inputStyles } from "../controls/input";
import { InputGroup } from "../controls/inputGroup";
import Search from "../controls/search";
import { Textarea } from "../controls/textarea";
import { SecurityQuestions } from "./components/securityQuestions";

interface IState {
  project: IProject;
}

interface IProps {
  readOnly?: boolean;
  project?: IProject;
  onSubmit: (formData: FormData) => any;
  onImageChange?: (image: string) => any;
}

export default class ProjectForm extends React.Component<IProps, IState> {
  private formRef = React.createRef<HTMLFormElement>();
  private file: File | null = null;
  public state: IState = {
    project: {
      _id: null,
      external_identifier: undefined,
      name: "",
      imagePath: undefined,
      description: "",
      contactId: "",
      companyId: "",
      customerId: undefined,
      securityQuestions: [""],
      location: { address: "", city: "", zipCode: "", coordinates: [0, 0] },
    },
  };

  componentDidMount() {
    const project = {
      ...this.state.project,
      ...this.props.project,
    };
    const employerId = authUtils.getEmployer();
    if (
      !project.company &&
      !authUtils.satisfiesRoles(Role.ADMIN, Role.SYSTEM_ADMIN) &&
      employerId
    ) {
      this.companySelected([{ value: employerId, key: employerId }]);
    }

    this.setState({ project });
  }

  render() {
    return (
      <form
        key={this.state.project ? this.state.project._id : ""}
        ref={this.formRef}
        onSubmit={(e) => {
          e.preventDefault();
          this.submit();
        }}
      >
        <InputGroup
          readOnly={this.props.readOnly}
          inputs={[
            {
              name: "_id",
              type: "hidden",
              defaultValue: this.state.project._id || undefined,
              required: false,
            },
            {
              name: "_imagePath",
              label: "Afbeelding",
              type: this.props.readOnly ? "hidden" : "file",
              required: false,
              onChange: async (e) => {
                this.file = await formUtils.processFileInput(
                  e,
                  this.props.onImageChange
                );
              },
            },
            {
              name: "external_identifier",
              label: "Extern projectnummer",
              type: "text",
              placeholder: "Extern projectnummer",
              defaultValue: this.state.project.external_identifier,
              required: false,
            },
            {
              name: "name",
              label: "Projectnaam",
              type: "text",
              placeholder: "Projectnaam",
              defaultValue: this.state.project.name,
            },
          ]}
        />

        <FormRow label="Projectomschrijving" optional>
          <Textarea
            readOnly={this.props.readOnly}
            className={inputStyles}
            placeholder="Projectomschrijving"
            name="description"
            defaultValue={this.state.project.description}
          />
        </FormRow>

        <FormRow label="Extra opmerkingen" optional>
          <Textarea
            readOnly={this.props.readOnly}
            placeholder="Extra opmerkingen"
            className={inputStyles}
            name="remarks"
            defaultValue={this.state.project.remarks}
          />
        </FormRow>

        {authUtils.satisfiesRoles(Role.ADMIN, Role.SYSTEM_ADMIN) ? (
          <FormRow label="Deelnemer" optional={this.props.readOnly}>
            <Search
              readOnly={this.props.readOnly}
              required
              name="companyId"
              searchMethod={(query) => companyUtils.searchCompanyOptions(query)}
              onChange={(options) => this.companySelected(options)}
              defaultValue={companyUtils.toOption([this.state.project.company])}
              placeholder="Vind een deelnemer"
            />
          </FormRow>
        ) : (
          <input
            type="hidden"
            name="companyId"
            value={this.state.project.companyId}
          />
        )}

        {this.state.project.companyId && (
          <React.Fragment key={this.state.project.companyId}>
            <FormRow label="Opdrachtgever">
              <Search
                readOnly={this.props.readOnly}
                name="customerId"
                searchMethod={(query) =>
                  customerUtils.searchCustomerOptions(query, {
                    filter: { companyId: this.state.project.companyId! },
                    limit: 5,
                  })
                }
                onChange={(options) => this.customerSelected(options)}
                defaultValue={customerUtils.toOption([
                  this.state.project.customer,
                ])}
                placeholder="Vind een opdrachtgever"
              />
            </FormRow>

            {this.state.project.customerId && (
              <>
                <FormRow label="Contact" optional>
                  <Search
                    key={this.state.project.customerId}
                    readOnly={
                      this.props.readOnly || !this.state.project.customerId
                    }
                    name="contactId"
                    searchMethod={async (search) =>
                      userUtils.toOption(
                        this.state.project.customer?.contacts
                          ?.filter((user) =>
                            Object.values(user)
                              .join(" ")
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                          .slice(0, 5) || []
                      )
                    }
                    defaultValue={userUtils.toOption([
                      this.state.project.contact,
                    ])}
                    placeholder="Vind een contact"
                  />
                </FormRow>

                <InputGroup
                  readOnly={this.props.readOnly}
                  inputs={[
                    {
                      name: "location.address",
                      label: "Adres",
                      type: "text",
                      placeholder: "Adres",
                      defaultValue: this.state.project.location.address,
                    },
                    [
                      {
                        name: "location.zipCode",
                        label: "Postcode",
                        type: "text",
                        placeholder: "Postcode",
                        defaultValue: this.state.project.location.zipCode,
                        required: false,
                      },
                      {
                        name: "location.city",
                        label: "Stad",
                        type: "text",
                        placeholder: "Stad",
                        defaultValue: this.state.project.location.city,
                      },
                    ],
                  ]}
                />

                <FormRow label="LMRA vragen" optional={this.props.readOnly}>
                  <SecurityQuestions
                    readOnly={this.props.readOnly}
                    questions={this.state.project.securityQuestions}
                    onChange={(securityQuestions) =>
                      this.setState({
                        project: { ...this.state.project, securityQuestions },
                      })
                    }
                  />
                </FormRow>
              </>
            )}
          </React.Fragment>
        )}

        {!this.props.readOnly && (
          <>
            <FormRow>
              <Button type="submit">
                {this.state.project._id ? "Aanpassen" : "Aanmaken"}
              </Button>
            </FormRow>

            {this.state.project._id && (
              <FormRow>
                <Button ghost onClick={() => this.delete()}>
                  Verwijderen
                </Button>
              </FormRow>
            )}
          </>
        )}
      </form>
    );
  }

  private async companySelected(options: IOption[]) {
    const company = options[0]?.key
      ? await json<ICompany>(
          companyService.get(options[0].key, { populate: ["contacts"] })
        )
      : undefined;

    this.setState({
      project: {
        ...this.state.project,
        customerId: undefined,
        customer: undefined,
        contactId: undefined,
        contact: undefined,
        company,
        companyId: company?._id,
        securityQuestions: company?.securityQuestions || [],
      },
    });
  }

  private async customerSelected(options: IOption[]) {
    const customer = options[0]?.key
      ? await json<ICustomer>(customerService.get(options[0].key))
      : undefined;

    this.setState({
      project: {
        ...this.state.project,
        location: customer?.location ?? {},
        customerId: customer?._id,
        customer,
        contactId: undefined,
        contact: undefined,
      },
    });
  }

  /**
   * Delete a project
   */
  private async delete() {
    const id = this.state.project._id;
    if (!id) {
      return;
    }

    if (window.confirm("Weet u zeker dat u dit contact wilt verwijderen?")) {
      try {
        await projectService.delete(id, { loader: true });
        routerService.navigate(routes.contacts.path());

        notificationListener.next({
          message: "Het contact is succesvol verwijderd",
          severity: Severity.SUCCESS,
        });
      } catch (err) {
        notificationListener.next({
          message: "Er ging iets mis",
          severity: Severity.ALERT,
        });
      }
    }
  }

  /**
   * Store the form data in the project attribute
   */
  private storeFormData(callback?: () => any) {
    let project = this.state.project;
    if (this.formRef.current) {
      project = formUtils.getFormDataAsJSON<IProject>(this.formRef.current);
    }
    this.setState({ project }, callback || (() => {}));
  }

  /**
   * submit the form
   */
  private submit() {
    this.storeFormData(() => {
      const formData = formUtils.jsonToFormData(this.state.project);
      if (this.file) {
        formData.append("file", this.file);
      }

      this.props.onSubmit(formData);
    });
  }
}
