import React, { useRef } from "react";
import { style } from "typestyle";
import { dialog } from "../../dialogs/dialog";

export interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  carousel?: boolean;
}

export const Image: React.FC<IProps> = (props) => {
  const ref = useRef<HTMLImageElement>(null);
  const openCarousel = () => {
    console.log(ref.current?.clientWidth);
    dialog.mount({
      width: (ref.current?.naturalWidth ?? 0) + 90,
      children: <Image {...{ ...props, carousel: false }} alt="" />,
    });
  };

  return (
    <img
      ref={ref}
      alt={""}
      {...{ ...props, carousel: undefined }}
      onClick={props.carousel ? () => openCarousel() : props.onClick}
      className={[props.className, props.carousel ? styles.clickable : ""].join(
        " "
      )}
    />
  );
};

const styles = {
  clickable: style({
    cursor: "pointer",
    transform: "scale(1)",
    transition: ".3s transform",

    $nest: {
      "&:hover": {
        transform: "scale(1.01)",
      },
    },
  }),
};
