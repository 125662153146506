import { IQuestionnaire } from "@hulanbv/afbouwkeur-packages";
import React, { FormEvent } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { formUtils } from "../../utils/form.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Questions } from "./components/questions";

interface IProps {
  onSubmit: (data: FormData) => any;
  questionnaire?: Partial<IQuestionnaire>;
}

export const QuestionnaireForm: React.FC<IProps> = (props) => {
  const submit = (e: FormEvent) => {
    e.preventDefault();
    const formdata = formUtils.getFormData(e.currentTarget as any);
    props.onSubmit(formdata);
  };

  return (
    <form onSubmit={submit}>
      {props.questionnaire && (
        <input type="hidden" name="_id" value={props.questionnaire._id} />
      )}

      <FormRow label={dictionary.title} optional>
        <Input
          name="title"
          placeholder={dictionary.title}
          defaultValue={props.questionnaire?.title}
        />
      </FormRow>

      <FormRow label={dictionary.questions} optional>
        <Questions
          inputName="questions"
          questions={props.questionnaire?.questions ?? []}
        />
      </FormRow>

      <FormRow>
        <Button type="submit">{dictionary.save}</Button>
      </FormRow>
    </form>
  );
};
