import React, { CSSProperties } from "react";
import { Sheet } from "strcss";
import xIcon from "../../assets/x.svg";
import { globalStyles } from "../../styles";
import { Image } from "../controls/image";
import { Circle } from "../shapes/circle";
import { IOption } from "./dropdown";
import { Pill } from "./pill";

interface IProps {
  option: IOption;
  style?: CSSProperties;
  onDelete?: (option: IOption) => any;
  onClick?: (option: IOption) => void;
}

export const OptionPill: React.FC<IProps> = (props) => (
  <Pill style={props.style || {}}>
    <Circle
      size={15}
      background={
        props.option.image
          ? `url(${props.option.image}) center center/cover no-repeat`
          : "rgba(0, 0, 0, 0.05)"
      }
    />
    <div
      onClick={
        props.onClick
          ? () => {
              props.onClick!(props.option);
            }
          : undefined
      }
      className={[
        map.value,
        globalStyles.ellipsis,
        props.onClick ? map.clickable : "",
      ].join(" ")}
    >
      {props.option.value}
    </div>

    {props.onDelete && (
      <Image
        className={map.remove}
        src={xIcon}
        height={15}
        alt={"x"}
        onClick={() => props.onDelete && props.onDelete(props.option)}
      />
    )}
  </Pill>
);

const { map } = new Sheet(`

  map value 
    margin 0 10
    flex 1

  map remove
    cursor pointer
    opacity 0.6
    opacity 1 !hover
    transition .3s opacity
    
  map clickable 
    opacity 0.9
    cursor pointer
    opacity 1 !hover
`);
