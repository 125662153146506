import { Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { ResetPasswordRequestForm } from "../components/forms/resetPasswordRequestForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { userService } from "../services/user.service";

export default class ResetPasswordRequestView extends React.Component<
  RouteComponentProps
> {
  componentDidMount() {}

  render() {
    return (
      <Card
        head="Wachtwoord vergeten"
        attr={{ style: { maxWidth: "400px", margin: "0 auto" } }}
      >
        <ResetPasswordRequestForm onSubmit={(email) => this.submit(email)} />
        <Button
          ghost
          onClick={() => this.props.history.push(routes.login.path())}
        >
          Inloggen
        </Button>
      </Card>
    );
  }

  /**
   * Log in to the application
   * @param credentials
   */
  private async submit(email: string) {
    try {
      await userService.requestPasswordReset(email, {
        loader: true,
        useTimeout: true,
      });
    } catch {}
    notificationListener.next({
      message: "Er is een mail verstuurd naar het bijbehorende account",
      severity: Severity.SUCCESS,
    });
  }
}
