import React, { CSSProperties } from "react";
import { style } from "typestyle";

interface IProps {
  style?: CSSProperties;
}

export const Container: React.FC<IProps> = (props) => (
  <div className={styles.container} style={props.style || {}}>
    {props.children}
  </div>
);

const styles = {
  container: style({
    maxWidth: 1400,
    margin: "50px auto",
    padding: "0 20px",
    position: "relative",

    $nest: {
      "@media print": {
        margin: 0,
      },
    },
  }),
};
