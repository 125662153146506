import React, { CSSProperties } from "react";
import { Sheet } from "strcss";
import { classes } from "typestyle";

interface IProps {
  style?: CSSProperties;
  size?: "small" | "normal";
}

export const Pill: React.FC<IProps> = (props) => (
  <div
    className={classes(map.pill, map[props.size ?? "normal"])}
    style={props.style || {}}
  >
    {props.children}
  </div>
);

const { map } = new Sheet(`
  map pill
    borderRadius 5
    border 1 solid #eaeaea 
    padding 8
    display inlineFlex
    alignItems center
    background #eee
    margin 5
    cursor default

  map small
    fontSize 0.8em
    padding 5

  map normal
    fontSize 1em

`);
