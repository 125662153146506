import { Risk } from "@hulanbv/afbouwkeur-packages";

const colors = {
  brand: {
    primary: "#ff0014",
    secondary: "#a00a6d",
    tertiary: "",
    gradient: {
      horizontal: "",
      diagonal: "",
    },
  },
  shades: {
    lightGray: "#eaeaea",
  },
  text: {
    primary: "#000000DD",
    secondary: "#000000A9",
    tertiary: "#00000066",
  },
  textLight: {
    primary: "#FFFFFFDD",
    secondary: "#FFFFFFA9",
    tertiary: "#FFFFFF66",
  },

  severity: {
    success: "#2ecc71",
    warning: "#e67e22",
    alert: "#e74c3c",
    info: "#3498db",
  },

  risk: {
    [Risk.HIGH]: "#EB0000",
    [Risk.MEDIUM]: "#FF8200",
    [Risk.LOW]: "#79B436",
  },
};

colors.brand.gradient.horizontal = `linear-gradient(90deg, ${colors.brand.primary}, ${colors.brand.secondary})`;

colors.brand.gradient.diagonal = `linear-gradient(135deg, ${colors.brand.primary}, ${colors.brand.secondary})`;

export default colors;
