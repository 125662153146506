import { INewsItem } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { formUtils } from "../../utils/form.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Textarea } from "../controls/textarea";
import { Checkbox } from "../controls/checkbox.element";

interface IProps {
  model?: INewsItem;
  onSubmit: (data: INewsItem) => any;
}

export const NewsItemForm: React.FC<IProps> = (props) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      props.onSubmit(formUtils.getFormDataAsJSON(e.currentTarget));
    }}
  >
    {props.model?._id && (
      <>
        <input type="hidden" name="id" value={props.model._id} />
        <input type="hidden" name="_id" value={props.model._id} />
      </>
    )}

    <FormRow
      style={{
        border: "1px solid #9ec5fe",
        borderRadius: 3,
        background: "#cfe2ff",
        color: "#052c65",
        fontSize: ".9em",
        padding: 10,
      }}
    >
      Omdat je dit bericht als administrator aanmaakt zal het voor iedereen
      zichtbaar zijn.
    </FormRow>
    <FormRow label={dictionary.title}>
      <Input
        defaultValue={props.model?.title}
        name="title"
        type="text"
        placeholder={dictionary.title}
        required
      />
    </FormRow>
    <FormRow label={dictionary.content}>
      <Textarea
        defaultValue={props.model?.content}
        name="content"
        placeholder={dictionary.content}
        required
        style={{ minHeight: 250 }}
      />
    </FormRow>

    <FormRow label={dictionary.creation_date}>
      <Input
        defaultValue={new Date(props.model?.createdAt ?? Date.now())
          .toISOString()
          .substring(0, 10)}
        name="createdAt"
        type="date"
        placeholder={dictionary.creation_date}
        required
      />
    </FormRow>
    <FormRow>
      <Checkbox name="isPinned" defaultValue={props.model?.isPinned ?? false}>
        Is uitgelicht
      </Checkbox>
    </FormRow>

    <FormRow>
      <Button type="submit">{dictionary.save}</Button>
    </FormRow>
  </form>
);
