import { IUser } from "@hulanbv/afbouwkeur-packages";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { IResponse } from "../interfaces/response.interface";
import { CrudService } from "./crudService.abstract.service";
import { httpService } from "./http.service";

export const userService = new (class Service extends CrudService<IUser> {
  constructor() {
    super("user");
  }

  /**
   * Request a password reset for the provided email
   * @param email
   */
  public async requestPasswordReset(
    email: string,
    options?: IHttpOptions
  ): Promise<IResponse<void>> {
    return httpService.post(
      [this.controller, "reset-password", encodeURIComponent(email)].join("/"),
      null,
      options
    );
  }

  /**
   * reset a password
   * @param email
   */
  public async resetPassword(
    email: string,
    token: string,
    password: string,
    options?: IHttpOptions
  ): Promise<IResponse<IUser>> {
    return httpService.post(
      [
        this.controller,
        "reset-password",
        encodeURIComponent(email),
        encodeURIComponent(token),
      ].join("/"),
      { password },
      options
    );
  }

  /**
   * reset a users password
   * @param email
   */
  public async resetPasswordForUser(
    userId: string,
    options?: IHttpOptions
  ): Promise<IResponse<IUser>> {
    return httpService.post(
      [this.controller, userId, "reset-password"].join("/"),
      null,
      options
    );
  }
})();
