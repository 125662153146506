import { ICompany, ICustomer, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import CustomerForm from "../components/forms/customerForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { companyService } from "../services/company.service";
import { customerService } from "../services/customer.service";
import { routerService } from "../services/router.service";
import { authUtils } from "../utils/authentication.utils";
import { json } from "../utils/statics.utils";

interface IState {
  customer: ICustomer | null;
  image?: string;
}

export default class CustomerFormView extends React.Component<
  RouteComponentProps,
  IState
> {
  private id: string | null = null;
  public state: IState = {
    customer: null,
    image: undefined,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    const id = (this.props.match as any).params.id;
    if (id && id !== "new") {
      this.id = id;
    }
  }

  componentDidMount() {
    this.fetchCustomer();
  }

  render() {
    return (
      <>
        {this.state.customer && (
          <Card
            attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}
            images={this.state.image ? [this.state.image] : undefined}
            imageSize="contain"
          >
            <CustomerForm
              onImageChange={(image) => this.setState({ image })}
              onSubmit={(customer) => this.submit(customer)}
              customer={this.state.customer || undefined}
            />
          </Card>
        )}
      </>
    );
  }

  private async submit(customer: FormData) {
    try {
      await customerService.createOrPatch(customer as any, { loader: true });
      notificationListener.next({
        message: "De opdrachtgever is succesvol opgeslagen",
        severity: Severity.SUCCESS,
      });

      routerService.navigate(routes.customers.path());
    } catch (err) {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.ALERT,
      });
    }
  }

  private async fetchCustomer() {
    if (!this.id) {
      const companyId = authUtils.getEmployer();
      const company = companyId
        ? await json<ICompany>(companyService.get(companyId))
        : null;

      this.setState({
        customer: {
          companyId,
          company,
        } as ICustomer,
      });

      return;
    }

    const customer = await json<ICustomer>(customerService.get(this.id));
    this.setState({ customer, image: customer.imagePath || undefined });
  }
}
