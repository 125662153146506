import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { breadcrumbListener } from "../../observables/breadcrumb-listener";

export const Breadcrumbs: FC = () => {
  const [path, setPath] = useState<(JSX.Element | string)[]>([]);

  useEffect(() => {
    const listener = breadcrumbListener.subscribe(setPath);
    return () => listener.unsubscribe();
  }, []);

  return (
    <div className={styles.container}>
      {path.slice(0, path.length - 1).map((location, i) => (
        <div className={styles.item} key={i}>
          {location}
          <span className={styles.separator}>/</span>
        </div>
      ))}
      <div className={[styles.item, styles.active].join(" ")}>
        {path[path.length - 1]}
      </div>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    margin: "0 0 50px 0"
  }),
  separator: style({
    margin: "0 0 0 8px",
    content: "'-'"
  }),
  item: style({
    margin: "0 8px 0 0",
    opacity: 0.7,
    $nest: {
      a: {
        color: "rgba(0,0,0,.87)",
        textDecoration: "none"
      },
      "&:hover": {
        transition: ".2s opacity",
        opacity: 0.9
      }
    }
  }),
  active: style({
    opacity: 1,
    fontWeight: 500
  })
};
