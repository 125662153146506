import {
  CustomerType,
  ReportType,
  Risk,
  Role,
} from "@hulanbv/afbouwkeur-packages";

export const roleValues = {
  [Role.SYSTEM_ADMIN]: "Systeem administrator",
  [Role.ADMIN]: "Administrator",
  [Role.MANAGER]: "Beheerder",
  [Role.EXECUTOR]: "Medewerker",
  [Role.SELF_EMPLOYED]: "Onderaannemer",
  [Role.CONTACT]: "Opdrachtgever/contact",
};

export const riskValues = {
  [Risk.HIGH]: "Hoog",
  [Risk.MEDIUM]: "Redelijk",
  [Risk.LOW]: "Laag",
};

export const customerTypeValues: { [key: string]: string } = {
  [CustomerType.PRIVATE]: "Particulier",
  [CustomerType.BUSINESS]: "Zakelijk",
};

export const reportTypeValues: { [key: string]: string } = {
  [ReportType.NON_SPECIFIC]: "Beide logo's",
  [ReportType.AFBOUWKEUR]: "Afbouwkeur-logo",
  [ReportType.NOA]: "NOA-logo",
};
