/* eslint-disable react-hooks/exhaustive-deps */
import { ISecurityQuestion, Severity } from "@hulanbv/afbouwkeur-packages";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { SecurityQuestions } from "../components/forms/components/securityQuestions";
import { Hr } from "../components/shapes/hr";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { routerService } from "../services/router.service";
import { securityQuestionService } from "../services/securityQuestion.service";
import { json } from "../utils/statics.utils";

export const SecurityQuestionsView: React.FC<RouteComponentProps> = (props) => {
  const [models, setModels] = useState<ISecurityQuestion[] | null>(null);
  const [questions, setQuestions] = useState<string[]>([]);

  const submit = async () => {
    // save the new questions
    for (let i = 0, l = questions.length; i < l; i++) {
      await securityQuestionService.create({
        _id: undefined,
        text: questions[i],
        order: i,
      });
    }

    // delete the old questions
    for (const model of models ?? []) {
      await securityQuestionService.delete(model._id);
    }

    notificationListener.next({
      message: "De vragen zijn succesvol opgeslagen",
      severity: Severity.SUCCESS,
    });
    routerService.navigate(routes.companies.path());
  };

  useEffect(() => {
    const fetchModels = async () => {
      const models = await json<ISecurityQuestion[]>(
        securityQuestionService.getAll({ sort: ["order"] })
      );
      setModels(models);
      setQuestions(models.map(({ text }) => text));
    };
    fetchModels();
  }, []);

  if (!models) {
    return <></>;
  }
  return (
    <Card attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}>
      <SecurityQuestions questions={questions} onChange={setQuestions} />
      <Hr attr={{ style: { margin: "20px 0" } }} />
      <Button disabled={!questions.length} type="submit" onClick={submit}>
        Opslaan
      </Button>
    </Card>
  );
};
