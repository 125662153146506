import { IProject } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { style } from "typestyle";
import CrudTable from "../components/containers/tables/crudTable";
import { Button } from "../components/controls/button";
import {
  ProjectRow,
  projectRowHeadings,
} from "../components/data/table-rows/projectRow";
import { dictionary } from "../constants/i18n/dictionary";
import routes from "../routes";
import { projectService } from "../services/project.service";
import { routerService } from "../services/router.service";

interface IState {
  showActive: boolean;
}

export default class ProjectsView extends React.Component<
  RouteComponentProps,
  IState
> {
  public state: IState = {
    showActive: true,
  };

  render() {
    return (
      <div>
        <div className={styles.controlBar}>
          <Button
            bland
            small
            onClick={() =>
              this.setState({ showActive: !this.state.showActive })
            }
          >
            {this.state.showActive
              ? dictionary.show_finished
              : dictionary.show_active}
          </Button>
        </div>
        <CrudTable<IProject>
          key={this.state.showActive.toString()}
          limit={10}
          defaultSortBy="-name"
          headings={projectRowHeadings()}
          rowTemplate={(project) => (
            <ProjectRow key={project._id} project={project} />
          )}
          fetchMethod={(options) => {
            if (this.state.showActive) {
              return projectService.getActive({
                populate: [],
                searchScope: Object.keys(projectRowHeadings()),
                ...options,
              });
            }
            return projectService.getFinished({
              populate: [],
              searchScope: Object.keys(projectRowHeadings()),
              ...options,
            });
          }}
          onCreateClick={() =>
            routerService.navigate(routes.projectCreate.path())
          }
        />
      </div>
    );
  }
}

const styles = {
  controlBar: style({
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 30,

    $nest: {
      "& > *": {
        flex: 0,
        whiteSpace: "nowrap",
      },
    },
  }),
};
