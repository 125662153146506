import { en } from "./en";

export const nl: typeof en = {
  active_assignments: "Actieve taken",
  add_question: "Vraag toevoegen",
  all_attachments_have_been_successfully_downloaded:
    "Alle bijlages zijn succesvol gedownload",
  attachment: "Bijlage",
  attachments: "Bijlages",
  apply: "Toepassen",
  cancel: "Annuleren",
  confirm: "Bevestigen",
  completed_on: "Afgerond op",
  creation_date: "Aanmaakdatum",
  customerNumber: "Klantnummer",
  date_format: "dd-LL-y",
  date_format_string: "dd-mm-jjjj",
  defaults_to_manager: "Gebruikt standaard dat van de beheerder",
  description: "Omschrijving",
  distributed_by: "Uitgegeven door",
  download_attachments: "Download bijlages",
  email: "E-mail",
  employer: "Opdrachtgever",
  executors: "Medewerkers",
  filters_active: "Filter(s) actief",
  finished_assignments: "Afgeronde taken",
  job_title: "Functieomschrijving",
  location: "Locatie",
  manager: "Manager",
  name: "Naam",
  new: "Nieuw",
  news: "Nieuws",
  no_submissions_found_for_this_assignment:
    "Geen inzendingen voor deze taak gevonden",
  on__off: "Aan/uit",
  ongoing: "Lopend",
  participant: "Deelnemer",
  phone_number: "Telefoonnummer",
  place: "Plaats",
  print: "Print",
  print_project: "Print project",
  print_assignment: "Print taak",
  project: "Project",
  project_number: "Projectnummer",
  risk: "Risico",
  show_active: "Toon actieve",
  show_finished: "Toon afgeronde",
  show__hide_on_print: "Toon/verberg op print",
  status: "Status",
  totalAttachments: "Totaal bijlages",
  type: "Type",
  assignment_name: "Taaknaam",
  assignment: "Taak",
  download_x_attachments_to_device: (total: number) =>
    `Weet je zeker dat je ${total} ${
      total === 1 ? "bijlage" : "bijlages"
    } naar je apparaat wilt downloaden?`,
  start_date: "Startdatum",
  executing_employees: "Uitvoerende medewerkers",
  find_employees: "Vind medewerkers",
  assignment_description: "Taakomschrijving",
  lmra_questions: "LMRA vragen",
  update: "Aanpassen",
  create: "Aanmaken",
  delete: "Verwijderen",
  are_you_sure_you_want_to_delete_x: (x: string) =>
    `Weet je zeker dat je "${x}" wilt verwijderen?`,
  the_x_has_been_successfully_removed: (x: string) =>
    `${x} Is succesvol verwijderd`,
  contact: "Contact",
  something_went_wrong: "Er is iets mis gegaan",
  something_went_wrong_with_downloading_the_attachments:
    "Er is iets misgegaan met het downloaden van de bijlages",
  file: "Bestand",
  file_description: "Bestandsomschrijving",
  specifically_authorized_employees: "Specifiek bevoegde medewerkers",
  save: "Opslaan",
  image: "Afbeelding",
  participant_name: "Deelnemer naam",
  address: "Adres",
  city: "Stad",
  zip_code: "Postcode",
  find_a_manager: "Vind een beheerder",
  default_lmra_questions: "Standaard LMRA vragen",
  company: "Bedrijf",
  password: "Wachtwoord",
  login: "Inloggen",
  title: "Titel",
  content: "Inhoud",
  questions: "Vragen",
  questionnaire: "Vragenlijst",
  questionnaires: "Vragenlijsten",
  public_questionnaires: "Publieke vragenlijsten",
  find_questionnaire: "Vind vragenlijst",
  questionnaire_templates: "Vragenlijst sjablonen",
  show_questionnaires: "Toon vragenlijsten",
  show_templates: "Toon sjablonen",
};
