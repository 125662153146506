import React, { useEffect, useRef, useState } from "react";
import { Image } from "../controls/image";
import { style } from "typestyle";

export interface IProps {
  images?: string[];
  imageSize?: "cover" | "contain";
  showNumbers?: boolean;
}

export const ImageCarousel: React.FC<IProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target as HTMLImageElement;

          // Update the current image index
          setCurrentIndex((props.images?.indexOf(image.src) ?? 0) + 1);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      // Trigger when 50% of the image is visible
      threshold: 0.5,
    });

    const container = containerRef.current;
    if (container) {
      const images = container.querySelectorAll("img");
      images.forEach((image) => {
        observer.observe(image);
      });
    }

    return () => {
      // Clean up the observer
      observer.disconnect();
    };
  }, [props.images]);

  return (
    <div className={styles.headerImage} ref={containerRef}>
      {props.images?.map((image, index) => (
        <Image
          key={index}
          carousel
          className={styles.image}
          style={{ objectFit: props.imageSize ?? "cover" }}
          src={image}
          alt="header"
        />
      ))}
      {props.showNumbers && (
        <div className={styles.imageNumbers}>
          {currentIndex}/{props.images?.length}
        </div>
      )}
    </div>
  );
};

const styles = {
  headerImage: style({
    display: "flex",
    width: "100%",
    height: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    scrollSnapType: "x mandatory",
  }),
  image: style({
    flex: "0 0 auto",
    alignSelf: "center",
    maxHeight: "400px",
    width: "100%",
    height: "100%",
    scrollSnapAlign: "start",
  }),
  imageNumbers: style({
    position: "absolute",
    top: 0,
    left: 0,
    padding: 5,
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    borderRadius: "0 5px 5px 0",
  }),
};
