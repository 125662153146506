import {
  IQuestionnaireSubmission,
  ISubmission,
} from "@hulanbv/afbouwkeur-packages";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import alertIcon from "../../../assets/alert-circle.svg";
import { dictionary } from "../../../constants/i18n/dictionary";
import { submissionService } from "../../../services/submission.service";
import { Center } from "../../containers/center";
import { Filler } from "../../containers/filler";
import { Pill } from "../../containers/pill";
import { Image } from "../../controls/image";
import { Spacer } from "../notifications/spacer";
import { SubmissionRow } from "./submissionRow";
import { Toggler } from "../../controls/toggler";
import { style } from "typestyle";

interface IProps {
  onRefreshRequested?: () => void | Promise<void>;
  submissions: ISubmission[];
  questionnaireSubmissions?: IQuestionnaireSubmission[];
  onToggle?: (active: boolean, element?: string) => void;
  inactiveElements?: string[];
}

export const SubmissionOverview: React.FC<IProps> = (props) => {
  // distribute submissions by date
  const [dateSubmissions, setDateSubmissions] = useState<
    Record<string, ISubmission[]>
  >({});
  useEffect(() => {
    const submissions = submissionService.injectQuestionnaireSubmissions(
      props.submissions,
      props.questionnaireSubmissions ?? []
    );

    const _dateSubmissions: Record<string, ISubmission[]> = {};
    submissions.forEach((submission) => {
      const date = DateTime.fromISO(
        (submission.createdAt || "").toString()
      ).toISODate();
      _dateSubmissions[date] = _dateSubmissions[date] || [];
      _dateSubmissions[date].push(submission);
    });
    setDateSubmissions(_dateSubmissions);
  }, [props.questionnaireSubmissions, props.submissions]);

  return (
    <>
      {!props.submissions.length && (
        <Filler>
          <Image
            src={alertIcon}
            height={15}
            alt={"Exit"}
            style={{ opacity: 0.4, marginRight: 5 }}
          />
          {dictionary.no_submissions_found_for_this_assignment}
        </Filler>
      )}
      {Object.keys(dateSubmissions).map((date) => (
        <div
          key={date}
          className={
            dateSubmissions[date].some(
              ({ _id }) => !props.inactiveElements?.includes(_id)
            )
              ? ""
              : styles.hiddenOnPrint
          }
        >
          <Center>
            <Pill>
              {DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)}
            </Pill>
          </Center>

          {dateSubmissions[date].map((submission) => {
            const template = (
              <Spacer key={submission._id} height={10}>
                <SubmissionRow
                  submission={submission}
                  onCommentCreated={props.onRefreshRequested}
                />
              </Spacer>
            );

            if (props.onToggle) {
              return (
                <Toggler
                  key={submission._id}
                  id={submission._id}
                  onToggle={props.onToggle}
                  inactive={props.inactiveElements?.includes(submission._id)}
                >
                  {template}
                </Toggler>
              );
            }

            return template;
          })}
        </div>
      ))}
    </>
  );
};

const styles = {
  hiddenOnPrint: style({
    $nest: {
      "@media print": {
        display: "none",
      },
    },
  }),
};
