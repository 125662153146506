import {
  IAssignment,
  ICompany,
  ICustomer,
  IProject,
  IQuestionnaire,
  IUser,
  Role,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { Link } from "react-router-dom";
import { dictionary } from "./constants/i18n/dictionary";
import { assignmentService } from "./services/assignment.service";
import { authenticationService } from "./services/authentication.service";
import { companyService } from "./services/company.service";
import { customerService } from "./services/customer.service";
import { projectService } from "./services/project.service";
import { questionnaireService } from "./services/questionnaire.service";
import { userService } from "./services/user.service";
import { authUtils } from "./utils/authentication.utils";
import { json } from "./utils/statics.utils";
import AssignmentView from "./views/assignment.view";
import AssignmentFormView from "./views/assignmentForm.view";
import AttachmentFormView from "./views/attachmentForm.view";
import CompaniesView from "./views/companies.view";
import CompanyView from "./views/company.view";
import CompanyFormView from "./views/companyForm.view";
import ContactView from "./views/contactForm.view";
import ContactsView from "./views/contacts.view";
import CustomerView from "./views/customerForm.view";
import CustomersView from "./views/customers.view";
import EmployeeFormView from "./views/employeeForm.view";
import EmployeesView from "./views/employees.view";
import LoginView from "./views/login.view";
import LogoutView from "./views/logout.view";
import { NewsView } from "./views/news.view";
import { NewsFormView } from "./views/newsForm.view";
import ProjectView from "./views/project.view";
import ProjectFormView from "./views/projectForm.view";
import { ProjectPrintView } from "./views/projectPrint.view";
import ProjectsView from "./views/projects.view";
import { QuestionnaireFormView } from "./views/questionnaireForm.view";
import QuestionnairesView from "./views/questionnaires.view";
import ResetPasswordView from "./views/resetPassword.view";
import ResetPasswordRequestView from "./views/resetPasswordRequest.view";
import { SecurityQuestionsView } from "./views/securityQuestions.view";

export type RouteOptions = {
  path: (...args: string[]) => string;
  label?: string;
  component: any;
  roles: any[] | null;
  exact?: boolean;
  breadcrumbs?: (params: any) => Promise<(JSX.Element | string)[]>;
};

const routes = {
  home: {
    path: () => "/",
    component: ProjectsView,
    roles: [],
    breadcrumbs: async () => routes.projects.breadcrumbs(),
  },
  login: {
    path: () => "/",
    component: LoginView,
    roles: null,
  },
  news: {
    path: () => "/news",
    label: dictionary.news,
    component: NewsView,
    roles: [],
    exact: false,
    breadcrumbs: async () => [
      <Link to={routes.news.path()}>{dictionary.news}</Link>,
    ],
  },
  newsCreate: {
    path: () => `/news/new`,
    component: NewsFormView,
    roles: [Role.SYSTEM_ADMIN, Role.ADMIN],
    breadcrumbs: async () => [
      ...(await routes.news.breadcrumbs()),
      <Link to={routes.newsCreate.path()}>Aanmaken</Link>,
    ],
  },
  newsEdit: {
    path: (id = ":id") => `/news/${id}/edit`,
    component: NewsFormView,
    roles: [Role.SYSTEM_ADMIN, Role.ADMIN],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.news.breadcrumbs()),
      <Link to={routes.newsEdit.path(params.id)}>Aanpassen</Link>,
    ],
  },
  companies: {
    path: () => "/companies",
    label: "Deelnemers",
    component: CompaniesView,
    roles: [Role.ADMIN, Role.SYSTEM_ADMIN],
    exact: false,
    breadcrumbs: async () => [
      authUtils.satisfiesRoles(...routes.companies.roles) ? (
        <Link to={routes.companies.path()}>Deelnemers</Link>
      ) : (
        "Deelnemers"
      ),
    ],
  },
  securityQuestions: {
    path: () => `/companies/security-questions`,
    component: SecurityQuestionsView,
    roles: [Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async () => [
      ...(await routes.companies.breadcrumbs()),
      <Link to={routes.securityQuestions.path()}>Standaard LMRA vragen</Link>,
    ],
  },
  companyCreate: {
    path: () => `/companies/new`,
    component: CompanyFormView,
    roles: [Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.companies.breadcrumbs()),
      <Link to={routes.companyCreate.path()}>Aanmaken</Link>,
    ],
  },
  companyEdit: {
    path: (id = ":id") => `/companies/${id}/edit`,
    component: CompanyFormView,
    roles: [Role.ADMIN, Role.SYSTEM_ADMIN, Role.MANAGER],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.companies.breadcrumbs()),
      <Link to={routes.companyCreate.path()}>Aanpassen</Link>,
    ],
  },
  myCompany: {
    path: () => `/companies/own`,
    component: CompanyView,
    roles: [Role.MANAGER],
    label: "Deelnemer",
    breadcrumbs: async () => [
      ...(await routes.companies.breadcrumbs()),
      <Link to={routes.myCompany.path()}>
        {
          (
            await json<ICompany>(
              companyService.get(
                authenticationService.getSessionToken()?.user?.employedById ??
                  ""
              )
            )
          ).name
        }
      </Link>,
    ],
  },
  company: {
    path: (id = ":id") => `/companies/${id}`,
    component: CompanyView,
    roles: [Role.ADMIN, Role.SYSTEM_ADMIN, Role.MANAGER],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.companies.breadcrumbs()),
      <Link to={routes.company.path(params.id)}>
        {(await json<ICompany>(companyService.get(params.id))).name}
      </Link>,
    ],
  },
  companyEmployeeCreate: {
    path: (companyId = ":companyId") => `/companies/${companyId}/employees/new`,
    component: EmployeeFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { companyId: string }) => [
      ...(await routes.company.breadcrumbs({ id: params.companyId })),
      <Link to={routes.companyEmployeeCreate.path(params.companyId)}>
        Medewerker aanmaken
      </Link>,
    ],
  },
  companyEmployeeEdit: {
    path: (companyId = ":companyId", employeeId = ":employeeId") =>
      `/companies/${companyId}/employees/${employeeId}/edit`,
    component: EmployeeFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { companyId: string; employeeId: string }) => [
      ...(await routes.companyEmployee.breadcrumbs(params)),
      <Link
        to={routes.companyEmployeeEdit.path(
          params.companyId,
          params.employeeId
        )}
      >
        Aanpassen
      </Link>,
    ],
  },
  companyEmployee: {
    path: (companyId = ":companyId", employeeId = ":employeeId") =>
      `/companies/${companyId}/employees/${employeeId}`,
    component: EmployeeFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { companyId: string; employeeId: string }) => [
      ...(await routes.company.breadcrumbs({ id: params.companyId })),
      <Link
        to={routes.companyEmployee.path(params.companyId, params.employeeId)}
      >
        {(await json<IUser>(userService.get(params.employeeId))).fullname}
      </Link>,
    ],
  },
  employees: {
    path: () => "/employees",
    label: "Medewerkers",
    component: EmployeesView,
    roles: [],
    exact: false,
    breadcrumbs: async () => [
      <Link to={routes.employees.path()}>Medewerkers</Link>,
    ],
  },
  employeeCreate: {
    path: () => `/employees/new`,
    component: EmployeeFormView,
    roles: [],
    breadcrumbs: async () => [
      ...(await routes.employees.breadcrumbs()),
      <Link to={routes.employeeCreate.path()}>Aanmaken</Link>,
    ],
  },
  employee: {
    path: (employeeId = ":employeeId") => `/employees/${employeeId}`,
    component: EmployeeFormView,
    roles: [],
    breadcrumbs: async (params: { employeeId: string }) => [
      ...(await routes.employees.breadcrumbs()),
      <Link to={routes.employee.path(params.employeeId)}>
        {(await json<IUser>(userService.get(params.employeeId))).fullname}
      </Link>,
    ],
  },
  contacts: {
    path: () => "/contacts",
    label: "Contacten",
    component: ContactsView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    exact: false,
    breadcrumbs: async () => [
      <Link to={routes.contacts.path()}>Contacten</Link>,
    ],
  },
  contactCreate: {
    path: () => `/contacts/new`,
    component: ContactView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async () => [
      ...(await routes.contacts.breadcrumbs()),
      <Link to={routes.employeeCreate.path()}>Aanmaken</Link>,
    ],
  },
  contact: {
    path: (id = ":id") => `/contacts/${id}`,
    component: ContactView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.contacts.breadcrumbs()),
      <Link to={routes.contact.path(params.id)}>
        {(await json<IUser>(userService.get(params.id))).fullname}
      </Link>,
    ],
  },
  customers: {
    path: () => "/customers",
    label: "Opdrachtgevers",
    component: CustomersView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    exact: false,
    breadcrumbs: async () => [
      <Link to={routes.customers.path()}>Opdrachtgevers</Link>,
    ],
  },
  customerCreate: {
    path: () => `/customers/new`,
    component: CustomerView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async () => [
      ...(await routes.customers.breadcrumbs()),
      <Link to={routes.customerCreate.path()}>Aanmaken</Link>,
    ],
  },
  customer: {
    path: (id = ":id") => `/customers/${id}`,
    component: CustomerView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.customers.breadcrumbs()),
      <Link to={routes.customer.path(params.id)}>
        {(await json<ICustomer>(customerService.get(params.id))).name}
      </Link>,
    ],
  },
  projectAssignmentCreate: {
    path: (projectId = ":projectId") =>
      `/projects/${projectId}/assignments/new`,
    component: AssignmentFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { projectId: string }) => [
      ...(await routes.project.breadcrumbs({ id: params.projectId })),
      <Link to={routes.projectAssignmentCreate.path(params.projectId)}>
        Taak aanmaken
      </Link>,
    ],
  },
  projectAssignmentEdit: {
    path: (projectId = ":projectId", assignmentId = ":assignmentId") =>
      `/projects/${projectId}/assignments/${assignmentId}/edit`,
    component: AssignmentFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      projectId: string;
      assignmentId: string;
    }) => [
      ...(await routes.projectAssignment.breadcrumbs(params)),
      <Link
        to={routes.projectAssignmentEdit.path(
          params.projectId,
          params.assignmentId
        )}
      >
        Aanpassen
      </Link>,
    ],
  },
  projectAssignment: {
    path: (projectId = ":projectId", assignmentId = ":assignmentId") =>
      `/projects/${projectId}/assignments/${assignmentId}`,
    component: AssignmentView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      projectId: string;
      assignmentId: string;
    }) => [
      ...(await routes.project.breadcrumbs({ id: params.projectId })),
      <Link
        to={routes.projectAssignment.path(
          params.projectId,
          params.assignmentId
        )}
      >
        {
          (await json<IAssignment>(assignmentService.get(params.assignmentId)))
            .name
        }
      </Link>,
    ],
  },
  projectAssignmentAttachmentCreate: {
    path: (projectId = ":projectId", assignmentId = ":assignmentId") =>
      `/projects/${projectId}/assignments/${assignmentId}/attachments/new`,
    component: AttachmentFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      projectId: string;
      assignmentId: string;
      attachmentId: string;
    }) => [
      ...(await routes.projectAssignment.breadcrumbs({
        projectId: params.projectId,
        assignmentId: params.assignmentId,
      })),
      <Link
        to={routes.projectAssignmentAttachmentCreate.path(
          params.projectId,
          params.assignmentId
        )}
      >
        Bijlage aanmaken
      </Link>,
    ],
  },
  projectAssignmentAttachmentEdit: {
    path: (
      projectId = ":projectId",
      assignmentId = ":assignmentId",
      attachmentId = ":attachmentId"
    ) =>
      `/projects/${projectId}/assignments/${assignmentId}/attachments/${attachmentId}`,
    component: AttachmentFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      projectId: string;
      assignmentId: string;
      attachmentId: string;
    }) => [
      ...(await routes.projectAssignment.breadcrumbs({
        projectId: params.projectId,
        assignmentId: params.assignmentId,
      })),
      <Link
        to={routes.projectAssignmentAttachmentEdit.path(
          params.projectId,
          params.assignmentId,
          params.attachmentId
        )}
      >
        Bijlage
      </Link>,
    ],
  },
  projects: {
    path: () => "/projects",
    label: "Projecten",
    component: ProjectsView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    exact: false,
    breadcrumbs: async () => [
      <Link to={routes.projects.path()}>Projecten</Link>,
    ],
  },
  projectCreate: {
    path: () => `/projects/new`,
    component: ProjectFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async () => [
      ...(await routes.projects.breadcrumbs()),
      <Link to={routes.projectCreate.path()}>Aanmaken</Link>,
    ],
  },
  project: {
    path: (id = ":id") => `/projects/${id}`,
    component: ProjectView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.projects.breadcrumbs()),
      <Link to={routes.project.path(params.id)}>
        {(await json<IProject>(projectService.get(params.id))).name}
      </Link>,
    ],
  },
  projectEdit: {
    path: (id = ":id") => `/projects/${id}/edit`,
    component: ProjectFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { id: string }) => [
      ...(await routes.project.breadcrumbs({ id: params.id })),
      <Link to={routes.projectEdit.path(params.id)}>Aanpassen</Link>,
    ],
  },
  projectPrint: {
    path: (projectId = ":projectId", assignmentId = ":assignmentId?") =>
      `/projects/print/${projectId}/${assignmentId}`,
    component: ProjectPrintView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      projectId: string;
      assignmentId?: string;
    }) => {
      if (params.assignmentId) {
        return [
          ...(await routes.projectAssignment.breadcrumbs({
            projectId: params.projectId,
            assignmentId: params.assignmentId,
          })),
          <Link to={routes.projectPrint.path(params.projectId)}>Print</Link>,
        ];
      }
      return [
        ...(await routes.project.breadcrumbs({ id: params.projectId })),
        <Link to={routes.projectPrint.path(params.projectId)}>Print</Link>,
      ];
    },
  },
  projectAttachmentCreate: {
    path: (projectId = ":projectId") =>
      `/projects/${projectId}/attachments/new`,
    component: AttachmentFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      projectId: string;
      attachmentId: string;
    }) => [
      ...(await routes.project.breadcrumbs({ id: params.projectId })),
      <Link to={routes.projectAttachmentCreate.path(params.projectId)}>
        Bijlage aanmaken
      </Link>,
    ],
  },
  projectAttachmentEdit: {
    path: (projectId = ":projectId", attachmentId = ":attachmentId") =>
      `/projects/${projectId}/attachments/${attachmentId}`,
    component: AttachmentFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      projectId: string;
      attachmentId: string;
    }) => [
      ...(await routes.project.breadcrumbs({ id: params.projectId })),
      <Link
        to={routes.projectAttachmentEdit.path(
          params.projectId,
          params.attachmentId
        )}
      >
        Bijlage
      </Link>,
    ],
  },
  questionnaires: {
    path: () => "/questionnaires",
    label: dictionary.public_questionnaires,
    component: QuestionnairesView,
    roles: [Role.ADMIN, Role.SYSTEM_ADMIN],
    exact: false,
    breadcrumbs: async () => [
      <Link to={routes.questionnaires.path()}>
        {dictionary.public_questionnaires}
      </Link>,
    ],
  },
  questionnaireCreate: {
    path: () => `/questionnaires/new`,
    component: QuestionnaireFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async () => [
      ...(await routes.questionnaires.breadcrumbs()),
      <Link to={routes.questionnaireCreate.path()}>Aanmaken</Link>,
    ],
  },
  questionnaire: {
    path: (id = ":questionnaireId") => `/questionnaires/${id}`,
    component: QuestionnaireFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { questionnaireId: string }) => [
      ...(await routes.questionnaires.breadcrumbs()),
      <Link to={routes.questionnaire.path(params.questionnaireId)}>
        {
          (
            await json<IQuestionnaire>(
              questionnaireService.get(params.questionnaireId)
            )
          ).title
        }
      </Link>,
    ],
  },
  companyQuestionnaireCreate: {
    path: (companyId = ":companyId") =>
      `/companies/${companyId}/questionnaires/new`,
    component: QuestionnaireFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: { companyId: string }) => [
      ...(await routes.company.breadcrumbs({ id: params.companyId })),
      <Link to={routes.companyQuestionnaireCreate.path(params.companyId)}>
        Vragenlijst aanmaken
      </Link>,
    ],
  },
  companyQuestionnaire: {
    path: (companyId = ":companyId", questionnaireId = ":questionnaireId") =>
      `/companies/${companyId}/questionnaires/${questionnaireId}`,
    component: QuestionnaireFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      companyId: string;
      questionnaireId: string;
    }) => [
      ...(await routes.company.breadcrumbs({ id: params.companyId })),
      <Link
        to={routes.companyQuestionnaire.path(
          params.companyId,
          params.questionnaireId
        )}
      >
        {
          (
            await json<IQuestionnaire>(
              questionnaireService.get(params.questionnaireId)
            )
          ).title
        }
      </Link>,
    ],
  },
  companyQuestionnaireDuplication: {
    path: (companyId = ":companyId", questionnaireId = ":questionnaireId") =>
      `/companies/${companyId}/questionnaires/${questionnaireId}/new`,
    component: QuestionnaireFormView,
    roles: [Role.MANAGER, Role.ADMIN, Role.SYSTEM_ADMIN],
    breadcrumbs: async (params: {
      companyId: string;
      questionnaireId: string;
    }) => [
      ...(await routes.company.breadcrumbs({ id: params.companyId })),
      <Link to={routes.companyQuestionnaireCreate.path(params.companyId)}>
        Vragenlijst aanmaken
      </Link>,
    ],
  },
  resetPasswordRequest: {
    path: () => "/reset-password",
    component: ResetPasswordRequestView,
    roles: null,
  },
  resetPassword: {
    path: (email = ":email", token = ":token") =>
      `/reset-password/${email}/${token}`,
    component: ResetPasswordView,
    roles: null,
  },
  resetPasswordAuthorized: {
    path: (email = ":email", token = ":token") =>
      `/reset-password/${email}/${token}`,
    component: ResetPasswordView,
    roles: [],
  },
  logout: {
    path: () => "/logout",
    label: "Uitloggen",
    component: LogoutView,
    roles: [],
  },
  notFound: {
    path: () => "**",
    component: CompaniesView,
    roles: [],
  },
  notFoundNoSession: {
    path: () => "**",
    component: LoginView,
    roles: null,
  },
};

export default routes as { [key in keyof typeof routes]: RouteOptions };
