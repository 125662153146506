import { IUser, Role, Severity } from "@hulanbv/afbouwkeur-packages";
import React, { FC } from "react";
import UserForm from "../components/forms/userForm";
import { notificationListener } from "../observables/notification-listener";
import { userService } from "../services/user.service";
import { json } from "../utils/statics.utils";
import { dialog } from "./dialog";

interface IProps {
  model?: Partial<IUser>;
  roles?: Role[];
  onCreated?: (user: IUser) => void;
}

export const CreateUserDialog: FC<IProps> = (props) => {
  const saveUser = async (user: FormData) => {
    const created = await json<IUser>(userService.create(user));
    props.onCreated?.(created);

    notificationListener.next({
      message: "De gebruiker is succesvol aangemaakt",
      severity: Severity.SUCCESS,
    });
    dialog.unmount();
  };

  return (
    <UserForm roles={props.roles} user={props.model} onSubmit={saveUser} />
  );
};
