import { DateTime } from "luxon";

/**
 * Formats a date string or object to the given Luxon format
 * @param date
 * @param format
 */
export const formatDate = (date: string | Date, format: string) => {
  const dateTime = DateTime.fromJSDate(new Date(date));

  return dateTime.toFormat(format);
};
