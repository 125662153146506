import { ICustomer } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../../constants/i18n/dictionary";
import routes from "../../../routes";
import { routerService } from "../../../services/router.service";
import { TD } from "../../containers/tables/tableCell";
import { TR } from "../../containers/tables/tableRow";
import { Circle } from "../../shapes/circle";

export const customerRowHeadings = () => ({
  imagePath: "",
  name: dictionary.name,
  "location.city": dictionary.location,
  customerNumber: dictionary.customerNumber,
});

interface IProps {
  customer: ICustomer;
}

export const CustomerRow: React.FC<IProps> = (props) => (
  <TR
    onClick={() =>
      routerService.navigate(routes.customer.path(props.customer._id))
    }
  >
    <TD style={{ width: 50 }}>
      <Circle
        size={35}
        background={
          props.customer.imagePath
            ? `url(${props.customer.imagePath}) center center/contain no-repeat`
            : "rgba(0, 0, 0, 0.05)"
        }
      />
    </TD>
    <TD>{props.customer.name}</TD>

    <TD>{props.customer.location.city}</TD>
    <TD>{props.customer.customerNumber}</TD>
  </TR>
);
