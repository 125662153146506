import { IQuestionnaire } from "@hulanbv/afbouwkeur-packages";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { IResponse } from "../interfaces/response.interface";
import { CrudService } from "./crudService.abstract.service";
import { httpService } from "./http.service";

export const questionnaireService =
  new (class Service extends CrudService<IQuestionnaire> {
    constructor() {
      super("questionnaire");
    }

    /**
     * Get only active assignments
     * @param options
     */
    getPublic(options?: IHttpOptions): Promise<IResponse<IQuestionnaire[]>> {
      return httpService.get([this.controller, "public"].join("/"), options);
    }
  })();
