import { IResetPasswordRequest, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { ResetPasswordForm } from "../components/forms/resetPasswordForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { userService } from "../services/user.service";

export default class ResetPasswordView extends React.Component<
  RouteComponentProps
> {
  private email: string;
  private token: string;

  constructor(props: RouteComponentProps<{ email: string; token: string }>) {
    super(props);

    this.email = decodeURIComponent(props.match.params.email);
    this.token = decodeURIComponent(props.match.params.token);
  }

  render() {
    return (
      <Card
        head="Wachtwoord vergeten"
        attr={{ style: { maxWidth: "400px", margin: "0 auto" } }}
      >
        <ResetPasswordForm
          onSubmit={(data) => this.submit(data)}
          email={this.email}
        />
        <Button
          ghost
          onClick={() => this.props.history.push(routes.login.path())}
        >
          Inloggen
        </Button>
      </Card>
    );
  }

  /**
   * Log in to the application
   * @param credentials
   */
  private async submit(data: IResetPasswordRequest) {
    try {
      await userService.resetPassword(this.email, this.token, data.password, {
        loader: true,
      });
      notificationListener.next({
        message: "Je wachtwoord is succesvol aangepast",
        severity: Severity.SUCCESS,
      });
      this.props.history.push(routes.login.path());
    } catch {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.WARNING,
      });
    }
  }
}
