import React, { FC, ReactNode } from "react";
import { style } from "typestyle";

import { dialog } from "./dialog";
import { Button } from "../components/controls/button";
import { dictionary } from "../constants/i18n/dictionary";

interface IProps {
  children: ReactNode;
  onConfirm: () => void;
}

export const ConfirmDialog: FC<IProps> = (props) => {
  return (
    <div>
      <div className={styles.content}>
        {props.children}
        <div className={styles.buttons}>
          <Button bland onClick={() => dialog.unmount()}>
            {dictionary.cancel}
          </Button>
          <Button onClick={props.onConfirm}>{dictionary.confirm}</Button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  content: style({
    display: "flex",
    flexDirection: "column",
    gap: 20,
    width: 400,
  }),
  buttons: style({
    display: "flex",
    gap: 20,
  }),
};
