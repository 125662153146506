import { IQuestion, QuestionType } from "@hulanbv/afbouwkeur-packages";
import React, { useEffect, useState } from "react";
import xIcon from "../../../assets/x.svg";
import { dictionary } from "../../../constants/i18n/dictionary";
import { globalStyles } from "../../../styles";
import { FormRow } from "../../containers/formRow";
import { Button } from "../../controls/button";
import { Image } from "../../controls/image";
import { Input } from "../../controls/input";
import { Select } from "../../controls/select";

interface IProps {
  questions: IQuestion[];
  onChange?: (questions: IQuestion[]) => any;
  inputName?: string;
  readOnly?: boolean;
}

export const Questions: React.FC<IProps> = (props) => {
  const [questions, setQuestions] = useState<IQuestion[]>(props.questions);

  // create event handlers
  const add = () => {
    const _questions = questions.concat({
      value: "",
      type: QuestionType.BOOLEAN,
    });

    setQuestions(_questions);
    props.onChange?.(_questions);
  };

  const remove = (index: number) => {
    const _questions = questions.filter((x, i) => i !== index);

    setQuestions(_questions);
    props.onChange?.(_questions);
  };

  const change = (question: IQuestion, index: number) => {
    const _questions = questions.map((q, i) => (i === index ? question : q));

    setQuestions(_questions);
    props.onChange?.(_questions);
  };

  useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions]);

  return (
    <>
      {questions.map((question, i) => (
        <FormRow key={i + Date.now()}>
          <div className={globalStyles.flex}>
            <Input
              readOnly={props.readOnly}
              style={{ flex: 2 }}
              name={`${props.inputName || "questions"}[${i}][value]`}
              placeholder="Vraag"
              defaultValue={question.value}
              onBlur={(e) =>
                e.currentTarget.value !== question.value &&
                change({ value: e.currentTarget.value, type: question.type }, i)
              }
              required
            />
            <Select
              disabled={props.readOnly}
              style={{ flex: 1 }}
              name={`${props.inputName || "questions"}[${i}][type]`}
              defaultValue={question.type.toString()}
              options={[
                {
                  key: QuestionType.BOOLEAN.toString(),
                  value: "Ja/nee/n.v.t.",
                },
                { key: QuestionType.OPEN.toString(), value: "Open vraag" },
                { key: QuestionType.UPLOAD.toString(), value: "Upload" },
              ]}
              onChange={(e) =>
                change(
                  { value: question.value, type: +e.currentTarget.value },
                  i
                )
              }
              required
            />
            {!props.readOnly && (
              <Button ghost style={{ flex: 0 }} onClick={() => remove(i)}>
                <Image src={xIcon} height={15} alt={"x"} />
              </Button>
            )}
          </div>
        </FormRow>
      ))}
      {!props.readOnly && (
        <Button ghost onClick={add}>
          {dictionary.add_question}
        </Button>
      )}
    </>
  );
};
