import React, { useEffect } from "react";
import { messageService } from "../services/message.service";
import { submissionService } from "../services/submission.service";
import { Container } from "./containers/container";
import { NotificationCenter } from "./data/notifications/notification-center.component";
import Router from "./navigation/router";
import { Line } from "./shapes/line";
import { Footer } from "./statics/footer";

export const App: React.FC = () => {
  useEffect(() => {
    // poll for high risk submissions every 30 seconds
    submissionService.pollHighRisks();
    messageService.pollMessages();
    const interval = setInterval(() => {
      submissionService.pollHighRisks();
      messageService.pollMessages();
    }, 30 * 1000);

    return () => clearInterval(interval);
  });

  return (
    <>
      <Line style={{ zIndex: 1000, position: "relative" }} />
      <Container>
        <Router />
      </Container>
      <NotificationCenter />
      <Footer />
    </>
  );
};
