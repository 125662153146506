import { ICompany } from "@hulanbv/afbouwkeur-packages";
import { ISearchOption } from "../components/controls/search";
import routes from "../routes";
import { companyService } from "../services/company.service";
import { routerService } from "../services/router.service";
import { isNil, json } from "./statics.utils";

export const companyUtils = new (class Utils {
  /**
   * Returns the companies as IOption array
   * @param companies
   */
  public toOption(companies: (ICompany | undefined | null)[]): ISearchOption[] {
    return companies
      .filter((company) => !isNil(company))
      .map((company) => ({
        key: company!._id,
        value: company!.name,
        image: company!.imagePath,
        onClick: (option) => {
          routerService.navigate(routes.company.path(option.key));
        },
      }));
  }

  /**
   * Search for companies and retrieve options
   * @param query
   */
  public async searchCompanyOptions(query: string): Promise<ISearchOption[]> {
    return this.toOption(
      await json<ICompany[]>(
        companyService.getAll({
          search: query,
          searchScope: ["name"],
          limit: 5,
        })
      )
    );
  }
})();
