import React, { InputHTMLAttributes } from "react";
import { isNil } from "../../utils/statics.utils";
import { Flex } from "../containers/flex";
import { FormRow } from "../containers/formRow";
import { Input } from "./input";

interface IFormRow extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

interface IProps {
  inputs: (IFormRow | IFormRow[])[];
  readOnly?: boolean;
}

export const InputGroup: React.FC<IProps> = props => (
  <>
    {props.inputs.map((item, i) => {
      let inputs: IFormRow[] = !Array.isArray(item) ? [item] : item;
      return (
        <Flex key={i}>
          {inputs.map((input, i) => {
            const inputField = (
              <Input
                {...{
                  required: true,

                  ...input,
                  key: i,
                  type: input.type || "text",
                  label: undefined,
                  readOnly: props.readOnly
                }}
              />
            );

            if (input.type === "hidden") {
              return inputField;
            }
            return (
              <FormRow
                key={i}
                label={input.label}
                optional={
                  props.readOnly ||
                  (!isNil(input.required) && input.required !== true)
                }
              >
                {inputField}
              </FormRow>
            );
          })}
        </Flex>
      );
    })}
  </>
);
