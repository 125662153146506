import React from "react";
import { Sheet } from "strcss";

interface IProps {
  attr?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

export const Grid: React.FC<IProps> = props => (
  <div className={map.grid} {...(props.attr || {})}>
    {props.children}
  </div>
);

const { map } = new Sheet(`
  map grid
    display flex
`);

interface IItemProps extends IProps {
  flex?: number;
  width?: number | string;
  alignVertically?: boolean;
  alignHorizontally?: boolean;
}

export const GridItem: React.FC<IItemProps> = props => {
  const { map } = new Sheet(`
    map gridItem
      padding 15
      flex ${props.flex || 1}
      ${
        props.width
          ? `
        width ${props.width}
      `
          : ""
      }
      ${
        props.width
          ? `
        maxWidth ${props.width}
      `
          : ""
      }

      ${
        props.alignVertically
          ? `
        display flex
        alignItems center
      `
          : ""
      }

      ${
        props.alignHorizontally
          ? `
        display flex
        justifyContent space-around
      `
          : ""
      }
  `);

  return (
    <div className={map.gridItem} {...(props.attr || {})}>
      {props.children}
    </div>
  );
};
