import {
  IQuestionnaireSubmission,
  ISubmission,
  Risk,
  Severity,
  SubmissionType,
} from "@hulanbv/afbouwkeur-packages";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { IResponse } from "../interfaces/response.interface";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { json } from "../utils/statics.utils";
import { authenticationService } from "./authentication.service";
import { CrudService } from "./crudService.abstract.service";
import { httpService } from "./http.service";

export const submissionService =
  new (class Service extends CrudService<ISubmission> {
    private fetchedHighRiskIds: string[] = [];

    constructor() {
      super("submission");
    }

    /**
     * Get unread submissions
     * @param options
     */
    getUnread(options?: IHttpOptions): Promise<IResponse<ISubmission[]>> {
      return httpService.get([this.controller, "unread"].join("/"), options);
    }

    /**
     * Dismiss a submission
     * @param id
     */
    dismiss(id: string): Promise<IResponse<ISubmission>> {
      return httpService.post([this.controller, id, "dismiss"].join("/"), null);
    }

    /**
     * Poll for unread high risk submissions
     * and add them to the notificationcenter
     */
    async pollHighRisks() {
      if (!authenticationService.getSessionToken()) {
        return;
      }

      const highRisks = await json<ISubmission[]>(
        this.getUnread({
          filter: { risk: Risk.HIGH.toString() },
          populate: ["assignment"],
        })
      );
      highRisks.forEach((submission) => {
        if (this.fetchedHighRiskIds.includes(submission._id)) {
          return;
        }

        notificationListener.next({
          message: `Hoog risico gemeld bij ${submission.assignment?.name}`,
          severity: Severity.ALERT,
          duration: -1,
          navigateTo: routes.projectAssignment.path(
            submission.assignment?.projectId || "",
            submission.assignmentId
          ),
          onClose: () => this.dismiss(submission._id),
        });
        this.fetchedHighRiskIds.push(submission._id);
      });
    }

    injectQuestionnaireSubmissions(
      submissions: ISubmission[],
      questionnaireSubmissions: IQuestionnaireSubmission[]
    ): ISubmission[] {
      const _submissions = submissions.concat(
        questionnaireSubmissions.map((qs) => ({
          _id: null,
          assignmentId: qs.assignmentId,
          authorId: qs.authorId,
          risk: Risk.LOW,
          type: SubmissionType.DOCUMENT,
          imagePaths: qs.answers.flatMap((answer) => answer.imagePaths ?? []),
          assignment: qs.assignment,
          author: qs.author,
          createdAt: qs.createdAt,
          updatedAt: qs.updatedAt,
          content:
            "<p>" +
            qs.answers
              .map(
                (answer) =>
                  `<strong>${answer.question.value}</strong><br>${answer.answer}`
              )
              .join("</p><p>") +
            "</p>",
        }))
      );
      _submissions.sort((a, b) => {
        const aCreatedAt = new Date(a.createdAt ?? 0);
        const bCreatedAt = new Date(b.createdAt ?? 0);

        return bCreatedAt.getTime() - aCreatedAt.getTime();
      });
      return _submissions;
    }
  })();
