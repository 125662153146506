import React, { HTMLAttributes } from "react";
import { Sheet } from "strcss";

interface IProps extends HTMLAttributes<HTMLTableRowElement> {}

export const TR: React.FC<IProps> = props => (
  <tr
    {...{
      ...props,
      className: [props.className, props.onClick ? map.selectable : ""].join(
        " "
      )
    }}
  >
    {props.children}
  </tr>
);

const { map } = new Sheet(`
  map selectable
    cursor pointer
    background #fafafa !hover
`);
