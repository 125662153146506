import {
  IAssignment,
  IProject,
  ReportType,
} from "@hulanbv/afbouwkeur-packages";
import React, { FC, useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Fab } from "../components/controls/fab";
import { Toggler } from "../components/controls/toggler";
import { SubmissionOverview } from "../components/data/submissions/submissionOverview";
import AssignmentForm from "../components/forms/assignmentForm";
import ProjectForm from "../components/forms/projectForm";
import { H1 } from "../components/typography/h1";
import { H2 } from "../components/typography/h2";
import { dictionary } from "../constants/i18n/dictionary";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import routes from "../routes";
import { assignmentService } from "../services/assignment.service";
import { projectService } from "../services/project.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";
import { stylesheet } from "typestyle";
import { Image } from "../components/controls/image";
import afbouwLogo from "../assets/afbouwkeur-individual-logo.png";
import noaLogo from "../assets/noa-logo.png";

interface IParams {
  projectId: string;
  assignmentId?: string;
}

export const ProjectPrintView: FC<RouteComponentProps<IParams>> = (props) => {
  const [project, setProject] = useState<IProject | null>(null);
  const [assignments, setAssignments] = useState<IAssignment[]>([]);
  const [inactiveElements, setInactiveElements] = useState<string[]>([]);
  let key = 0;

  const fetchData = useCallback(async () => {
    try {
      if (!props.match.params.projectId) {
        throw new Error("No id defined");
      }

      // fetch the project
      const project = await json<IProject>(
        projectService.get(props.match.params.projectId)
      );
      setProject(project);

      // fetch the projects assignments or a single assignment based on params
      let options: IHttpOptions = {
        filter: { projectId: props.match.params.projectId },
        populate: [
          "company",
          "submissions.comments.author",
          "submissions.signature.author",
          "submissions.author",
          "questionnaireSubmissions.author",
        ],
      };
      if (props.match.params.assignmentId) {
        setAssignments([
          await json<IAssignment>(
            assignmentService.get(props.match.params.assignmentId, options)
          ),
        ]);
      } else {
        setAssignments(
          await json<IAssignment[]>(assignmentService.getAll(options))
        );
      }
    } catch (err) {
      routerService.navigate(routes.projects.path());
    }
  }, [props.match.params.assignmentId, props.match.params.projectId]);

  useEffect(() => {
    fetchData();

    // retrieve provided inactive elements
    const urlParams = new URLSearchParams(window.location.search);
    setInactiveElements(
      (urlParams.get("inactiveElements") || "").split(",").map((x) => x)
    );
  }, [
    props.match.params.projectId,
    props.match.params.assignmentId,
    fetchData,
  ]);

  /**
   * Toggle an element (in)active in the state
   * @param active
   * @param element
   */
  const toggleElement = (active: boolean, element?: string) => {
    if (element === undefined) {
      return;
    }
    setInactiveElements((_elements) => {
      if (active) {
        return _elements.filter((_element) => _element !== element);
      }
      return _elements.concat(element);
    });
  };

  if (!project) {
    return <div></div>;
  }

  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td>
            <Toggler
              id={++key}
              onToggle={toggleElement}
              inactive={inactiveElements.includes(key.toString())}
            >
              <H1>Project</H1>
              <ProjectForm
                onSubmit={() => {}}
                readOnly={true}
                project={project}
              />
            </Toggler>

            {assignments.map((assignment) => (
              <Toggler
                key={assignment._id}
                id={++key}
                onToggle={toggleElement}
                inactive={inactiveElements.includes(key.toString())}
              >
                <H1>Taak</H1>
                <AssignmentForm
                  onSubmit={() => {}}
                  readOnly={true}
                  assignment={assignment}
                />

                <Toggler
                  id={++key}
                  onToggle={toggleElement}
                  inactive={inactiveElements.includes(key.toString())}
                >
                  <H2>Dossier</H2>
                  <SubmissionOverview
                    onToggle={toggleElement}
                    inactiveElements={inactiveElements}
                    onRefreshRequested={fetchData}
                    submissions={assignment?.submissions ?? []}
                    questionnaireSubmissions={
                      assignment?.questionnaireSubmissions ?? []
                    }
                  />
                </Toggler>
              </Toggler>
            ))}
            <Fab onClick={() => window.print()}>{dictionary.print}</Fab>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className={styles.footer}>
              <span className={styles.footerText}>
                {" "}
                {dictionary.distributed_by}:{" "}
                {[
                  project.company?.name,
                  project.company?.location.address,
                  project.company?.location.zipCode +
                    ", " +
                    project.company?.location.city,
                ].join(" - ")}
              </span>
              <div style={{ display: "flex", gap: 20 }}>
                {(project.company?.reportType === ReportType.AFBOUWKEUR ||
                  project.company?.reportType === ReportType.NON_SPECIFIC) && (
                  <Image
                    src={afbouwLogo}
                    alt="Afbouwkeur"
                    className={styles.logo}
                  />
                )}
                {(project.company?.reportType === ReportType.NOA ||
                  project.company?.reportType === ReportType.NON_SPECIFIC) && (
                  <Image
                    src={noaLogo}
                    alt="Afbouwkeur"
                    className={styles.logo}
                  />
                )}
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

const styles = stylesheet({
  table: {
    overflow: "visible !important",
    width: "100%",
    pageBreakBefore: "always",

    $nest: {
      "& tbody thead": {
        display: "none !important",
      },
    },
  },
  footer: {
    height: 100,
    justifyContent: "space-between",
    alignItems: "end",
    display: "none",
    $nest: {
      "@media print": {
        display: "flex",
      },
    },
  },
  footerText: {
    opacity: 0.54,
  },
  logo: {
    height: 30,
  },
});
