import { ISubmission, ISubmissionComment } from "@hulanbv/afbouwkeur-packages";
import { DateTime } from "luxon";
import React from "react";
import { style } from "typestyle";
import messageIcon from "../../../assets/message.svg";
import colors from "../../../constants/colors.constants";
import { riskValues } from "../../../constants/enumValues.constants";
import { dictionary } from "../../../constants/i18n/dictionary";
import { CreateSubmissionCommentDialog } from "../../../dialogs/createSubmissionComment.dialog";
import { dialog } from "../../../dialogs/dialog";
import { Card } from "../../containers/card";
import { Grid, GridItem } from "../../containers/grid";
import { Pill } from "../../containers/pill";
import { Image } from "../../controls/image";
import { Submission } from "./submission";

interface IProps {
  onCommentCreated?: (model: ISubmissionComment) => void | Promise<void>;
  submission: ISubmission;
}

export const SubmissionRow: React.FC<IProps> = (props) => {
  const time = DateTime.fromISO(
    (props.submission.createdAt || "").toString()
  ).toFormat("HH:mm");
  return (
    <Grid attr={{ style: { pageBreakInside: "avoid" } }}>
      <GridItem alignVertically flex={2}>
        {time}{" "}
        {props.submission.risk !== undefined && (
          <>
            -&nbsp;
            <span style={{ color: colors.risk[props.submission.risk] }}>
              {riskValues[props.submission.risk]} {dictionary.risk}
            </span>
          </>
        )}
      </GridItem>

      <GridItem flex={6}>
        <Grid>
          <GridItem flex={1} alignHorizontally alignVertically>
            <div>
              <Image
                title="Reageer"
                className={styles.reply}
                src={messageIcon}
                alt=""
                onClick={() =>
                  dialog.mount({
                    title: "Reageer",
                    children: (
                      <CreateSubmissionCommentDialog
                        submissionId={props.submission._id}
                        onCreated={props.onCommentCreated}
                      />
                    ),
                  })
                }
              />
            </div>
          </GridItem>
          <GridItem flex={24}>
            <Submission submission={props.submission} />
            {props.submission.comments?.map((comment) => (
              <Card
                images={comment.imagePath ? [comment.imagePath] : undefined}
                attr={{ className: styles.comment }}
              >
                {comment.content}
                <div className={styles.author}>
                  {comment.author?.fullname} -{" "}
                  {DateTime.fromISO(
                    (comment.createdAt || "").toString()
                  ).toFormat("dd-LL-y HH:mm")}
                  <Pill size="small">
                    {comment.isPublic ? "Zichtbaar voor medewerkers" : "Privé"}
                  </Pill>
                </div>
              </Card>
            ))}
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};

const styles = {
  reply: style({
    cursor: "pointer",
    transition: ".3s opacity",
    opacity: 0.54,

    $nest: {
      "&:hover": {
        opacity: 0.87,
      },
      "@media print": {
        display: "none",
      },
    },
  }),

  comment: style({
    marginTop: 20,
    marginLeft: 50,
  }),
  author: style({
    paddingTop: 10,
    opacity: 0.54,
    display: "flex",
    alignItems: "center",
  }),
};
