import {
  ICompany,
  ISecurityQuestion,
  ReportType,
  Severity,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import CompanyForm from "../components/forms/companyForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { companyService } from "../services/company.service";
import { routerService } from "../services/router.service";
import { securityQuestionService } from "../services/securityQuestion.service";
import { json } from "../utils/statics.utils";

interface IState {
  company?: ICompany;
  image?: string;
}

export default class CompanyFormView extends React.Component<
  RouteComponentProps,
  IState
> {
  private id: string | null = null;
  public state: IState = {
    company: undefined,
    image: undefined,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    const id = (this.props.match as any).params.id;
    if (id && id !== "new") {
      this.id = id;
    }
  }

  componentDidMount() {
    this.fetchCompany();
    this.setCompanyTemplate();
  }

  render() {
    return (
      <>
        {this.state.company && (
          <Card
            key={this.state.company._id}
            attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}
            images={this.state.image ? [this.state.image] : undefined}
            imageSize="contain"
          >
            <CompanyForm
              onImageChange={(image) => this.setState({ image })}
              onSubmit={(company) => this.submit(company)}
              company={this.state.company}
            />
          </Card>
        )}
      </>
    );
  }

  private async submit(company: FormData) {
    try {
      const created = await json<ICompany>(
        companyService.createOrPatch(company as any, { loader: true })
      );
      notificationListener.next({
        message: "Het bedrijf is succesvol opgeslagen",
        severity: Severity.SUCCESS,
      });

      routerService.navigate(routes.company.path(created._id));
    } catch (err) {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.ALERT,
      });
    }
  }

  private async setCompanyTemplate() {
    if (this.id) {
      return;
    }

    const questions = await json<ISecurityQuestion[]>(
      securityQuestionService.getAll({ sort: ["order"] })
    );
    const company: ICompany = {
      _id: undefined,
      location: { address: "", city: "", coordinates: [0, 0], zipCode: "" },
      name: "",
      securityQuestions: questions.map(({ text }) => text),
      reportType: ReportType.NON_SPECIFIC,
    };
    this.setState({ company });
  }

  private async fetchCompany() {
    if (!this.id) {
      return;
    }

    const company = await json<ICompany>(companyService.get(this.id));
    this.setState({ company, image: company.imagePath || undefined });
  }
}
