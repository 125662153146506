import React from "react";
import { style } from "typestyle";
import { Button, IButtonProps } from "./button";

export const Fab: React.FC<IButtonProps> = (props) => (
  <Button {...props} className={[props.className, styles.fab].join(" ")}>
    {props.children}
  </Button>
);

const styles = {
  fab: style({
    position: "fixed",
    right: 50,
    bottom: 50,
    width: "fit-content",
    padding: 20,

    $nest: {
      "@media print": {
        display: "none",
      },
    },
  }),
};
