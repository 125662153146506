import { IModel } from "@hulanbv/afbouwkeur-packages";
import { IHttpOptions } from "../interfaces/httpOptions.interface";
import { IResponse } from "../interfaces/response.interface";
import { httpService } from "./http.service";

export abstract class CrudService<Model extends IModel> {
  private modelStore: { [id: string]: Model } = {};

  constructor(protected readonly controller: string) {}

  /**
   * Creates or patches a model based on the presence of an _id
   */
  createOrPatch(
    body: Model,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    if (!body._id) {
      return this.create(body, options);
    }
    return this.patch(body, options);
  }
  /**
   * Creates or patches a model based on the presence of an _id
   */
  createOrPut(body: Model, options?: IHttpOptions): Promise<IResponse<Model>> {
    if (!body._id) {
      return this.create(body, options);
    }
    return this.put(body, options);
  }

  /**
   * Creates a new model
   */
  create(
    body: Model | FormData,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    return this.cache(httpService.post(this.controller, body, options));
  }

  /**
   * Fetches a specific model
   */
  async get(id: string, options?: IHttpOptions): Promise<IResponse<Model>> {
    if (options && options.cached && this.modelStore[id]) {
      return this.getCached(id);
    }

    options = options || {};
    options.populate = options.populate || [];

    return this.cache(
      httpService.get([this.controller, id].join("/"), options)
    );
  }

  /**
   * Fetches multiple specific models
   */
  getMany(ids: string[], options?: IHttpOptions): Promise<IResponse<Model[]>> {
    return this.cache(
      httpService.get([this.controller, ids.join(",")].join("/"), options)
    );
  }

  /**
   * Fetches all models
   */
  getAll(options?: IHttpOptions): Promise<IResponse<Model[]>> {
    return this.cache(httpService.get(this.controller, options));
  }

  /**
   * Overwrites the model with the same id
   */
  put(body: Model, options?: IHttpOptions): Promise<IResponse<Model>> {
    return this.cache(httpService.put(this.controller, body, options));
  }

  /**
   * Merges the model with the same id
   */
  patch(
    body: Partial<Model> | FormData,
    options?: IHttpOptions
  ): Promise<IResponse<Model>> {
    return this.cache(httpService.patch(this.controller, body, options));
  }

  /**
   * Deletes a specific model
   */
  delete(id: string, options?: IHttpOptions): Promise<IResponse<Model>> {
    delete this.modelStore[id];

    return this.cache(
      httpService.delete([this.controller, id].join("/"), options)
    );
  }

  /**
   * Caches a single or a set of models to the store
   * @param request
   */
  private async cache<ResponseType>(
    request: Promise<IResponse<ResponseType>>
  ): Promise<IResponse<ResponseType>> {
    const response = await request;
    const json = await response.clone().json();

    const models: Model[] = Array.isArray(json) === false ? [json] : json;
    models.forEach((model) => {
      this.modelStore[model._id] = model;
    });

    return response;
  }

  /**
   * Returns a cached model from the store
   * @param id
   */
  private getCached(id: string): IResponse<Model> {
    return new Response(JSON.stringify(this.modelStore[id]));
  }
}
