import { ICustomer } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import CrudTable from "../components/containers/tables/crudTable";
import {
  CustomerRow,
  customerRowHeadings,
} from "../components/data/table-rows/customerRow";
import routes from "../routes";
import { customerService } from "../services/customer.service";
import { routerService } from "../services/router.service";

export default class CustomersView extends React.Component<
  RouteComponentProps
> {
  render() {
    return (
      <CrudTable<ICustomer>
        limit={10}
        defaultSortBy="name"
        headings={customerRowHeadings()}
        rowTemplate={(customer) => (
          <CustomerRow key={customer._id} customer={customer} />
        )}
        fetchMethod={(options) =>
          customerService.getAll({
            populate: [],
            searchScope: Object.keys(customerRowHeadings),
            ...options,
          })
        }
        onCreateClick={() =>
          routerService.navigate(routes.customer.path("new"))
        }
      />
    );
  }
}
