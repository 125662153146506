import { ICompany } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../../constants/i18n/dictionary";
import routes from "../../../routes";
import { routerService } from "../../../services/router.service";
import { TD } from "../../containers/tables/tableCell";
import { TR } from "../../containers/tables/tableRow";
import { Circle } from "../../shapes/circle";

export const companyRowHeadings = () => ({
  imagePath: "",
  name: dictionary.name,
  "manager.firstName": dictionary.manager,
  "manager.phoneNumber": dictionary.phone_number,
  "manager.email": dictionary.email,
  "location.city": dictionary.location,
});

interface IProps {
  company: ICompany;
}

export const CompanyRow: React.FC<IProps> = (props) => (
  <TR
    onClick={() =>
      routerService.navigate(routes.company.path(props.company._id))
    }
  >
    <TD style={{ width: 50 }}>
      <Circle
        size={35}
        background={
          props.company.imagePath
            ? `url(${props.company.imagePath}) center center/contain no-repeat`
            : "rgba(0, 0, 0, 0.05)"
        }
      />
    </TD>
    <TD>{props.company.name}</TD>
    <TD>{props.company.manager ? `${props.company.manager.fullname}` : ""}</TD>
    <TD>
      {props.company.manager
        ? `${props.company.manager.phoneNumber || ""}`
        : ""}
    </TD>
    <TD>{props.company.manager ? `${props.company.manager.email}` : ""}</TD>
    <TD>{props.company.location ? props.company.location.city : ""}</TD>
  </TR>
);
