import { ICompany } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { style } from "typestyle";
import CrudTable from "../components/containers/tables/crudTable";
import { Button } from "../components/controls/button";
import {
  CompanyRow,
  companyRowHeadings,
} from "../components/data/table-rows/companyRow";
import routes from "../routes";
import { authenticationService } from "../services/authentication.service";
import { companyService } from "../services/company.service";
import { routerService } from "../services/router.service";

export default class CompaniesView extends React.Component<
  RouteComponentProps
> {
  render() {
    return (
      <>
        <div className={styles.flex}>
          {routes.securityQuestions.roles?.includes(
            authenticationService.getSessionToken()?.user?.role
          ) && (
            <Button
              small
              bland
              onClick={() =>
                routerService.navigate(routes.securityQuestions.path())
              }
            >
              Standaard LMRA
            </Button>
          )}
        </div>
        <CrudTable<ICompany>
          limit={10}
          defaultSortBy="name"
          headings={companyRowHeadings()}
          rowTemplate={(item) => <CompanyRow key={item._id} company={item} />}
          onCreateClick={() =>
            routerService.navigate(routes.company.path("new"))
          }
          fetchMethod={(options) =>
            companyService.getAll({
              populate: ["manager"],
              searchScope: Object.keys(companyRowHeadings()),
              ...options,
            })
          }
        />
      </>
    );
  }
}

const styles = {
  flex: style({
    display: "flex",
    $nest: {
      "& > button": {
        margin: "0 0 20px auto",
        flex: 0,
        whiteSpace: "nowrap",
      },
    },
  }),
};
