import { IAttachment } from "@hulanbv/afbouwkeur-packages";
import React, { FormEvent, useRef } from "react";
import fileIcon from "../../assets/file.svg";
import { dictionary } from "../../constants/i18n/dictionary";
import { globalStyles } from "../../styles";
import { formUtils } from "../../utils/form.utils";
import { userUtils } from "../../utils/user.utils";
import { Card } from "../containers/card";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Image } from "../controls/image";
import { Input } from "../controls/input";
import Search from "../controls/search";
import { Textarea } from "../controls/textarea";
import { Spacer } from "../data/notifications/spacer";

interface IProps {
  onSubmit: (data: FormData) => any;
  attachment?: Partial<IAttachment>;
}

export const AttachmentForm: React.FC<IProps> = (props) => {
  const submit = (e: FormEvent) => {
    e.preventDefault();
    const formdata = formUtils.getFormData(e.currentTarget as any);
    if (file) {
      formdata.append("file", file);
    }

    props.onSubmit(formdata);
  };

  const ref = useRef<HTMLInputElement>(null);
  let file: File | null = null;
  return (
    <form onSubmit={submit}>
      {props.attachment && (
        <>
          <input type="hidden" name="_id" value={props.attachment._id} />
          {props.attachment.projectId && (
            <input
              type="hidden"
              name="projectId"
              value={props.attachment.projectId}
            />
          )}
          {props.attachment.assignmentId && (
            <input
              type="hidden"
              name="assignmentId"
              value={props.attachment.assignmentId}
            />
          )}
        </>
      )}

      {props.attachment && props.attachment.filePath && (
        <>
          <a
            href={props.attachment.filePath}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card padding={20}>
              <div className={globalStyles.flexAligned}>
                <Image
                  src={fileIcon}
                  height={15}
                  alt={""}
                  style={{ marginRight: 8 }}
                />{" "}
                {dictionary.attachment}.
                {props.attachment.filePath.split(".").reverse()[0]}
              </div>
            </Card>
          </a>
          <Spacer height={20} />
        </>
      )}

      <FormRow
        label={dictionary.file}
        optional={!!props.attachment && !!props.attachment.filePath}
      >
        <input type="hidden" name="file" ref={ref} />
        <Input
          required={!props.attachment || !props.attachment.filePath}
          name="_filePath"
          type="file"
          accept="image/jpeg,image/gif,image/png,application/pdf"
          placeholder={dictionary.file}
          onChange={async (e) =>
            (file = await formUtils.processFileInput(e, () => {}))
          }
        />
      </FormRow>

      <FormRow label={dictionary.name}>
        <Input
          defaultValue={props.attachment?.name}
          type="text"
          name="name"
          required
          placeholder={dictionary.name}
        />
      </FormRow>

      <FormRow label={dictionary.file_description} optional>
        <Textarea
          name="description"
          placeholder={dictionary.description}
          defaultValue={
            props.attachment ? props.attachment.description : undefined
          }
        />
      </FormRow>

      <FormRow optional label={dictionary.specifically_authorized_employees}>
        <Search
          name="eligibleUserIds"
          multiple
          searchMethod={(query) =>
            userUtils.searchUserOptions({ search: query, limit: 5 })
          }
          defaultValue={userUtils.toOption(
            props.attachment ? props.attachment.eligibleUsers || [] : []
          )}
          placeholder={dictionary.find_employees}
        />
      </FormRow>

      <FormRow>
        <Button type="submit">{dictionary.save}</Button>
      </FormRow>
    </form>
  );
};
