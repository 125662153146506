import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { Singleton } from "react-singletons";
import { style } from "typestyle";
import closeCircleIcon from "../assets/close-circle.svg";
import { Card } from "../components/containers/card";
import { Image } from "../components/controls/image";
import { H1 } from "../components/typography/h1";
import { routerService } from "../services/router.service";
interface IProps extends React.Props<{}> {
  imagePath?: string;
  title?: string;
  width?: number;
}

const closeOnEscape = (e: KeyboardEvent) => {
  if (e.keyCode === 27) {
    dialog.unmount();
  }
};

export const Dialog: FC<IProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);

  useLayoutEffect(() => {
    window.setTimeout(() => setVisible(true), 10);
  }, []);

  useEffect(() => {
    const listener = routerService.onRouteChange.subscribe(() =>
      dialog.unmount()
    );
    document.body.style.overflow = "hidden";
    document.addEventListener("keyup", closeOnEscape);

    return () => {
      document.body.style.overflow = "initial";
      document.removeEventListener("keyup", closeOnEscape);
      listener.unsubscribe();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={[styles.backdrop, visible ? styles.visible : ""].join(" ")}
      >
        <div className={styles.dialog} style={{ width: props.width }}>
          <H1
            attributes={{ className: styles.title }}
            controls={
              <Image
                className={styles.closeIcon}
                onClick={() => dialog.unmount()}
                src={closeCircleIcon}
                alt="Close"
              />
            }
          >
            {props.title ?? <div />}
          </H1>
          <Card
            attr={{ className: styles.card }}
            images={props.imagePath ? [props.imagePath] : undefined}
          >
            {props.children}
          </Card>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: style({
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 999,
  }),
  backdrop: style({
    overflowY: "auto",
    height: "100%",
    opacity: 0,
    transition: ".3s opacity",

    $nest: {
      "&:before": {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
        backgroundColor: "rgba(0,0,0,.8)",
        content: "''",
      },
    },
  }),
  dialog: style({
    zIndex: 1,
    position: "relative",
    width: 500,
    maxWidth: "100%",
    margin: "80px auto",
    transform: "translateY(-30px)",
    opacity: 0,
    transition: ".2s all",
  }),
  card: style({
    padding: 15,
  }),
  title: style({
    color: "#FFF",
  }),
  closeIcon: style({
    height: 22,
    opacity: 0.5,
    cursor: "pointer",
    transition: ".3s opacity",

    $nest: {
      "&:hover": {
        opacity: 0.8,
      },
    },
  }),
  visible: style({
    opacity: 1,
    $nest: {
      "& > div": {
        transform: "translateY(0px)",
        opacity: 1,
      },
    },
  }),
};

export const dialog = new Singleton<IProps>(Dialog);
