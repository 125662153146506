import React from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

interface IProps {}

export const Filler: React.FC<IProps> = props => (
  <div className={map.filler}>
    <div className={map.aligner}>{props.children}</div>
  </div>
);

const { map } = new Sheet(`
  map filler
    textAlign center
    color ${colors.text.tertiary}
    display flex
    justifyContent space-around
    padding 10

  map aligner
    display flex
    alignItems center

`);
