import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { INotification } from "../../../interfaces/notification.interface";
import { notificationListener } from "../../../observables/notification-listener";
import { Notification } from "./notification.component";

interface IProps {}

export const NotificationCenter: FC<IProps> = props => {
  const [notifications, setNotifications] = useState<{
    [timestamp: string]: INotification;
  }>({});

  /**
   * Clean up all expired notifications
   */
  const cleanup = () => {
    setNotifications(notifications => {
      const _notifications: typeof notifications = {};
      Object.keys(notifications).forEach(key => {
        if (
          !notifications[key].isDismissed &&
          Date.now() < +key + (notifications[key].duration ?? 0)
        ) {
          _notifications[key] = notifications[key];
        }
      });

      return _notifications;
    });
  };

  /**
   * Add a new notification to the queue
   * @param notification
   */
  const addNotification = (notification: INotification) => {
    if (notification.duration === -1) {
      notification.duration = 1000 * 60 * 60 * 24 * 365;
    }

    // force a duration
    notification.duration =
      notification.duration ??
      Math.floor(notification.message.length / 10) * 1000;

    setNotifications(prev => ({ ...prev, [Date.now()]: notification }));
    setTimeout(cleanup, notification.duration);
  };

  useEffect(() => {
    const listener = notificationListener.subscribe(addNotification);
    return () => listener.unsubscribe();
  });

  return (
    <div className={styles.notificationCenter}>
      {Object.keys(notifications)
        .reverse()
        .map(key => (
          <Notification
            onClose={() => {
              notifications[key].isDismissed = true;
              cleanup();
            }}
            key={key}
            notification={notifications[key]}
          />
        ))}
    </div>
  );
};

const styles = {
  notificationCenter: style({
    position: "fixed",
    right: 30,
    bottom: 30,
    flexDirection: "column",
    alignItems: "flex-end",
    display: "flex"
  })
};
