import { ISubmissionComment } from "@hulanbv/afbouwkeur-packages";
import React, { FormEvent } from "react";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox.element";
import { Input } from "../controls/input";
import { Textarea } from "../controls/textarea";

interface IProps {
  onSubmit: (data: FormData) => any;
  model?: Partial<ISubmissionComment>;
}

export const SubmissionCommentForm: React.FC<IProps> = (props) => {
  const submit = (e: FormEvent) => {
    e.preventDefault();

    props.onSubmit(new FormData(e.currentTarget as HTMLFormElement));
  };

  return (
    <form onSubmit={submit}>
      {props.model && (
        <>
          <input type="hidden" name="_id" value={props.model._id} />
          {props.model.submissionId && (
            <input
              type="hidden"
              name="submissionId"
              value={props.model.submissionId}
            />
          )}
          {props.model.authorId && (
            <input type="hidden" name="authorId" value={props.model.authorId} />
          )}
        </>
      )}
      <FormRow label="Afbeelding" optional>
        <Input name="file" type="file" accept="image/*" />
      </FormRow>

      <FormRow label="Bericht">
        <Textarea
          required
          name="content"
          placeholder="Bericht"
          defaultValue={props.model?.content}
        />
      </FormRow>

      <FormRow optional>
        <Checkbox name="isPublic" defaultValue={props.model?.isPublic}>
          Is zichtbaar voor medewerkers
        </Checkbox>
      </FormRow>

      <FormRow>
        <Button type="submit"> Opslaan </Button>
      </FormRow>
    </form>
  );
};
