import { ISubmission, SubmissionType } from "@hulanbv/afbouwkeur-packages";
import { DateTime } from "luxon";
import React from "react";
import { Sheet } from "strcss";
import locationIcon from "../../../assets/location.svg";
import colors from "../../../constants/colors.constants";
import { Card } from "../../containers/card";
import { Image } from "../../controls/image";

interface IProps {
  submission: ISubmission;
}

const activities = {
  [SubmissionType.DOCUMENT]: "Document gedeeld",
  [SubmissionType.GRADATION]: "Gradatie gewijzigd",
  [SubmissionType.LOCATION]: "Locatie aangepast",
  [SubmissionType.START_WORKING]: "Gestart met werken",
  [SubmissionType.STOP_WORKING]: "Gestopt met werken",
  [SubmissionType.TEXT]: "Bericht gedeeld",
  [SubmissionType.QUESTIONNAIRE]: "Vragenlijst ingevuld",
};

export const Submission: React.FC<IProps> = (props) => (
  <Card images={props.submission.imagePaths} hasImageNumbers imageSize="cover">
    {props.submission.content && (
      <div
        className={map.content}
        dangerouslySetInnerHTML={{ __html: props.submission.content }}
      ></div>
    )}
    <div className={map.details}>
      <div>
        {props.submission.author
          ? props.submission.author.fullname
          : "Onbekende auteur"}{" "}
        - {activities[props.submission.type]}
      </div>
      {props.submission.location?.coordinates && (
        <a
          className={map.locationLink}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://maps.google.com/?q=${props.submission.location.coordinates.join(
            ","
          )}`}
        >
          <Image alt="" src={locationIcon} className={map.icon} /> bekijk
          locatie
        </a>
      )}
    </div>
    {props.submission.signature && (
      <div className={map.signatureRow}>
        <div className={map.signedBy}>
          Ondertekend door {props.submission.signature.name}
          <div className={map.signDate}>
            {DateTime.fromISO(
              (props.submission.signature.createdAt || "").toString()
            ).toFormat("dd-LL-y HH:mm")}
          </div>
        </div>
        <Image
          className={map.signature}
          src={props.submission.signature.imagePath}
          alt=""
        />
      </div>
    )}
  </Card>
);

const { map } = new Sheet(`
    map content 
        paddingBottom 20

    map details
        fontSize 0.857em
        display flex
        justifyContent space-between
        color ${colors.text.secondary}

    map icon
      height 14
      marginRight 3

    map locationLink
        display flex
        alignItems center
        color #707070
        opacity .6

    map signatureRow
      display flex
      paddingTop 30
      marginTop 30
      borderTop 1 solid #eee
      alignItems center

    map signedBy
      flex 1
      whiteSpace nowrap
      opacity .87

    map signDate
      paddingTop 10
      opacity .54
      
              

    map signature
      height 75
`);
