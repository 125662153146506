import { ICustomer } from "@hulanbv/afbouwkeur-packages";
import { CrudService } from "./crudService.abstract.service";

export const customerService = new (class Service extends CrudService<
  ICustomer
> {
  constructor() {
    super("customer");
  }
})();
