import {
  IAssignment,
  IProject,
  IQuestionnaire,
  Severity,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { notificationListener } from "../../observables/notification-listener";
import routes from "../../routes";
import { assignmentService } from "../../services/assignment.service";
import { projectService } from "../../services/project.service";
import { questionnaireService } from "../../services/questionnaire.service";
import { routerService } from "../../services/router.service";
import { authUtils } from "../../utils/authentication.utils";
import { formUtils } from "../../utils/form.utils";
import { questionnaireUtils } from "../../utils/questionnaire.utils";
import { json } from "../../utils/statics.utils";
import { userUtils } from "../../utils/user.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox.element";
import { DateInput } from "../controls/dateInput";
import { inputStyles } from "../controls/input";
import { InputGroup } from "../controls/inputGroup";
import { PhoneInput } from "../controls/phoneInput";
import Search from "../controls/search";
import { Textarea } from "../controls/textarea";
import { Questions } from "./components/questions";
import { SecurityQuestions } from "./components/securityQuestions";
import { Card } from "../containers/card";

interface IState {
  assignment: IAssignment | null;
}

interface IProps {
  assignment?: Partial<IAssignment>;
  onSubmit: (assignment: IAssignment) => any;
  readOnly?: boolean;
}

export default class AssignmentForm extends React.Component<IProps, IState> {
  private formRef = React.createRef<HTMLFormElement>();
  public state: IState = {
    assignment: null,
  };

  componentDidMount() {
    const assignment: IAssignment = {
      _id: null,
      name: "",
      description: "",
      projectId: "",
      executorIds: [],
      securityQuestions: [],
      recurringQuestionnaire: false,

      ...this.props.assignment,
    };

    this.setState({ assignment }, () => this.supplementQuestions());
  }

  render() {
    return (
      this.state.assignment && (
        <form
          ref={this.formRef}
          onSubmit={(e) => {
            e.preventDefault();
            this.submit();
          }}
        >
          <input
            type="hidden"
            value={this.state.assignment.projectId}
            name="projectId"
          />
          <InputGroup
            readOnly={this.props.readOnly}
            inputs={[
              {
                name: "_id",
                type: "hidden",
                defaultValue: this.state.assignment._id || undefined,
                required: false,
              },
              {
                name: "name",
                label: dictionary.assignment_name,
                type: "text",
                placeholder: dictionary.name,
                defaultValue: this.state.assignment.name,
              },
              {
                name: "email",
                label: dictionary.email,
                type: "text",
                placeholder: dictionary.defaults_to_manager,
                defaultValue: this.state.assignment.email,
                required: false,
              },
            ]}
          />
          <FormRow label={dictionary.phone_number} optional>
            <PhoneInput
              name={"phoneNumber"}
              type={"text"}
              readOnly={this.props.readOnly}
              placeholder={dictionary.defaults_to_manager}
              defaultValue={this.state.assignment.phoneNumber}
              required={false}
            />
          </FormRow>
          <FormRow label={dictionary.start_date} optional>
            <DateInput
              readOnly={this.props.readOnly}
              name="startDate"
              defaultValue={this.state.assignment.startDate?.toString()}
              placeholder={dictionary.start_date}
            />
          </FormRow>
          <FormRow label={dictionary.executing_employees}>
            <Search
              required
              readOnly={this.props.readOnly}
              name="executorIds"
              multiple
              searchMethod={(query) =>
                userUtils.searchUserOptions({ search: query, limit: 5 })
              }
              defaultValue={userUtils.toOption(
                this.state.assignment.executors || [],
                (option) => {
                  routerService.navigate(routes.employee.path(option.key));
                }
              )}
              placeholder={dictionary.find_employees}
            />
          </FormRow>
          <FormRow label={dictionary.assignment_description}>
            <Textarea
              readOnly={this.props.readOnly}
              className={inputStyles}
              name="description"
              defaultValue={this.state.assignment.description}
            />
          </FormRow>
          <FormRow
            label={dictionary.lmra_questions}
            optional={this.props.readOnly}
          >
            <SecurityQuestions
              readOnly={this.props.readOnly}
              questions={this.state.assignment.securityQuestions || []}
              onChange={(securityQuestions) =>
                this.setState({
                  assignment: { ...this.state.assignment!, securityQuestions },
                })
              }
            />
          </FormRow>
          <FormRow label={dictionary.questionnaires} optional>
            {!this.props.readOnly && (
              <FormRow>
                <Search
                  readOnly={this.props.readOnly}
                  name=""
                  defaultValue={questionnaireUtils.toOption(
                    this.props.assignment?.questionnaires ?? []
                  )}
                  searchMethod={(query) => {
                    const filter: Record<string, string> = {};
                    const companyId = authUtils.getEmployer();
                    if (companyId) {
                      filter["companyId"] = companyId;
                    }
                    return questionnaireUtils.searchQuestionnaireOptions(
                      query,
                      {
                        limit: 5,
                        filter,
                      }
                    );
                  }}
                  multiple
                  onChange={(selection) =>
                    this.selectQuestionnaire(
                      selection.map((selection) => selection.key)
                    )
                  }
                  placeholder={dictionary.find_questionnaire}
                />
              </FormRow>
            )}
            <FormRow>
              {this.state.assignment.questionnaires?.map((questionnaire) => (
                <Card
                  key={questionnaire._id}
                  head={questionnaire.title}
                  darkened
                  padding={15}
                  attr={{
                    style: {
                      marginBottom: "10px",
                    },
                  }}
                >
                  <Questions
                    readOnly={true}
                    questions={questionnaire.questions}
                    inputName=""
                  />
                </Card>
              ))}
            </FormRow>
            <FormRow>
              <Checkbox
                readOnly={this.props.readOnly}
                name="recurringQuestionnaire"
                defaultValue={this.state.assignment.recurringQuestionnaire}
                attributes={{ className: "hide-on-print" }}
              >
                Vragenlijst elke dag terug laten komen
              </Checkbox>
            </FormRow>
          </FormRow>
          {!this.props.readOnly && (
            <>
              <FormRow>
                <Button type="submit">
                  {this.state.assignment._id
                    ? dictionary.update
                    : dictionary.create}
                </Button>
              </FormRow>

              {this.state.assignment._id && (
                <FormRow>
                  <Button ghost onClick={() => this.delete()}>
                    {dictionary.delete}
                  </Button>
                </FormRow>
              )}
            </>
          )}
        </form>
      )
    );
  }

  private async selectQuestionnaire(ids?: string[]) {
    if (!this.state.assignment) {
      return;
    }

    const { assignment } = this.state;
    const incomingIds = ids ?? [];

    const removedId =
      assignment.questionnaires
        ?.filter((questionnaire) => !incomingIds.includes(questionnaire._id))
        ?.map((questionnaire) => questionnaire._id) ?? [];

    const addedId = incomingIds.filter(
      (id) =>
        !assignment.questionnaires?.some(
          (questionnaire) => questionnaire._id === id
        )
    );

    const updatedQuestionnaires =
      assignment.questionnaires?.filter(
        (questionnaire) => !removedId.includes(questionnaire._id)
      ) ?? [];

    if (addedId.length > 0) {
      const questionnaire = await json<IQuestionnaire>(
        questionnaireService.get(addedId[0])
      );
      updatedQuestionnaires.push(questionnaire);
    }

    const updatedAssignment = {
      ...assignment,
      questionnaires: updatedQuestionnaires,
    };
    this.setState({ assignment: updatedAssignment });
  }

  private async supplementQuestions() {
    if (!this.state.assignment || this.state.assignment?._id) {
      return;
    }

    const project = await json<IProject>(
      projectService.get(this.state.assignment?.projectId || "")
    );

    this.setState({
      assignment: {
        ...this.state.assignment,
        securityQuestions: project.securityQuestions,
      },
    });
  }

  /**
   * Delete an assignment
   */
  private async delete() {
    if (!this.state.assignment || !this.state.assignment._id) {
      return;
    }
    const id = this.state.assignment._id;

    if (
      window.confirm(
        dictionary.are_you_sure_you_want_to_delete_x(dictionary.assignment)
      )
    ) {
      try {
        await assignmentService.delete(id, { loader: true });
        routerService.navigate(routes.contacts.path());

        notificationListener.next({
          message: dictionary.the_x_has_been_successfully_removed(
            dictionary.assignment
          ),
          severity: Severity.SUCCESS,
        });
      } catch (err) {
        notificationListener.next({
          message: dictionary.something_went_wrong,
          severity: Severity.ALERT,
        });
      }
    }
  }

  /**
   * Store the form data in the assignment attribute
   */
  private storeFormData(callback?: () => any) {
    let assignment = this.state.assignment;
    if (this.formRef.current) {
      assignment = formUtils.getFormDataAsJSON<IAssignment>(
        this.formRef.current
      );
    }
    if (assignment) {
      if (this.state.assignment?.questionnaires) {
        assignment.questionnaires = this.state.assignment.questionnaires;
      } else {
        assignment.questionnaires = undefined;
      }
    }
    this.setState({ assignment }, callback || (() => {}));
  }

  /**
   * submit the form
   */
  private submit() {
    this.storeFormData(() => {
      this.props.onSubmit(this.state.assignment!);
    });
  }
}
