import { ICompany, IQuestionnaire, IUser } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { Grid, GridItem } from "../components/containers/grid";
import CrudTable from "../components/containers/tables/crudTable";
import { Button } from "../components/controls/button";
import { Spacer } from "../components/data/notifications/spacer";
import {
  EmployeeRow,
  employeeRowHeadings,
} from "../components/data/table-rows/employeeRow";
import {
  QuestionnaireRow,
  questionnaireRowHeadings,
} from "../components/data/table-rows/questionnaireRow";
import CompanyForm from "../components/forms/companyForm";
import { Hr } from "../components/shapes/hr";
import { dictionary } from "../constants/i18n/dictionary";
import routes from "../routes";
import { authenticationService } from "../services/authentication.service";
import { companyService } from "../services/company.service";
import { questionnaireService } from "../services/questionnaire.service";
import { routerService } from "../services/router.service";
import { userService } from "../services/user.service";
import { json } from "../utils/statics.utils";

interface IState {
  company: ICompany | null;
  showExampleQuestionnaires: boolean;
}

export default class CompanyView extends React.Component<
  RouteComponentProps,
  IState
> {
  private id: string | null = null;
  public state: IState = {
    company: null,
    showExampleQuestionnaires: false,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.id =
      (this.props.match as any).params.id ??
      authenticationService.getSessionToken()?.user?.employedById;
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Grid>
        <GridItem flex={2}>
          <Card
            head="Deelnemer"
            images={
              this.state.company?.imagePath
                ? [this.state.company.imagePath]
                : undefined
            }
            imageSize="contain"
          >
            {this.state.company && (
              <>
                <CompanyForm
                  onSubmit={() => {}}
                  readOnly={true}
                  company={this.state.company || undefined}
                />

                <Hr></Hr>

                <Button
                  ghost
                  onClick={() =>
                    routerService.navigate(
                      routes.companyEdit.path(this.id || "")
                    )
                  }
                >
                  Aanpassen
                </Button>
              </>
            )}
          </Card>
        </GridItem>
        <GridItem flex={5}>
          <Card head="Medewerkers">
            <CrudTable<IUser>
              limit={10}
              defaultSortBy="lastName"
              headings={employeeRowHeadings()}
              rowTemplate={(user) => (
                <EmployeeRow
                  onClick={(user) =>
                    routerService.navigate(
                      routes.companyEmployeeEdit.path(
                        this.state.company?._id,
                        user._id
                      )
                    )
                  }
                  key={user._id}
                  user={user}
                />
              )}
              fetchMethod={(options) => {
                options.filter = options.filter || {};
                options.filter.employedById = this.id || "";
                options.populate = ["employedBy"];

                return userService.getAll(options);
              }}
              onCreateClick={
                routes.companyEmployeeCreate.roles?.includes(
                  authenticationService.getSessionToken()?.user?.role
                )
                  ? () =>
                      routerService.navigate(
                        routes.companyEmployeeCreate.path(
                          this.state.company?._id
                        )
                      )
                  : undefined
              }
            />
          </Card>

          <Spacer height={30} />

          <Card
            head={
              this.state.showExampleQuestionnaires
                ? dictionary.questionnaire_templates
                : dictionary.questionnaires
            }
            controls={
              <Button
                small
                bland
                onClick={() =>
                  this.setState({
                    showExampleQuestionnaires:
                      !this.state.showExampleQuestionnaires,
                  })
                }
              >
                {this.state.showExampleQuestionnaires
                  ? dictionary.show_questionnaires
                  : dictionary.show_templates}
              </Button>
            }
          >
            <CrudTable<IQuestionnaire>
              key={this.state.showExampleQuestionnaires.toString()}
              limit={10}
              defaultSortBy="title"
              headings={questionnaireRowHeadings()}
              rowTemplate={(questionnaire) => (
                <QuestionnaireRow
                  onClick={(questionnaire) =>
                    this.state.showExampleQuestionnaires
                      ? routerService.navigate(
                          routes.companyQuestionnaireDuplication.path(
                            this.state.company?._id,
                            questionnaire._id
                          )
                        )
                      : routerService.navigate(
                          routes.companyQuestionnaire.path(
                            this.state.company?._id,
                            questionnaire._id
                          )
                        )
                  }
                  key={questionnaire._id}
                  questionnaire={questionnaire}
                />
              )}
              fetchMethod={(options) => {
                options.filter = options.filter || {};
                options.filter.companyId = this.state.showExampleQuestionnaires
                  ? "!" + this.id
                  : this.id ?? "";

                return questionnaireService.getAll(options);
              }}
              onCreateClick={
                routes.companyQuestionnaireCreate.roles?.includes(
                  authenticationService.getSessionToken()?.user?.role
                )
                  ? () =>
                      routerService.navigate(
                        routes.companyQuestionnaireCreate.path(
                          this.state.company?._id
                        )
                      )
                  : undefined
              }
            />
          </Card>
        </GridItem>
      </Grid>
    );
  }

  private async fetchData() {
    try {
      if (!this.id) {
        throw new Error("No id defined");
      }

      const company = await json<ICompany>(
        companyService.get(this.id, {
          populate: ["employees", "manager"],
        })
      );
      this.setState({ company });
    } catch (err) {
      routerService.navigate(routes.companies.path());
    }
  }
}
