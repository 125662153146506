/* eslint-disable react-hooks/exhaustive-deps */
import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { style } from "typestyle";
import { Flex } from "../containers/flex";
import { Input } from "./input";
import { Select } from "./select";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {}

const countryCodes = [
  { key: "+31", value: "+31 (NL)" },
  { key: "+32", value: "+32 (BE)" },
];

export const PhoneInput: React.FC<IProps> = (props) => {
  const [prefix, setPrefix] = useState<string>(countryCodes[0].key);
  const [number, setNumber] = useState<string>("");

  useEffect(() => {
    const value = props.value?.toString() ?? props.defaultValue?.toString();
    const activeCode = countryCodes.find((code) => value?.startsWith(code.key));

    const _prefix = activeCode?.key ?? prefix;
    const _number = value?.replace(_prefix, "") ?? "";

    setPrefix(_prefix);
    setNumber(_number);
  }, [props.value, props.defaultValue]);

  if (props.readOnly) {
    return <Input {...props} readOnly />;
  }

  return (
    <Flex className={[props.className, styles.container].join(" ")}>
      {number && (
        <input
          {...{ ...props, defaultValue: undefined, value: prefix + number }}
          type="hidden"
        />
      )}
      <Select
        value={prefix}
        className={styles.select}
        options={countryCodes}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
          setPrefix(event.currentTarget.value)
        }
      />
      <Input
        {...{
          type: "tel",

          ...props,
          name: undefined,
          className: styles.input,
          defaultValue: undefined,
          value: number,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            setNumber(event.currentTarget.value),
        }}
      />
    </Flex>
  );
};

const styles = {
  container: style({
    flexWrap: "nowrap",
  }),
  select: style({
    width: "min-content",
    flex: 0,
    marginRight: 0,
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }),
  input: style({
    marginLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }),
};
