import React from "react";
import { Sheet } from "strcss";

interface IProps {}

export const Center: React.FC<IProps> = props => (
  <div className={map.center}>{props.children}</div>
);

const { map } = new Sheet(`
  map center
    display flex
    justifyContent space-around
`);
