import { ICredentials } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { formUtils } from "../../utils/form.utils";
import { FormRow } from "../containers/formRow";
import { Button } from "../controls/button";
import { Input } from "../controls/input";

interface IProps {
  onSubmit: (data: ICredentials) => any;
}

export const LoginForm: React.FC<IProps> = (props) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      props.onSubmit(formUtils.getFormDataAsJSON(e.currentTarget));
    }}
  >
    <FormRow label={dictionary.email}>
      <Input
        name="email"
        type="email"
        placeholder={dictionary.email}
        required
      />
    </FormRow>
    <FormRow label={dictionary.password}>
      <Input
        name="password"
        type="password"
        placeholder={dictionary.password}
        required
      />
    </FormRow>
    <FormRow>
      <Button type="submit">{dictionary.login}</Button>
    </FormRow>
  </form>
);
