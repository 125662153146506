import {
  IAssignment,
  IAttachment,
  IProject,
  Severity,
} from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { classes, style } from "typestyle";
import downloadIcon from "../assets/download.svg";
import printIcon from "../assets/print.svg";
import { Card } from "../components/containers/card";
import { FormRow } from "../components/containers/formRow";
import { Grid, GridItem } from "../components/containers/grid";
import CrudTable from "../components/containers/tables/crudTable";
import { Button } from "../components/controls/button";
import { Image } from "../components/controls/image";
import { Spacer } from "../components/data/notifications/spacer";
import { SubmissionOverview } from "../components/data/submissions/submissionOverview";
import {
  AssignmentRow,
  assignmentRowHeadings,
} from "../components/data/table-rows/assignmentRow";
import {
  AttachmentRow,
  attachmentRowHeadings,
} from "../components/data/table-rows/attachmentRow";
import ProjectForm from "../components/forms/projectForm";
import { Hr } from "../components/shapes/hr";
import { dictionary } from "../constants/i18n/dictionary";
import routes from "../routes";
import { assignmentService } from "../services/assignment.service";
import { attachmentService } from "../services/attachment.service";
import { projectService } from "../services/project.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";
import { ConfirmDialog } from "../dialogs/confirm.dialog";
import { dialog } from "../dialogs/dialog";
import { notificationListener } from "../observables/notification-listener";

interface IState {
  project: IProject | null;
  showActive: boolean;
}

export default class ProjectView extends React.Component<
  RouteComponentProps,
  IState
> {
  private id: string | null = null;
  public state: IState = {
    project: null,
    showActive: true,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.id = (this.props.match as any).params.id;
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Grid>
        <GridItem flex={2}>
          <Card
            head={dictionary.project}
            controls={
              <Image
                className={styles.iconButton}
                alt={"print"}
                title={dictionary.print_project}
                src={printIcon}
                onClick={() =>
                  routerService.navigate(
                    routes.projectPrint.path(this.id || "", "")
                  )
                }
              />
            }
            images={
              this.state.project?.imagePath
                ? [this.state.project.imagePath]
                : undefined
            }
            imageSize="cover"
          >
            {this.state.project && (
              <>
                <ProjectForm
                  onSubmit={() => {}}
                  readOnly={true}
                  project={this.state.project || undefined}
                />

                <Hr />

                <FormRow>
                  {!this.state.project.finishedAt ? (
                    <Button onClick={() => this.finishProject()}>
                      Project afronden
                    </Button>
                  ) : (
                    <Button ghost onClick={() => this.openProject()}>
                      Project heropenen
                    </Button>
                  )}
                </FormRow>

                <Button
                  ghost
                  onClick={() =>
                    routerService.navigate(
                      routes.projectEdit.path(this.id || "")
                    )
                  }
                >
                  Aanpassen
                </Button>
              </>
            )}
          </Card>
        </GridItem>
        <GridItem flex={5}>
          <Card
            head={dictionary.attachments}
            controls={
              <Image
                className={classes(
                  styles.iconButton,
                  this.state.project?.attachments?.length === 0 &&
                    styles.disabled
                )}
                alt={"download"}
                title={dictionary.download_attachments}
                src={downloadIcon}
                onClick={() => this.downloadAttachments()}
              />
            }
          >
            <CrudTable<IAttachment>
              limit={10}
              defaultSortBy="createdAt"
              headings={attachmentRowHeadings()}
              rowTemplate={(attachment) => (
                <AttachmentRow key={attachment._id} attachment={attachment} />
              )}
              fetchMethod={(options) => {
                options.filter = options.filter || {};
                options.filter.projectId = this.id || "";

                return attachmentService.getAll(options);
              }}
              onCreateClick={() =>
                routerService.navigate(
                  routes.projectAttachmentEdit.path(this.id || "", "new")
                )
              }
            />
          </Card>

          <Spacer height={30} />

          <Card
            head={
              this.state.showActive
                ? dictionary.active_assignments
                : dictionary.finished_assignments
            }
            controls={
              <Button
                small
                bland
                onClick={() =>
                  this.setState({ showActive: !this.state.showActive })
                }
              >
                {this.state.showActive
                  ? dictionary.show_finished
                  : dictionary.show_active}
              </Button>
            }
          >
            <CrudTable<IAssignment>
              key={this.state.showActive.toString()}
              limit={10}
              defaultSortBy="name"
              headings={assignmentRowHeadings()}
              rowTemplate={(assignment) => (
                <AssignmentRow key={assignment._id} assignment={assignment} />
              )}
              fetchMethod={(options) => {
                options.filter = options.filter || {};
                options.filter.projectId = this.id || "";

                return assignmentService[
                  this.state.showActive ? "getActive" : "getFinished"
                ]({ populate: [], ...options });
              }}
              onCreateClick={() =>
                routerService.navigate(
                  routes.projectAssignmentCreate.path(this.id || "")
                )
              }
            />
          </Card>

          {this.state.project?.assignments?.map((assignment) => (
            <div key={assignment._id}>
              <Spacer height={30}></Spacer>
              <Card head={`Dossier ${assignment.name}`} darkened>
                <SubmissionOverview
                  onRefreshRequested={this.fetchData}
                  submissions={assignment?.submissions ?? []}
                  questionnaireSubmissions={
                    assignment?.questionnaireSubmissions
                  }
                />
              </Card>
            </div>
          ))}
        </GridItem>
      </Grid>
    );
  }

  private async fetchData() {
    try {
      if (!this.id) {
        throw new Error("No id defined");
      }

      const project = await json<IProject>(
        projectService.get(this.id, {
          populate: [
            "assignments.submissions.author",
            "assignments.submissions.comments.author",
            "assignments.questionnaireSubmissions.author",
            "attachments",
            "company",
            "contact",
            "customer",
          ],
        })
      );
      this.setState({ project });
    } catch (err) {
      routerService.navigate(routes.projects.path());
    }
  }

  private async openConfirmDownloadDialog() {
    try {
      await projectService.downloadAttachments(this.id || "");
      notificationListener.next({
        message: dictionary.all_attachments_have_been_successfully_downloaded,
        severity: Severity.SUCCESS,
      });
      dialog.unmount();
    } catch (err) {
      notificationListener.next({
        message:
          dictionary.something_went_wrong_with_downloading_the_attachments,
        severity: Severity.ALERT,
      });
    }
  }

  private async downloadAttachments() {
    const totalAttachments = this.state.project?.attachments?.length ?? 0;

    dialog.mount({
      title: dictionary.download_attachments,
      children: (
        <ConfirmDialog
          children={
            <p>
              {dictionary.download_x_attachments_to_device(totalAttachments)}
            </p>
          }
          onConfirm={() => this.openConfirmDownloadDialog()}
        />
      ),
    });
  }

  private async finishProject() {
    if (!this.state.project) {
      return;
    }
    await projectService.finishProject(this.state.project._id);

    await this.fetchData();
  }

  private async openProject() {
    if (
      !this.state.project ||
      !window.confirm("Weet je zeker dat je deze taak wilt heropenen?")
    ) {
      return;
    }
    await projectService.put({
      ...this.state.project,
      finishedAt: undefined,
    });

    await this.fetchData();
  }
}

const styles = {
  iconButton: style({
    cursor: "pointer",
    transition: ".3s opacity",
    opacity: 0.54,

    $nest: {
      "&:hover": {
        opacity: 0.87,
      },
    },
    height: 20,
  }),
  disabled: style({
    pointerEvents: "none",
    opacity: 0.5,
  }),
};
