import React, { CSSProperties } from "react";
import { Sheet } from "strcss";
import colors from "../../constants/colors.constants";

interface IProps {
  label?: string;
  style?: CSSProperties;
  optional?: boolean;
}

export const FormRow: React.FC<IProps> = (props) => (
  <div style={props.style} className={map.formRow}>
    {props.label && (
      <label className={map.label}>
        {props.label}
        {!props.optional && <span className={map.required}>*</span>}
      </label>
    )}
    {props.children}
  </div>
);

const { map } = new Sheet(`
  map formRow 
    padding 10 0
    width 100%
    pageBreakInside avoid

  map label
    fontSize 1em
    fontWeight 500
    margin 0 15 5 15
    width -webkit-fill-available
    display block

  map required
    color ${colors.severity.alert}
`);
