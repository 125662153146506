import React from "react";
import { Singleton } from "react-singletons";
import { Component, ReactNode, Props } from "react";
import { Card } from "./card";
import { Spring } from "react-spring/renderprops";

interface IProps extends Props<HTMLElement> {
  target: HTMLElement;
}

export const Popup = new Singleton<IProps>(
  class extends Component<IProps, {}> {
    private ref = React.createRef<HTMLDivElement>();
    private width = 200;
    private forceUpdateFn: () => any = () => {};
    private closeOnClickFn: (e: MouseEvent) => any = e => {};

    componentDidMount() {
      this.forceUpdateFn = () => this.forceUpdate();
      this.closeOnClickFn = (e: MouseEvent) => this.closeOnClick(e);
      window.addEventListener("resize", this.forceUpdateFn);
      document.addEventListener("click", this.closeOnClickFn);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.forceUpdateFn);
      document.removeEventListener("click", this.closeOnClickFn);
    }

    public render(): ReactNode {
      return (
        <Spring
          from={{ opacity: 0, top: 10 }}
          to={{ opacity: 1, top: 0 }}
          config={{ friction: 20, tension: 500 }}
        >
          {props => (
            <Card
              attr={{
                ref: this.ref,
                style: {
                  opacity: props.opacity,
                  transform: `translateY(${props.top}px)`,
                  position: "absolute",
                  width: `${this.width}px`,
                  boxShadow: "rgba(0, 0, 0, 0.09) 0px 4px 11px ",
                  ...this.getPosition()
                }
              }}
              head="Filter resultaten"
              padding={15}
            >
              {this.props.children}
            </Card>
          )}
        </Spring>
      );
    }

    /**
     * Returns the left and bottom positions based on the provided target element
     */
    private getPosition(): { left: string; bottom: string } {
      const offsets = this.props.target.getBoundingClientRect() as DOMRect;
      const left = offsets.x + offsets.width / 2 - this.width / 2;
      const bottom = document.body.clientHeight - offsets.y - window.scrollY;

      return { left: `${left}px`, bottom: `${bottom}px` };
    }

    /**
     * Close the popup when the user clicks outside of its bounds
     * @param e
     */
    private closeOnClick(e: MouseEvent) {
      const clickInBounds = this.ref.current!.contains(e.target as any);
      if (!clickInBounds) {
        Popup.unmount();
      }
    }
  }
);
