import { IProject, Severity } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import ProjectForm from "../components/forms/projectForm";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { projectService } from "../services/project.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";

interface IState {
  project: IProject | null;
  image?: string;
}

export default class ProjectFormView extends React.Component<
  RouteComponentProps,
  IState
> {
  private id: string | null = null;
  public state: IState = {
    project: null,
    image: undefined,
  };

  constructor(props: RouteComponentProps) {
    super(props);

    const id = (this.props.match as any).params.id;
    if (id && id !== "new") {
      this.id = id;
    }
  }

  componentDidMount() {
    this.fetchProject();
  }

  render() {
    return (
      (!this.id || this.state.project) && (
        <Card
          attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}
          images={this.state.image ? [this.state.image] : undefined}
          imageSize="cover"
        >
          <ProjectForm
            onImageChange={(image) => this.setState({ image })}
            onSubmit={(project) => this.submit(project)}
            project={this.state.project || undefined}
          />
        </Card>
      )
    );
  }

  private async submit(project: FormData) {
    try {
      await projectService.createOrPatch(project as any, { loader: true });
      notificationListener.next({
        message: "Het project is succesvol opgeslagen",
        severity: Severity.SUCCESS,
      });

      if (this.id === "new") {
        routerService.navigate(routes.projects.path());
      } else {
        routerService.navigate(routes.project.path(this.id || ""));
      }
    } catch (err) {
      notificationListener.next({
        message: "Er is iets fout gegaan",
        severity: Severity.ALERT,
      });
    }
  }

  private async fetchProject() {
    if (!this.id) {
      return;
    }

    const project = await json<IProject>(
      projectService.get(this.id, {
        populate: ["customer.contacts", "contact", "company"],
      })
    );
    this.setState({ project, image: project.imagePath || undefined });
  }
}
