import { IQuestionnaire } from "@hulanbv/afbouwkeur-packages";
import React from "react";
import { RouteComponentProps } from "react-router";
import CrudTable from "../components/containers/tables/crudTable";
import {
  QuestionnaireRow,
  questionnaireRowHeadings,
} from "../components/data/table-rows/questionnaireRow";
import routes from "../routes";
import { questionnaireService } from "../services/questionnaire.service";
import { routerService } from "../services/router.service";

export default class QuestionnairesView extends React.Component<RouteComponentProps> {
  render() {
    return (
      <CrudTable<IQuestionnaire>
        limit={10}
        defaultSortBy="name"
        headings={questionnaireRowHeadings()}
        rowTemplate={(questionnaire) => (
          <QuestionnaireRow
            onClick={(questionnaire) =>
              routerService.navigate(
                routes.questionnaire.path(questionnaire._id)
              )
            }
            key={questionnaire._id}
            questionnaire={questionnaire}
          />
        )}
        fetchMethod={(options) =>
          questionnaireService.getPublic({
            populate: [],
            searchScope: Object.keys(questionnaireRowHeadings),
            ...options,
          })
        }
        onCreateClick={() =>
          routerService.navigate(routes.questionnaire.path("new"))
        }
      />
    );
  }
}
