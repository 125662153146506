import React, { CSSProperties } from "react";
import { Sheet } from "strcss";
import optionsIcon from "../../../assets/options.svg";
import colors from "../../../constants/colors.constants";
import { dictionary } from "../../../constants/i18n/dictionary";
import { ICrudTableFilterOptions } from "../../../interfaces/crudTableFilterOptions.interface";
import { IHttpOptions } from "../../../interfaces/httpOptions.interface";
import { IResponse } from "../../../interfaces/response.interface";
import { globalStyles } from "../../../styles";
import { isNil } from "../../../utils/statics.utils";
import { Button } from "../../controls/button";
import { Image } from "../../controls/image";
import { Input } from "../../controls/input";
import { Pagination } from "../../controls/pagination";
import { CrudTableFilterForm } from "../../forms/crudTableFilterForm";
import { Popup } from "../popup";
import { Table } from "./table";
import { TD } from "./tableCell";
import { TR } from "./tableRow";

interface IProps<Model> {
  style?: CSSProperties;

  defaultSortBy?: string;
  limit: number;
  headings: { [key: string]: string };
  fetchMethod: (options: IHttpOptions) => Promise<IResponse<Model[]>>;
  rowTemplate: (model: Model, index?: number) => React.ReactElement;
  filterOptions?: ICrudTableFilterOptions[];

  onCreateClick?: () => any;
}

interface IState<Model> {
  models: Model[] | null;
  isLoading: boolean;
}

export default class CrudTable<Model> extends React.Component<
  IProps<Model>,
  IState<Model>
> {
  private sortBy = "";
  private searchQuery = "";
  private page = 1;
  private filters: ICrudTableFilterOptions[] = [];
  private totalPages = 1;
  private _browsePages: any;

  public state: IState<Model> = {
    models: null,
    isLoading: true,
  };

  componentDidMount() {
    this.sortBy = this.props.defaultSortBy || "";
    this._browsePages = this.browsePages.bind(this);
    this.filters = this.props.filterOptions || [];

    this.fetchModels();
    this.bindKeys();
  }

  componentWillUnmount() {
    this.unbindKeys();
  }

  componentWillReceiveProps(props: IProps<Model>) {
    this.filters = props.filterOptions || this.props.filterOptions || [];
  }

  render() {
    return (
      <div style={this.props.style || {}}>
        <Table
          sortBy={this.sortBy}
          headings={this.props.headings}
          onSelectSortBy={(sortBy) => this.selectSortBy(sortBy)}
        >
          {!this.state.models?.length && (
            <TR>
              <TD
                className={map.empty}
                colSpan={Object.keys(this.props.headings).length}
              >
                {this.state.isLoading ? "Laden.." : "Geen resultaten gevonden"}
              </TD>
            </TR>
          )}

          {this.state.models &&
            this.state.models.map((model, i) =>
              this.props.rowTemplate(model, i)
            )}
        </Table>

        <div className={map.optionsBar}>
          <div className={globalStyles.flexAligned}>
            <Pagination
              page={this.page ?? 1}
              totalPages={this.totalPages ?? 1}
              onPageSelect={(page) => this.selectPage(page)}
            />

            <Input
              style={{ width: "auto" }}
              placeholder="Zoeken"
              onChange={(e) => this.onSearchChange(e.target.value)}
            />

            {!!this.filters.length && (
              <Button
                ghost
                compact
                style={{ width: "auto" }}
                onClick={(e) => {
                  Popup.mount({
                    target: e.currentTarget,
                    children: (
                      <CrudTableFilterForm
                        filterOptions={this.filters}
                        onSubmit={(filters) => {
                          this.filters = filters;
                          this.fetchModels();
                          Popup.unmount();
                        }}
                      />
                    ),
                  });
                }}
              >
                {!!this.getActiveFilters().length && (
                  <span className={map.filterLabel}>
                    {this.getActiveFilters().length} {dictionary.filters_active}
                  </span>
                )}
                <Image src={optionsIcon} height={15} alt={"Exit"} />
              </Button>
            )}
          </div>

          {this.props.onCreateClick && (
            <Button
              style={{ width: "auto" }}
              onClick={() => this.props.onCreateClick!()}
            >
              {dictionary.new}
            </Button>
          )}
        </div>
      </div>
    );
  }

  /**
   * Fetches a new set of models
   */
  private async fetchModels() {
    let models: Model[] = [];
    const options: IHttpOptions = {
      limit: this.props.limit,
      search: this.searchQuery,
      sort: [this.sortBy],
      offset: (this.page - 1) * this.props.limit,
    };

    // add the filters
    for (const filter of this.getActiveFilters()) {
      options.filter = options.filter || {};
      options.filter[filter.key] = filter.currentValue || "";
    }

    try {
      const response = await this.props.fetchMethod(options);
      models = await response.json();
      this.totalPages = Math.ceil(
        (+response.headers.get("X-total-count")! || 1) / this.props.limit
      );
    } catch (err) {}

    this.setState({ models, isLoading: false });
  }

  /**
   * Select a new sort column
   * @param sortBy
   */
  private selectSortBy(sortBy: string) {
    this.sortBy = sortBy;
    this.fetchModels();
  }

  /**
   * Returns all crudtable filters with values
   */
  private getActiveFilters(): ICrudTableFilterOptions[] {
    return this.filters.filter((filter) => !isNil(filter.currentValue));
  }

  /**
   * Select a new page
   * @param page
   */
  private selectPage(page: number) {
    this.page = page;
    this.fetchModels();
  }

  /**
   * Select a new search value
   * @param value
   */
  private onSearchChange(value: string) {
    this.searchQuery = value;
    this.page = 1;
    this.fetchModels();
  }

  private bindKeys() {
    window.addEventListener("keydown", this._browsePages);
  }

  private unbindKeys() {
    window.removeEventListener("keydown", this._browsePages);
  }

  /**
   * Browse the table pages with the arrow keys
   * @param keyCode
   */
  private browsePages(e: KeyboardEvent) {
    const destinations: { [k: string]: number } = {
      37: Math.max(this.page - 1, 1), // left
      39: Math.min(this.page + 1, this.totalPages), // right
      40: 1, // down
      38: this.totalPages, // up
    };

    if (destinations[e.keyCode] && destinations[e.keyCode] !== this.page) {
      this.page = destinations[e.keyCode];
      this.fetchModels();
    }
  }
}

const { map } = new Sheet(`
  map empty
    textAlign center
    color ${colors.text.secondary}
  
  map optionsBar
    display flex
    justifyContent space-between
    flexWrap wrap

  map filterLabel
    marginRight 8
`);
