import { Severity } from "@hulanbv/afbouwkeur-packages";
import React, { FC } from "react";
import { keyframes, style } from "typestyle";
import alertIcon from "../../../assets/alert-circle.svg";
import successIcon from "../../../assets/check-circle.svg";
import infoIcon from "../../../assets/info.svg";
import warnIcon from "../../../assets/warn-circle.svg";
import closeIcon from "../../../assets/x.svg";
import colors from "../../../constants/colors.constants";
import { INotification } from "../../../interfaces/notification.interface";
import { routerService } from "../../../services/router.service";
import { Image } from "../../controls/image";

interface IProps {
  notification: INotification;
  onClose?: () => void;
}

const severityIcons = {
  [Severity.ALERT]: alertIcon,
  [Severity.INFO]: infoIcon,
  [Severity.SUCCESS]: successIcon,
  [Severity.WARNING]: warnIcon,
};

export const Notification: FC<IProps> = (props) => (
  <div
    className={[
      styles.notification,
      styles[`severity-${props.notification.severity}`],
      style({
        animationDuration: `${props.notification.duration}ms`,
        $nest: {
          "&:before": {
            animationDuration: `${props.notification.duration}ms`,
          },
        },
      }),
    ].join(" ")}
  >
    <Image
      src={severityIcons[props.notification.severity]}
      className={styles.icon}
      alt="!"
    />
    {props.notification.navigateTo && (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => routerService.navigate(props.notification.navigateTo!)}
      >
        {props.notification.message}
      </span>
    )}
    {!props.notification.navigateTo && props.notification.message}

    {(props.notification.onClose || props.onClose) && (
      <Image
        className={styles.close}
        alt="x"
        onClick={() => {
          props.notification.onClose?.();
          props.onClose?.();
        }}
        src={closeIcon}
      />
    )}
  </div>
);

const houdiniAnimation = keyframes({
  "0%": { opacity: 1 },
  "97%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const styles = {
  notification: style({
    animationName: houdiniAnimation,
    animationTimingFunction: "linear",
    padding: 20,
    background: "rgba(255,255,255,.83)",
    borderRadius: 6,
    margin: 10,
    color: "#707070",
    position: "relative",
    overflow: "hidden",
    maxWidth: 500,
    border: "1px solid #eee",
    boxShadow: "0 1px 1px rgba(0,0,0,.08)",
    alignItems: "center",
    display: "flex",
  }),

  close: style({
    height: 18,
    marginLeft: 20,
    opacity: 0.2,
    cursor: "pointer",
  }),
  icon: style({
    height: 18,
    marginRight: 10,
  }),

  [`severity-${Severity.ALERT}`]: style({
    color: "#FFF",
    background: colors.severity.alert,
    borderColor: "#d04132",
  }),
  // [`severity-${Severity.INFO}`]: style({
  //   background: colors.severity.info
  // }),
  // [`severity-${Severity.SUCCESS}`]: style({
  //   background: colors.severity.success
  // }),
  // [`severity-${Severity.WARNING}`]: style({
  //   background: colors.severity.warning
  // })
};
