import { Sheet } from "strcss";

const { map } = new Sheet(`
    map flex 
        display flex

    map flexWrapped
        display flex
        flexWrap wrap

    map flexAligned
        display flex
        alignItems center
        
    map flex1
        flex 1

    map ellipsis
        whiteSpace nowrap
        textOverflow ellipsis
        overflow hidden
`);

export const globalStyles = map;
