/* eslint-disable react-hooks/exhaustive-deps */
import { INewsItem, Severity } from "@hulanbv/afbouwkeur-packages";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { NewsItemForm } from "../components/forms/newsItemForm";
import { Hr } from "../components/shapes/hr";
import { notificationListener } from "../observables/notification-listener";
import routes from "../routes";
import { newsService } from "../services/news.service";
import { routerService } from "../services/router.service";
import { json } from "../utils/statics.utils";

export const NewsFormView: React.FC<RouteComponentProps> = (props) => {
  const [model, setModel] = useState<INewsItem | undefined>(undefined);

  const submit = async (newsItem: INewsItem) => {
    if (newsItem._id) {
      await newsService.patch(newsItem);
    } else {
      await newsService.create(newsItem);
    }
    notificationListener.next({
      message: "Het nieuwsbericht is opgeslagen",
      severity: Severity.SUCCESS,
    });
    routerService.navigate(routes.news.path());
  };

  const deleteModel = async () => {
    if (!window.confirm("Weet je zeker dat je dit bericht wilt verwijderen?")) {
      return;
    }

    await newsService.delete(model?._id);
    notificationListener.next({
      message: "Het bericht is succesvol verwijderd",
      severity: Severity.SUCCESS,
    });
    routerService.navigate(routes.news.path());
  };

  const { id } = (props.match as any).params;
  useEffect(() => {
    const fetchModel = async () => {
      const model = await json<INewsItem>(newsService.get(id));
      setModel(model);
    };
    if (id) {
      fetchModel();
    }
  }, [props.match]);

  if (id && !model) {
    return <></>;
  }
  return (
    <Card attr={{ style: { maxWidth: "600px", margin: "0 auto" } }}>
      <NewsItemForm model={model} onSubmit={submit} />
      {model?._id && (
        <>
          <Hr />
          <Button ghost onClick={deleteModel}>
            Verwijderen
          </Button>
        </>
      )}
    </Card>
  );
};
